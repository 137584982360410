import axios from 'axios'
import React, { useLayoutEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import useErrorHandling from '../../../Utils/useErrorHandling'
import './verify.scss'
import PageLoading from '../loading/PageLoading'
import { useIntl } from 'react-intl'

/**
 * Verify component for handling payment verification
 * @returns {JSX.Element} The Verify component
 */
export default function Verify() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(0)
  const errorHandling = useErrorHandling()
  const navigate = useNavigate()
  const location = useLocation().search
  const intl = useIntl()

  /**
   * Fetches payment verification data from the API
   * @param {string} params - The query parameters for the API call
   */
  const fetchData = async (params: string) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/subscription/verify-payment/${params}/`
      )
      errorHandling(response.status) // Logout when invalid token && redirect 404

      // Set data state based on API response
      if (response.status >= 200 && response.status < 300) {
        setData(1) // Success
      } else {
        console.error(
          `Error in get data from ${process.env.REACT_APP_API_ENDPOINT}/subscription/verify-payment/${params} \n`,
          response
        )
        setData(-1) // Failure
      }
    } catch (e) {
      setData(-1) // Failure on exception
    } finally {
      setLoading(false)
      // Redirect to payment history after 5 seconds
      setTimeout(() => {
        navigate('/payment-history')
      }, 5000)
    }
  }

  // Fetch data on component mount
  useLayoutEffect(() => {
    fetchData(location)
  }, [])

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <div>
          <div className='container'>
            {/* Successful payment message */}
            {data === 1 && (
              <div className='row justify-content-center'>
                <div className='col-md-5 w-50'>
                  <div className='message-box _success '>
                    <i className='fa fa-check-circle' aria-hidden='true'></i>
                    <h2>
                      {intl.formatMessage({ id: 'Your payment has been successfully completed' })}
                    </h2>
                  </div>
                </div>
              </div>
            )}

            {/* Failed payment message */}
            {data === -1 && (
              <div className='row justify-content-center'>
                <div className='col-md-5'>
                  <div className='message-box _success _failed'>
                    <i className='fa fa-times-circle' aria-hidden='true'></i>
                    <h2>{intl.formatMessage({ id: 'Payment failed' })}</h2>
                    <p>
                      {intl.formatMessage({
                        id: 'The amount deducted from your account will be returned to your account within 72 hours',
                      })}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

