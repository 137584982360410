import { useState } from "react";
import { useIntl } from "react-intl";

interface SubSectionProps {
  data: {
    title: string;
    status: 'pass' | 'fail';
    detail: string;
    data?: string[];
    description: string;
  };
}

export const SubSection: React.FC<SubSectionProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();
  
  return (
    <div className="border rounded mb-3 subsection">
      <div
        className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${
          data.status === 'pass' ? 'bg-success-subtle' : 'bg-danger-subtle'
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
        aria-expanded={isExpanded}
        tabIndex={0}
      >
        <h4 className="mb-0 h6">{data.title}</h4>
        
        <i className={`fas fa-${data.status === 'pass' ? 'check' : 'times'}-circle 
          ${data.status === 'pass' ? 'text-success' : 'text-danger'}`}
        ></i>
      </div>

      <div className={`subsection-content ${isExpanded ? 'show' : ''}`}>
        <div className="p-3">
          <div className="mb-3">
            <h5>{intl.formatMessage({ id: 'Detail' })}</h5>
            <div dangerouslySetInnerHTML={{ __html: data.detail }} />
          </div>

          <div className="mb-3">
            <h5>{intl.formatMessage({ id: 'Data' })}</h5>
            <div className="bg-light p-3 rounded">
              {data.data?.length ? (
                <ul className="list-unstyled mb-0">
                  {data.data.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted mb-0 fst-italic">
                  {intl.formatMessage({ id: 'No data available' })}
                </p>
              )}
            </div>
          </div>

          <div>
            <h5>{intl.formatMessage({ id: 'Description' })}</h5>
            <p className="mb-0">{data.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};