import axios from 'axios'
import React, {useLayoutEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useIntl} from 'react-intl'
import ConvertDate from '../../../Utils/ConvertDate'
import { Button} from 'react-bootstrap'
import ImageModal from '../exposures/Domains/details/ImageModal'
import ModalDetails from './ModalDetails'
import { TicketDetails as TicketDetailsType } from '../../modules/types/ticket'
export default function TicketDetails () {
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [data, setData] = useState<TicketDetailsType>()
  
  /**
   * Fetches ticket details from the API.
   * 
   * This function sends a GET request to retrieve ticket details based on the ticket ID
   * from the URL search params. It handles the loading state, error handling, and data setting.
   * 
   * @async
   * @function
   * @throws {Error} Throws an error if the API request fails.
   * @returns {Promise<void>} A promise that resolves when the data fetching is complete.
   */
  const fetchAssetDetailData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ticket/${searchParams.get('id')}/`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ticket/${searchParams.get(
            'id'
          )} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /**
   * Fetches asset detail data when the component mounts.
   * This effect runs once after the initial render and layout.
   * It's used to load the ticket details from the API.
   * 
   * @remarks
   * This effect has no dependencies, so it only runs once.
   * It calls the fetchAssetDetailData function to retrieve the data.
   * 
   * @returns {void}
   */
  useLayoutEffect(() => {
    fetchAssetDetailData()
  }, [])

  return (
    <div className={`card p-5 ${loading && 'skeleton'}`}>
      <div
        className=' d-flex flex-column align-items-center position-absolute top-10 start-auto right-auto w-100 '
        style={{zIndex: 100, top: -50}}
      >

      </div>
      <div className=' d-flex align-items-center justify-content-between w-100'>
        <h1 className='m-0 p-0'>{data?.title}</h1>
        <div className='badge badge-light-primary  fs-3'>
        {intl.formatMessage({id: data?.status||' '})}
          </div>
      </div>
      <hr />
      <div className=' d-flex align-items-center justify-content-between w-100 mt-5'>
        <div className=' d-flex flex-wrap w-100 justify-content-between gap-5 '>
          <div className=' d-flex flex-column gap-1 '>
            <strong>{intl.formatMessage({id: 'Creator'})}</strong>
            <span className='text-gray-700'>{data?.creator.trim() || '_'}</span>
          </div>
          <div className=' d-flex flex-column gap-1 '>
            <strong>{intl.formatMessage({id: 'Assigned to'})}</strong>
            <span className='text-gray-700'>{data?.assigned_to.trim() || '_'}</span>
          </div>

          <div className=' d-flex flex-column gap-1 '>
            <strong>{intl.formatMessage({id: 'Created at'})}</strong>
            <span className='text-gray-700 '>
             {data?.created_at&& <ConvertDate date={data?.created_at} />}
            </span>
          </div>
          <div className=' d-flex flex-column gap-1 '>
            <strong>{intl.formatMessage({id: 'Priority'})}</strong>
            <div
              style={{width: 'fit-content'}}
              className={`badge fs-6 px-3 d-flex align-items-center justify-content-center ${
                data?.priority === 'low'
                  ? 'badge-light-success'
                  : data?.priority === 'medium'
                  ? 'badge-light-warning'
                  : 'badge-light-danger'
              }`}
            >
              
              {intl.formatMessage({id: data?.priority||' '})}
            </div>
          </div>
        </div>
      </div>
      <div className=' d-flex gap-3 mt-10'>
        <div className=' d-flex flex-row  w-50 h-300px '>
          <div
            data-bs-toggle={data?.attachment && 'modal'}
            data-bs-target={data?.attachment && '#exampleModal'}
            className='w-100 h-100 p-3 d-flex justify-content-center align-items-center bg-light rounded pt-3 cursor-pointer'
            style={{
              backgroundImage: `url(${data?.attachment || 'NoImage.png'})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className='w-50'>{data?.description}</div>
      </div>
      <div>
        <div className=' d-flex align-items-center justify-content-between w-100 mt-10 mb-5'>
          <strong className='m-0 p-0'>{intl.formatMessage({id: 'Comments'})}</strong>
          <Button
            size='sm'
            className='fs-6'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_ticket_details'
            disabled={createLoading}
          >
            {!createLoading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'Add comment'})}</span>
            )}
            {createLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Please wait'}) + '...'}
                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        {data?.comments?.map((item: any, ind: number) => (
          <div className=' card bg-light p-5 mb-3' key={ind}>
            <div className=' d-flex align-items-center justify-content-between'>
              <div className=' d-flex flex-column gap-1 col-5'>
                <strong>{intl.formatMessage({id: 'Author'})}</strong>
                <span className='text-gray-700'>{item?.author.trim() || '_'}</span>
              </div>
              <div className=' d-flex flex-column gap-1 col-5'>
                <strong>{intl.formatMessage({id: 'Created at'})}</strong>
                <span className='text-gray-700'>
                  <ConvertDate date={item?.created_at} />
                </span>
              </div>
            </div>
            <hr />
            <div className='w-100 d-flex gap-2'>
              <div className=' d-flex flex-row bg-body p-3 rounded  w-50 h-300px '>
                <div
                  data-bs-toggle={item?.attachment && 'modal'}
                  data-bs-target={item?.attachment && `#exampleModal${ind}`}
                  className='w-100 h-100 p-3 d-flex justify-content-center align-items-center  rounded pt-3 cursor-pointer'
                  style={{
                    backgroundImage: `url(${item?.attachment || 'NoImage.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }}
                />
              </div>

              <div className='w-50'>{item?.content}</div>
            </div>
            <ImageModal url={item?.attachment} id={`${ind}`} />
          </div>
        ))}
      </div>
      {data?.attachment&&<ImageModal url={data?.attachment} />}
      <ModalDetails
        setCreateLoading={setCreateLoading}
        getData={fetchAssetDetailData}
        id={searchParams.get('id')}
      />
    </div>
  )
}
