import React, {useState, useMemo, useLayoutEffect} from 'react'
import {Card, Table, Badge, Form, InputGroup, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import dayjs from 'dayjs'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {InvoiceResponse} from '../../modules/types/paymentInvoices'
import InvoicesDetails from './InvoicesDetails'

// Format currency with proper separator and currency symbol
const formatCurrency = (amount: string) => {
  return new Intl.NumberFormat('fa-IR', {
    style: 'currency',
    currency: 'IRR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(amount))
}

// Get status badge variant
const getStatusVariant = (status: string, isExpired: boolean) => {
  if (isExpired) return 'danger'
  switch (status) {
    case 'completed':
      return 'success'
    case 'pending':
      return 'warning'
    case 'failed':
      return 'danger'
    default:
      return 'secondary'
  }
}

const Invoices: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<InvoiceResponse | null>(null)
  const [id, setId] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const handlePageClick = (event: {selected: number}) => {
    getData(event.selected + 1)
  }
  async function getData (page = 1) {
    // fetch data from API
    setLoading(true)
    try {
      // Fetch payment history from API endpoint
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/invoices/?page=${page}`)

      // Handle potential token invalidation or error responses
      errorHandling(data.status)

      // Check for successful response
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        // Log error for unsuccessful requests
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/invoices/ \n`,
          data
        )
        setLoading(false)
      }
    } catch (e) {
      // Log and handle any errors during API call
      setLoading(false)
    } finally {
      // Ensure loading state is set to false
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    // Fetch data on component mount
    getData()
  }, [])
  return (
    <>
      <Card className='shadow-sm'>
        <Card.Header className='border-0 bg-transparent py-3'>
          <div className='d-flex flex-wrap justify-content-between align-items-center gap-3'>
            <h4 className='mb-0'>{intl.formatMessage({id: 'Invoices'})}</h4>
          </div>
        </Card.Header>

        <Card.Body className={`px-4 py-0 ${loading && 'skeleton'}`}>
          <div className='table-responsive'>
            <Table hover className='mb-0 align-middle'>
              <thead className='bg-light'>
                <tr>
                  <th style={{padding: '0.75rem'}}>{intl.formatMessage({id: 'Name'})}</th>
                  <th>{intl.formatMessage({id: 'Type'})}</th>
                  <th className=''>{intl.formatMessage({id: 'Amount'})}</th>
                  <th className=''>{intl.formatMessage({id: 'Discount'})}</th>
                  <th className=''>{intl.formatMessage({id: 'Final Amount'})}</th>
                  <th>{intl.formatMessage({id: 'Period'})}</th>
                  <th>{intl.formatMessage({id: 'Expiry'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'Status'})}</th>
                </tr>
              </thead>
              <tbody>
                {data?.results.map((invoice) => (
                  <tr
                    key={invoice.id}
                    className='cursor-pointer'
                    onClick={() => {
                      setId(invoice.id)
                    }}
                  
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invoice'
                  >
                    <td style={{padding: '0.75rem'}}>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold'>{invoice.invoiced_object.name}</span>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold'>{invoice.invoiced_object_type}</span>
                      </div>
                    </td>
                    <td className=''>{formatCurrency(invoice.amount)}</td>
                    <td className=''>
                      {Number(invoice.discount_amount) > 0 ? (
                        <span className='text-success'>
                          {formatCurrency(invoice.discount_amount)}
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className='fw-bold'>{formatCurrency(invoice.final_amount)}</td>
                    <td>
                      <div className='d-flex flex-column'>
                        <small>{dayjs(invoice.billing_period_start).format('YYYY/MM/DD')} -</small>
                        <small>{dayjs(invoice.billing_period_end).format('YYYY/MM/DD')}</small>
                      </div>
                    </td>
                    <td>
                      {invoice.is_expired ? (
                        <span className='text-danger'>
                          {dayjs(invoice.expiry_date).format('YYYY/MM/DD')}
                        </span>
                      ) : (
                        dayjs(invoice.expiry_date).format('YYYY/MM/DD')
                      )}
                    </td>
                    <td className='text-center'>
                      <Badge
                        bg={getStatusVariant(invoice.payment_status, invoice.is_expired)}
                        className='px-3 py-2'
                      >
                        {invoice.is_expired
                          ? intl.formatMessage({id: 'Expired'})
                          : intl.formatMessage({id: invoice.payment_status})}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>

        <Card.Footer className='bg-transparent'>
          <div className='d-flex justify-content-between align-items-center'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={data?.total_pages || 1}
              forcePage={(data?.current_page || 1) - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </Card.Footer>
      </Card>
      <InvoicesDetails id={id} setId={setId} />
    </>
  )
}

export default Invoices
