import {
  Edge,
  EdgeProps,
  getBezierPath,
  Node,
  useOnSelectionChange,
  useReactFlow,
} from '@xyflow/react'
import React, {useCallback, useState} from 'react'
import {NodeData} from '../../../modules/types/workflow'

/**
 * CustomEdge component renders a custom edge in a React Flow diagram.
 *
 * @component
 * @param {EdgeProps} props - The properties passed to the CustomEdge component.
 * @param {string} props.id - The unique identifier for the edge.
 * @param {number} props.sourceX - The x-coordinate of the source node.
 * @param {number} props.sourceY - The y-coordinate of the source node.
 * @param {number} props.targetX - The x-coordinate of the target node.
 * @param {number} props.targetY - The y-coordinate of the target node.
 * @param {Position} props.sourcePosition - The position of the source node.
 * @param {Position} props.targetPosition - The position of the target node.
 * @param {React.CSSProperties} [props.style={}] - The style object for the edge.
 * @param {string} props.source - The ID of the source node.
 * @param {string} props.sourceHandleId - The ID of the source handle.
 * @param {string} props.targetHandleId - The ID of the target handle.
 * @param {string} props.target - The ID of the target node.
 *
 * @returns {JSX.Element} The rendered CustomEdge component.
 *
 */
const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  source,
  sourceHandleId,
  targetHandleId,
  target,
}) => {
  const [selectedNodes, setSelectedNodes] = useState<any[]>([])
  const [selectedEdges, setSelectedEdges] = useState<any[]>([])
  const {getNode} = useReactFlow()
  const sourceNode = getNode(source)
  const targetNode = getNode(target)
  // the passed handler has to be memoized, otherwise the hook will not work correctly
  const onChange = useCallback(({nodes,edges}: {nodes: Node[],edges:Edge[]}) => {
    setSelectedNodes(nodes.map((node) => node.id))
    setSelectedEdges(edges.map((edge) => edge.id))
  }, [])
  useOnSelectionChange({onChange})
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const data: NodeData | undefined = sourceNode?.data as NodeData | undefined
  /**
   * Finds the color from the data ports based on the source or target handle ID.
   *
   * @param {any} data - The data object containing ports information.
   * @param {string} sourceHandleId - The ID of the source handle.
   * @param {string} targetHandleId - The ID of the target handle.
   * @returns {any} The item from the ports array that matches the source or target handle ID.
   */
  const findeColor = data?.ports?.source?.find(
    (item: any) => item.id === sourceHandleId || item.id === targetHandleId
  ) 
  const isSelected = selectedEdges.includes(id);
  
  return (
    <g className='react-flow__edge' >
      <defs>
        <marker
          id={`arrow-${id}`}
          viewBox='0 0 10 10'
          refX='5'
          refY='5'
          markerWidth='4'
          markerHeight='4'
          orient='auto'
          
        >
          <path d='M 0 0 L 10 5 L 0 10 z'  fill={ sourceNode?.selected || targetNode?.selected || isSelected ? findeColor?.color : '#505050'} />
        </marker>
      </defs>
      <path
        id={id}
        style={{
          ...style,
          stroke: sourceNode?.selected || targetNode?.selected || isSelected  ? findeColor?.color : '#505050',
        }}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={`url(#arrow-${id})`}
      />
    </g>
  )
}

export default CustomEdge
