import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ThemeModeProvider} from '../_metronic/partials'

const App = () => {

  const queryClient = new QueryClient()
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

export {App}
