import React from 'react'
import {useIntl} from 'react-intl'

export default function FormatAndConvertAmount (input: string | number) {
  const intl = useIntl()
  let number: number
  if (typeof input === 'string') {
    number = parseFloat(input)
    if (isNaN(number)) {
      return 'ورودی نامعتبر است'
    }
  } else {
    number = input
  }

  const tomanValue = number / 10

  return (
    <span>
      {new Intl.NumberFormat().format(tomanValue)}
      {' '}
      <span className='text-muted'>{intl.formatMessage({id: 'Toman'})}</span>
    </span>
  )
}
