import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useIntl } from 'react-intl';
import InfoIcon from '../../images/InfoIcon';

interface ChartDataItem {
  keyword: string;
  clicks: number;
}

interface ChartProps {
  header: string;
  headerRowTitle1: string;
  headerRowTitle2: string;
  headerRowColor: string;
  bodyRowsTitleColor: string;
  bodyRowsDataColor: string;
  chartData: ChartDataItem[];
}

interface BarChartAssetProps {
  data: ChartProps;
  loading: boolean;
}

const BarChartAsset: React.FC<BarChartAssetProps> = ({ data, loading }) => {
  const intl = useIntl();
  const maxChartWidth = Math.max(...data.chartData?.map(item => item.clicks));

  // Configuration for severity levels and their icons/colors
  const severityConfig: Record<string, { icon: string; colorClass: string }> = {
    'Sensetive Files': { 
      icon: 'fas fa-file-shield', 
      colorClass: 'danger' 
    },
    'XSS': { 
      icon: 'fas fa-bug', 
      colorClass: 'warning' 
    },
    'Open Ports': { 
      icon: 'fas fa-ethernet', 
      colorClass: 'info' 
    }
  };

  return (
    <div className={`card h-100  ${loading ? 'skeleton' : ''}`}>
      {/* Chart Header */}
      <div className=" h-50px  border-0 d-flex justify-content-between align-items-center p-4 ps-5 bg-secondary rounded-top">
        <div className="d-flex align-items-center ">
          <span className="fs-6 fw-bold mb-0 ">
            {intl.formatMessage({ id: data.header })}
          </span>
          <span 
            data-tooltip-id={`tooltip-${data.header}`}
            className="d-flex align-items-center"
          >
            <InfoIcon />
          </span>
        </div>
        <span className="badge bg-primary-subtle text-primary px-3 py-2">
          <i className="fas fa-shield-check me-1"></i>
          24h
        </span>
      </div>

      {/* Chart Content */}
      <div className="card-body p-4 pb-0">
        {/* Header Row */}
        <div className="row mb-4" style={{ color: data.headerRowColor }}>
          <div className="col-7">
            <h4 className="fs-6 fw-bold mb-0">
              {intl.formatMessage({ id: data.headerRowTitle1 })}
            </h4>
          </div>
          <div className="col-5">
            <h4 className="fs-6 fw-bold mb-0">
              {intl.formatMessage({ id: data.headerRowTitle2 })}
            </h4>
          </div>
        </div>

        {/* Chart Items */}
        {data.chartData.map((item, index) => (
          <div 
            key={item.keyword}
            className="row py-3 align-items-center"
            style={index !== data.chartData.length - 1 ? {
              borderBottom: '1px dashed',
              borderColor: data.headerRowColor
            } : {}}
          >
            {/* Item Label */}
            <div className="col-7 d-flex align-items-center gap-3">
              <div className={`d-flex align-items-center justify-content-center p-2 rounded bg-${severityConfig[item.keyword]?.colorClass}-subtle`}>
                <i className={`${severityConfig[item.keyword]?.icon} text-${severityConfig[item.keyword]?.colorClass}`}></i>
              </div>
              <span 
                className="fw-semibold" 
                style={{ color: data.bodyRowsTitleColor }}
              >
                {intl.formatMessage({ id: item.keyword })}
              </span>
            </div>

            {/* Progress Bar */}
            <div className="col-5 px-5">
              <div className="position-relative">
                <div 
                  className="progress bg-gray-200"
                  style={{ height: '12px' }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${(item.clicks / maxChartWidth) * 100}%`,
                      backgroundColor: data.bodyRowsDataColor,
                      borderRadius: '0 4px 4px 0'
                    }}
                    aria-valuenow={item.clicks}
                    aria-valuemin={0}
                    aria-valuemax={maxChartWidth}
                  >
                    <span className="position-absolute end-0 translate-middle-y start-100 ms-2 text-link-light ">
                      {item.clicks}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Chart Footer */}
      {/* <div className="card-footer bg-transparent border-0 p-4 py-0">
        <div className="d-flex justify-content-between align-items-center">
          <small className="text-muted">
            <i className="fas fa-clock me-1"></i>
            {intl.formatMessage({ id: 'Last updated' })}: 2h
          </small>
          <button className="btn btn-sm btn-outline-primary px-3">
            {intl.formatMessage({ id: 'View Details' })}
            <i className="fas fa-arrow-right ms-2"></i>
          </button>
        </div>
      </div> */}

      {/* Tooltip */}
      <Tooltip 
        id={`tooltip-${data.header}`}
        className="fs-7 bg-dark text-white mw-200px"
      >
        {intl.formatMessage({ id: 'Top Vulnerabilities tooltip' })}
      </Tooltip>
    </div>
  );
};

export default BarChartAsset;