import MonacoEditor from '@monaco-editor/react'
import * as yaml from 'js-yaml'
import {useThemeMode} from '../../../_metronic/partials'

/**
 * CodeEditor component for YAML syntax editing and validation
 *
 * @component
 * @param {Object} props - Component properties
 * @param {Function} props.setError - Function to set error state
 * @param {Function} props.setAnswer - Function to update answer state
 * @param {string} props.answer - Current answer/content in the editor
 *
 * @returns {React.ReactElement} Monaco Editor component with YAML validation
 *
 * @example
 * <CodeEditor
 *   setError={setErrorHandler}
 *   setAnswer={setAnswerHandler}
 *   answer={currentAnswer}
 * />
 */
export default function CodeEditor ({
  setError,
  setAnswer,
  answer,
}: {
  setAnswer: React.Dispatch<React.SetStateAction<string>>
  answer: string
  setError: React.Dispatch<React.SetStateAction<string | null>>
}) {
  // Get current theme mode from Metronic theme hook
  const theme = useThemeMode().mode

  /**
   * Handles editor content changes with YAML syntax validation
   *
   * @param {string|undefined} value - New editor content
   */
  const handleEditorChange = (value: string | undefined) => {
    try {
      // Attempt to parse YAML to validate syntax
      yaml.load(value || '')
      setError(null)
    } catch (e) {
      // Set error if YAML syntax is incorrect
      setError('YAML syntax is incorrect')
    }

    // Update answer state with new content
    setAnswer(value || '')
  }

  return (
    <MonacoEditor
      height='100%'
      language='yaml'
      value={answer}
      onChange={(e) => handleEditorChange(e)}
      theme={theme === 'dark' ? 'vs-dark' : 'light'}
      loading={<div className='h-100 w-100 skeleton'></div>}
      options={{
        minimap: {
          enabled: false,
        },
      }}
    />
  )
}
