import React from 'react'
import {useSearchParams} from 'react-router-dom'
const ports = {
  target: [
    {
      id: '01JJ6RTMPZMHY8S0Y20185A65G',
      label: 'domain',
      color: '#00ff00',
    },
    {
      id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      label: 'url',
      color: 'orange',
    },
  ],
  source: [
    {
      id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      label: 'link',
      color: '#0000ff',
    },
    {
      id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      label: 'ip',
      color: 'red',
    },
    {
      id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      label: 'url',
      color: 'orange',
    },
  ],
}
const DEFAULT_HANDLE_STYLE = {
  width: 20,
  height: 20,
  bottom: -5,
  fontSize: 8,
  borderRedus: '100%',
}
function findPosition (ind: number) {
  if (ind === 0) {
    return '50%'
  }
  if (ind === 1) {
    return `calc(50% + ${ind * 40}px)`
  }
  if (ind % 2 === 0) {
    return `calc(50% - ${ind * 20}px)`
  }
  return `calc(50% + ${ind * 20}px)`
}
export default function NodeDetails () {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  return (
    <div className='p-5 card'>
      <h2>{'Node Name'}</h2>
      <div className='d-flex my-5' style={{gap: '7rem'}}>
        <div className='d-flex flex-column align-items-center'>
          <p className='text-muted'>Type</p>
          <p className=''>Tools</p>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <p className='text-muted'> Original repo</p>
          <p className=''>carlospolop/404checker</p>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <p className='text-muted'>Published by</p>
          <p className=''> trickest-mhmdiaa </p>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <p className='text-muted'>Category</p>
          <p className=''> Discovery </p>
        </div>
      </div>
      <h3>
        Auxiliary script thought to be used in Red Team exercises to check if a URL redirects to a
        masked 404 (such as 200 that redirects to a "Not found" page or similars). URLs must be
        passed sorted in order to improve performance.{' '}
      </h3>
      <hr />
      <div className='d-flex justify-content-between'>
        <div
          className='d-flex justify-content-center border-end'
          style={{minWidth: 500, width: '30%'}}
        >
          <div
            className='position-relative min-w-200px min-h-200px large_item'
            //   style={item.data.icon ? {backgroundImage: `url(${item.data.icon})`} : {}}
          >
            {ports?.source?.map((port: any, index: number) => {
              return (
                <div
                  className='react-flow__handle-right position-absolute rounded-circle source'
                  key={index}
                  id={port.id}
                  style={{
                    ...DEFAULT_HANDLE_STYLE,
                    top: findPosition(index),
                    border: `3px solid ${port.color}`,
                    right: -10,
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: 5,
                      left: 22,
                      color: port.color,
                      textWrap: 'nowrap',
                      fontSize: 16,
                    }}
                  >
                    {port.label}
                  </span>
                </div>
              )
            })}
            {ports?.target?.map((port: any, index: number) => {
              return (
                <div
                  className='react-flow__handle-right position-absolute rounded-circle source'
                  key={index}
                  style={{
                    ...DEFAULT_HANDLE_STYLE,
                    top: findPosition(index),
                    border: `3px solid ${port.color}`,
                    left: -27,
                  }}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: 5,
                      color: port.color,
                      textWrap: 'nowrap',
                      fontSize: 16,
                      left: -7,
                      transform: 'translateX(-100%)',
                    }}
                  >
                    {port.label}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
        <p className='p-4'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, repellat id
          asperiores suscipit nam iusto corrupti exercitationem? Optio alias debitis a veniam,
          impedit, molestiae iusto sequi facere enim, quis maiores!
          <br />
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nam culpa quod vitae? A dolor,
          nisi voluptatem repellendus voluptates temporibus assumenda nulla asperiores nesciunt
          illum! A saepe iusto doloribus sunt est.
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, vel velit
          consequuntur animi recusandae distinctio, doloremque ab, vero voluptatum neque qui natus
          nisi quisquam aliquid est maxime dolor nobis enim.
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, repellat id
          asperiores suscipit nam iusto corrupti exercitationem? Optio alias debitis a veniam,
          impedit, molestiae iusto sequi facere enim, quis maiores!
        </p>
      </div>
    </div>
  )
}
