import {useIntl} from 'react-intl'
import {Button} from 'react-bootstrap'
import Starts from '../images/Starts'
import {AI} from '../../modules/auth/redux/DEMO_DATA'
import {KeyboardEvent, useRef, useState} from 'react'
import {Modal as BootstrapModal} from 'bootstrap'
/**

Modal component for AI-generated content suggestions

@component
@param {Object} props - Component properties
@param {Function} props.generate - Function to generate content from modal

@returns {React.ReactElement} AI suggestion modal

@example

<Modal generate={handleContentGeneration} />


*/
export default function Modal ({generate}: {generate: (content: string) => void}) {
  const intl = useIntl()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [indexPlaceholder, setIndexPlaceholder] = useState(0)
  const [value, setValue] = useState('')

  // useEffect(() => {
  //   const modalElement = document.getElementById('kt_modal_AI')
  //   console.log('event')
  //   if (modalElement) {
  //     const handleShown = () => {
  //       if (textareaRef.current) {
  //         textareaRef.current.focus()
  //         console.log('Focused:', document.activeElement === textareaRef.current)
  //       }
  //     }

  //     // Attach event listener for when the modal is fully shown
  //     modalElement.addEventListener('shown.bs.modal', handleShown)

  //     return () => {
  //       modalElement.removeEventListener('shown.bs.modal', handleShown)
  //     }
  //   }
  // }, [])

  /**

Changes placeholder text by moving through predefined AI suggestions

@param {'mines'|'pluse'} prop - Direction to move through suggestions
*/
  function changePlaceholder (prop: 'mines' | 'pluse') {
    if (!value) {
      if (prop === 'pluse') {
        setIndexPlaceholder((prev) => (prev < AI.length - 1 ? prev + 1 : 0))
      } else {
        setIndexPlaceholder((prev) => (prev > 0 ? prev - 1 : AI.length - 1))
      }
    }
  }

  /**
  
  Selects current placeholder text if no value is entered
  */
  function selectPlaceholder () {
    if (!value) {
      setValue(AI[indexPlaceholder])
    }
  }

  /**
  
  Handles keyboard navigation for placeholder suggestions
  
  @param {KeyboardEvent} e - Keyboard event
  */
  function keydown (e: KeyboardEvent<HTMLDivElement>) {
    if (e.code === 'Tab') {
      selectPlaceholder()
    }
    if (e.code === 'ArrowRight') {
      changePlaceholder('pluse')
    }
    if (e.code === 'ArrowLeft') {
      changePlaceholder('mines')
    }
  }

  /**
  
  Closes the Bootstrap modal programmatically
  */
  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_AI')
    if (modalElement) {
      const modalInstance = BootstrapModal.getOrCreateInstance(modalElement)
      modalInstance.hide()
    }
  }
  return (
    <>
      <div
        className={`modal fade `}
        id='kt_modal_AI'
        role='dialog'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setValue('')
          setIndexPlaceholder(0)
        }}
      >
        <div
          onKeyDown={(e) => keydown(e)}
          className='modal-dialog p-0 '
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header p-2 justify-content-between align-item-center px-4'>
              <div className='d-flex align-items-center gap-2'>
                <Starts className='image_change_theme' />
                <p className='p-0 m-0 fw-bold fs-5 '>
                  {intl.formatMessage({id: 'Vulnerability steps'})}
                </p>
              </div>

              <Button
                size='sm'
                className='btn btn-icon btn-sm btn-active-light-primary ms-2  p-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                style={{width: 'fit-content'}}
                onClick={() => {
                  generate(value)
                  setValue('')
                  setIndexPlaceholder(0)
                  closeModal()
                }}
              >
                {intl.formatMessage({id: 'Generate'})}
              </Button>
            </div>

            <div className='modal-body h-400px p-0 position-relative'>
              <textarea
                ref={textareaRef}
                value={value}
                className=' w-100  bg-transparent position-relative '
                style={{resize: 'none', outline: 'none', border: 'none', height: 350, zIndex: 2}}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
              />
              {!value && (
                <pre
                  className='w-100 position-absolute overflow-hidden top-0 left-0 text-gray-500 abc'
                  style={{height: 350, zIndex: '1'}}
                >
                  {AI[indexPlaceholder]}
                </pre>
              )}
              <div className='d-flex align-items-center justify-content-between w-100 h-50px px-4'>
                <div className=' d-flex align-items-center gap-2'>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary p-1'
                    onClick={selectPlaceholder}
                  >
                    TAB
                  </Button>
                  {intl.formatMessage({id: 'To choose from tips'})}
                </div>
                <div className=' d-flex align-items-center gap-2'>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary fw-bolder  px-2'
                    onClick={() => {
                      changePlaceholder('mines')
                    }}
                  >
                    {`<`}
                  </Button>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary fw-bolder  px-2'
                    onClick={() => {
                      changePlaceholder('pluse')
                    }}
                  >{`>`}</Button>
                  {intl.formatMessage({id: 'Arrow keys to move tips'})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
