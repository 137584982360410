import React from "react";
import {toast} from 'sonner'
const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "#f8f9fa",
      textAlign: "center",
    },
    icon: {
        fontSize: "5rem",
        color:"#6c757d",
        marginBottom: "1rem",
    },
    image: {
      width: "150px",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "2rem",
      color: "#333",
      marginBottom: "10px",
    },
    text: {
      fontSize: "1rem",
      color: "#666",
      marginBottom: "20px",
    },
    button: {
      backgroundColor: "#007bff",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "1rem",
    },
  };
const OfflinePage = () => {
  const retryConnection = () => {
    if (navigator.onLine) {
      window.location.reload();
    } else {
        toast.info("Still offline. Please check your connection and try again.")
    }
  };

  return (
    <div style={styles.container as React.CSSProperties}>
        <div style={styles.icon}>
            ⚠️
        </div>
      <h1 style={styles.heading}>You Are Offline</h1>
      <p style={styles.text}>
        Please check your internet connection and try again.
      </p>
      <button style={styles.button} onClick={retryConnection}>
        Retry
      </button>
    </div>
  );
};



export default OfflinePage;
