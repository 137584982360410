import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {PRICE_PLAN_CARD_DATA, PRICE_PLAN_TABLE} from '../../modules/auth/redux/DEMO_DATA'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import Modal from './Modal'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {SetDataPricePlans} from '../../modules/auth'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import InfoIcon from '../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Question from '../images/Question'
import Chat from '../images/Chat'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import Arr001 from '../components/Arr001'
import Check from '../images/Ckeck'
import { Plan, PlanType } from '../../modules/types/plans'

/**
 * Defines the steps for a guided tour of the service features.
 * Each step targets a specific element on the page and provides content to be displayed.
 * 
 * @constant
 * @type {Array<{target: string, content: string}>}
 * @property {string} target - The CSS selector for the element to highlight during the step.
 * @property {string} content - The text content to display for the step, describing the feature (in Persian).
 * @returns {Array<{target: string, content: string}>} An array of step objects for use with a tour guide component.
 */
const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-4',
    content: 'این ویژگی 4 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-5',
    content: 'این ویژگی 5 در صفحه سرویس است.',
  },
]




export default function PricePlan () {
  const intl = useIntl()
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const pricePlanData = useSelector((state: RootState) => state.root.data.dataPricePlans)
  const [details, setDetails] = useState<Plan | null>(null)
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const [productSelectedIndex, setProductSelectedIndex] = useState<null | number>(null)
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const front = useRef<null | any>(null)
  const back = useRef<null | any>(null)
  /**
   * Handles the callback events from the Joyride tour component.
   * This function is responsible for managing the tour's completion or skipping,
   * and redirecting the user to the scan page when the tour ends.
   *
   * @param {CallBackProps} data - The callback data provided by Joyride.
   * @param {string} data.status - The current status of the tour ('finished' or 'skipped').
   *
   * @returns {void}
   */
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    // When the tour is finished, redirect to the scan Page
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/scan')
    }
  }

  /**
   * Fetches price plans from the server and updates the application state.
   * 
   * This asynchronous function sends a GET request to the server's plans endpoint,
   * handles the response, and dispatches the received data to update the application state.
   * It also includes error handling for invalid tokens and unsuccessful requests.
   * 
   * @async
   * @function fetchPricePlans
   * @throws {Error} Logs any errors that occur during the fetch operation
   * @returns {Promise<void>} A promise that resolves when the operation is complete
   */
  const fetchPricePlans = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/plans/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPricePlans(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/plans/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Effect hook to manage price plan data fetching and new user onboarding.
   * 
   * This effect runs once on component mount and performs the following tasks:
   * 1. Checks if the current price plan data is expired and fetches new data if necessary.
   * 2. Initiates the onboarding process for new users.
   * 
   * @effect
   * @param {void}
   * @returns {void}
   */
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (pricePlanData?.expireTime && pricePlanData.expireTime >= nowDate) {
    } else {
      fetchPricePlans()
    }
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      className='mb-10 h-750px overflow-hidden'
      style={{
        minHeight:
          productSelectedIndex === null
            ? front?.current?.clientHeight
            : back?.current?.clientHeight,
      }}
    >
      <div
        className='position-relative w-100 inner_box'
        style={
          productSelectedIndex !== null
            ? {transform: 'rotateY(180deg)'}
            : {transform: 'rotateY(0deg)'}
        }
      >
        <div className='p-5 w-100 vh-100 card front_card' ref={front}>
          <div className=' '>
            <h1 className='mb-5 fw-bold text-center fs-2hx'>
              {' '}
              {intl.formatMessage({id: 'Choose Your Product'})}
            </h1>
            <div className='fw-semibold text-center text-gray-600 fs-5'>
              {intl.formatMessage({id: 'If you need more info about our products, please check'})}{' '}
              <Link to={'/'} className='fw-bold link-primary'>
                {intl.formatMessage({id: 'Products Guidelines'})}
              </Link>
              .
            </div>
          </div>
          <div className='align-items-stretch my-10 w-100 g-10 row'>
            {pricePlanData.data?.map((items, ind: number) => (
              <div
                className='col-4'
                key={ind}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setProductSelectedIndex(ind)
                }}
              >
                <div className='h-100 service-feature-4'>
                  <div className='d-flex align-items-center h-100 h-100'>
                    <div className='d-flex flex-column justify-content-between bg-light bg-opacity-75 px-10 py-15 rounded-3 w-100 h-100'>
                      <div>
                        <div className='d-flex flex-column align-items-center mb-7 text-center'>
                          <h1 className='mb-5 fw-bolder text-gray-900 text-capitalize'>
                            {items.name}
                          </h1>
                          <div
                            className='mb-5 fw-semibold text-gray-600 overflow-hidden'
                            style={{
                              maxWidth: '50ch',
                              height: '90px',
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                            }}
                          >
                            {items.description}
                          </div>
                        </div>
                        <button
                          className='mb-7 px-5 rounded-pill align-self-center btn btn-primary btn-sm'
                          type='button'
                        >
                          {intl.formatMessage({id: 'Select'})}
                        </button>
                        <div className='w-100'>
                          {items.features.map((item, index) => (
                            <div className='d-flex align-items-center mb-5' key={index}>
                              <span className='flex-grow-1 fw-semibold text-gray-800 fs-6 pe-3'>
                                {intl.formatMessage({id: item})}
                                {/* {item.title} */}
                              </span>
                             
                                <i className='text-success fs-1 ki-check-circle ki-duotone'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                           
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='p-5 w-100 back_card_y card' ref={back}>
          {/* title */}
          <div className='service-feature-one'>
            <h1 className='mb-5 fw-bold text-center fs-2hx'>
              {' '}
              {intl.formatMessage({id: 'Choose Your Plan'})}
            </h1>
            <div className='fw-semibold text-center text-gray-600 fs-5'>
              {intl.formatMessage({id: 'If you need more info about our pricing, please check'})}{' '}
              <Link to={'/'} className='fw-bold link-primary'>
                {intl.formatMessage({id: 'Pricing Guidelines'})}
              </Link>
              .
            </div>
          </div>
          {/* plan card */}
          <div className='service-feature-two'>
            <div className='d-flex flex-column gap-4 w-100'>
              <button
                className={`col-auto border border-0   p-0 m-0 position-absolute `}
                style={{rotate: '180deg', background: 'unset'}}
                onClick={() => {
                  setProductSelectedIndex(null)
                }}
              >
                <Arr001 fill={'#fff'} className={'image_change_theme'} />
              </button>
              <div className='justify-content-center align-items-stretch mt-10 w-100 row'>
                {productSelectedIndex!==null&&pricePlanData.data?.[productSelectedIndex].plans?.map(
                  (items, ind: number) => (
                    <div
                      className='position-relative p-0 col-4'
                      key={items.id}
                      style={{
                        filter: !pricePlanData?.data? 'blur(3px)' : 'blur(0px)',
                        transform:
                          ind === 0
                            ? 'translateX(15px)'
                            : ind === 1
                            ? 'translateX(0) scale(1.04)'
                            : 'translateX(-15px) ',
                        zIndex: ind === 1 ? 100 : 1,
                      }}
                    >
                      <div className='h-100 service-feature-4'>
                        <div className='d-flex align-items-center h-100 h-100'>
                          <div
                            className={`w-100 h-100  d-flex flex-column justify-content-between ${
                              ind === 1 ? 'bg-primary-subtle' : 'bg-light-subtle'
                            }   py-15 px-10 border shadow-sm`}
                            style={{borderRadius: '30px'}}
                          >
                            <div>
                              <div className='d-flex flex-column mb-7'>
                                <h1
                                  className='mb-5 fw-bolder text-gray-900 text-capitalize'
                                  style={{width: 'fit-content', fontSize: '35px'}}
                                >
                                  {items.name}
                                </h1>
                                <div
                                  className='mb-5 fw-semibold text-gray-600'
                                  style={{
                                    // textAlign:'start',
                                    maxWidth: '50ch',
                                    height: '40px',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  {items.description}
                                </div>
                                {items?.is_purchasable ? (
                                  <>
                                    {!!items.discounted_price && (
                                      <span
                                        className='fw-bold text-gray-600'
                                        style={{textDecoration: 'line-through', fontSize: 20}}
                                      >
                                        {FormatAndConvertAmount(items.price)}
                                        {}
                                      </span>
                                    )}
                                    <div className='d-flex align-items-end text-center'>
                                      <span className='fw-bold text-primary fs-2x'>
                                        {items.discounted_price
                                          ? FormatAndConvertAmount(items.discounted_price)
                                          : FormatAndConvertAmount(items.price)}
                                      </span>
                                      <span className='mb-2 text-primary'>
                                        {intl.formatMessage({id: 'IR-T'})}
                                      </span>
                                      <span className='opacity-50 mb-2 fw-semibold fs-7'>
                                        /
                                        <span data-kt-element='period' className='text-capitalize'>
                                          {items.duration_days} {intl.formatMessage({id: 'day'})}
                                        </span>
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <div style={{height: 67}}>
                                    <Chat className='image_change_theme2 svg-icon-3x svg-icon-primary' />
                                    <p className='m-0 p-0 fw-bold'>
                                      {intl.formatMessage({id: 'Contact us for more information'})}
                                    </p>
                                  </div>
                                )}
                                <div className='mt-5'>
                                  {items.is_purchasable ? (
                                    <button
                                      onClick={() => {
                                        setDetails(items)
                                      }}
                                      className='px-5 rounded-pill align-self-center btn btn-primary btn-sm'
                                      type='button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_edit'
                                      disabled={!pricePlanData.data?.length}
                                    >
                                      {intl.formatMessage({id: 'Select'})}
                                    </button>
                                  ) : (
                                    <button
                                      className='px-5 rounded-pill align-self-center btn btn-info btn-sm'
                                      onClick={() => {
                                        navigate('/ticket')
                                      }}
                                    >
                                      {intl.formatMessage({id: 'Contact us'})}
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div className='mb-10 w-100'>
                                {
                                  items.features?.map((item, index) => (
                                    <div
                                      className='d-flex align-items-center gap-2 mb-5'
                                      key={index}
                                    >
                                      <Check className='image_change_theme2 svg-icon-md' />
                                      <span className='fw-semibold text-gray-800 pe-3'>
                                        {intl.formatMessage({id: item})}
                                        {/* {item.title} */}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* plan table */}
          <div className='service-feature-5'>
            <table className='mt-7 align-middle gy-7 table table-striped'>
              <tbody>
                <tr>
                  <th className='card-rounded-start'>
                    <div className='d-flex align-items-center fw-bolder fs-3 ps-9'>
                      {intl.formatMessage({id: 'Feature'})}
                    </div>
                  </th>
                  {PRICE_PLAN_CARD_DATA[0].plans.map((item, ind, arr) => (
                    <td key={ind} className={`${ind === arr.length - 1 && 'card-rounded-end'}`}>
                      <div className='d-flex flex-root justify-content-center fw-bolder text-capitalize fs-3'>
                        {item.name}
                      </div>
                    </td>
                  ))}
                </tr>

                {PRICE_PLAN_TABLE.map((item, ind) => (
                  <tr key={ind}>
                    <th className={'card-rounded-start'}>
                      <div
                        className='d-flex align-items-center gap-2 fw-bolder fs-3 ps-9'
                        style={item.group ? {color: 'red'} : {}}
                      >
                        {intl.formatMessage({id: item.title})}
                        {!item.group && (
                          <>
                            <span
                              data-tooltip-id={'my-tooltip' + ind}
                              data-tooltip-content={intl.formatMessage({
                                id: item.description,
                              })}
                            >
                              <Question className='image_change_theme2 svg-icon-2x' />
                            </span>
                            <ReactTooltip
                              id={'my-tooltip' + ind}
                              style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                            />
                          </>
                        )}
                      </div>
                    </th>
                    {item.data.map((i, index, arr) => (
                      <td
                        key={index}
                        className={`${
                          index === arr.length - 1 ? 'card-rounded-end ' : 'no_reduce'
                        }`}
                      >
                        <div className='d-flex flex-root justify-content-center'>
                          {i === true ? (
                            <i className='text-success fs-1 ki-check-circle ki-duotone'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : i === false ? (
                            <i className='fs-1 ki-cross-circle ki-duotone'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : (
                            <span>{intl.formatMessage({id: i || ' '})}</span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Joyride Component */}
      {/* 
       * Renders a Joyride component for guided user onboarding.
       * This component provides a step-by-step tour of the application's features.
       *
       * @param {Object} props - The properties passed to the Joyride component.
       * @param {number | undefined} props.stepIndex - The index of the current step in the tour.
       * @param {Array<Object>} props.steps - An array of step objects defining the tour.
       * @param {boolean} props.run - Determines whether the tour should start running.
       * @param {boolean} props.disableOverlay - If true, disables the overlay that dims the background.
       * @param {boolean} props.disableScrolling - If true, prevents automatic scrolling to steps.
       * @param {boolean} props.showProgress - If true, displays a progress indicator.
       * @param {boolean} props.disableOverlayClose - If true, prevents closing the tour by clicking on the overlay.
       * @param {boolean} props.continuous - If true, allows the tour to progress continuously without user interaction.
       * @param {boolean} props.showSkipButton - If true, displays a skip button to end the tour early.
       * @param {Function} props.callback - A function to handle various tour events.
       * @param {Object} props.locale - An object containing localized strings for UI elements.
       * @param {number} props.spotlightPadding - The padding around the spotlight area.
       * @param {Object} props.styles - Custom styles for the Joyride component.
       * @returns {JSX.Element} A Joyride component configured for the application's guided tour.
       */ }
      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={true}
        disableOverlayClose={true}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{next: 'بعدی', skip: 'رد کردن'}}
        spotlightPadding={5}
        styles={{
          options: {
            zIndex: 10000,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      />

      {/* 
       * Renders a Modal component for displaying and managing plan details.
       *
       * @param {Object} props - The properties passed to the Modal component.
       * @param {PlanType | null} props.details - The current plan details to be displayed in the modal.
       *                                          If null, no plan is currently selected.
       * @param {React.Dispatch<React.SetStateAction<PlanType | null>>} props.setDetails - A function to update the details state.
       *                                                                                   Used to clear the modal when it's closed.
       * @returns {JSX.Element} A Modal component for displaying and managing plan details.
       */ }
      <Modal details={details} setDetails={setDetails} />

    </div>
  )
}
