import React from 'react'
import {useIntl} from 'react-intl'
import ChartLine from './ChartLine'
import ColumnChart from './ColumnChart'

/**
 * Chart component that renders either line or column charts based on the provided data
 * @param {Object} props - Component props
 * @param {Array} props.list - Array of data items to be displayed in the chart
 * @param {('line'|'column')} [props.type='line'] - Type of chart to render
 * @returns {JSX.Element} Rendered Chart component
 */
export default function Chart ({list = [], type ='line'}: {list: any[]; type: 'line' | 'column'}) {
  const intl = useIntl()

  return (
    <>
      <hr />
      {/* Chart container with dynamic padding based on chart type */}
      <div 
        className='d-flex justify-content-between gap-5 mw-100 w-100' 
        style={type === 'line' ? {padding: 10} : {padding:'0 10px 0 10px'}}
      >
        {/* Map through the list and render each item */}
        {list.map((item, ind) => (
          <div className='d-flex gap-2 align-center w-50' key={ind}>
            {/* Item details */}
            <div className='d-flex flex-column justify-content-center'>
              <span>{intl.formatMessage({id: item.title})}</span>
              <strong style={{fontSize: 18}}>{item.count}</strong>
            </div>
            {/* Render either line chart or column chart based on the 'type' prop */}
            {type === 'line' ? <ChartLine data={item.data} /> : <ColumnChart data={item.data} />}
          </div>
        ))}
      </div>
    </>
  )
}

