import React, {useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {Alert, Button} from 'react-bootstrap'
import {Modal} from 'bootstrap'
import {toast} from 'sonner'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {useDispatch} from 'react-redux'
import {SetDataTargetTypes} from '../../../modules/auth'
const DomainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
const initialValues = {
  title: '',
  description: '',
  type: '',
}
interface Props {
  get: () => Promise<void>
  id: string | null
  targetPage: 1 | 2
  setTargetPage: React.Dispatch<React.SetStateAction<1 | 2>>
  setId: React.Dispatch<React.SetStateAction<string | null>>
}
export default function CreateTargetModal ({get, id, setId, targetPage, setTargetPage}: Props) {
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const targetTypes = useSelector((state: RootState) => state.root.data.dataTargetTypes)
  const [loading, setLoading] = useState(false)
  const [innerId, setInnerId] = useState('')
  const [target, setTarget] = useState('')
  const [description, setDescription] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [type, setType] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [errShow, setErrShow] = useState('')
  /**
   * Finds a target type object based on the provided type ID.
   * 
   * @param type - The ID of the target type to find.
   * @returns The target type object if found, or undefined if not found.
   */
  function findType(type: string) {
    return targetTypes.data?.find((item: any) => item.id === type)
  }

  /**
   * Verifies the DNS TXT record for the target.
   * 
   * This function sends a GET request to verify the DNS TXT record for the specified target.
   * It handles the response, showing success or error messages, and updates the UI accordingly.
   * 
   * @async
   * @function verifyTXT
   * @throws {Error} If there's an error during the API call or response handling.
   * @returns {Promise<void>} A promise that resolves when the verification process is complete.
   */
  const verifyTXT = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/dns-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/dns-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Verifies the target file.
   * 
   * This function sends a GET request to verify the file for the specified target.
   * It handles the response, showing success or error messages, and updates the UI accordingly.
   * 
   * @async
   * @function verifyFile
   * @throws {Error} If there's an error during the API call or response handling.
   * @returns {Promise<void>} A promise that resolves when the verification process is complete.
   */
  const verifyFile = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/file-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/file-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Creates a new target by sending a POST request to the server.
   * 
   * This function performs the following actions:
   * 1. Sets the loading state to true.
   * 2. Sends a POST request to create a new target.
   * 3. Handles the response, updating the UI accordingly.
   * 4. Manages error cases and displays appropriate messages.
   * 
   * @async
   * @function createTarget
   * @returns {Promise<void>} A promise that resolves when the target creation process is complete.
   * 
   * @throws {Error} If there's an error during the API call or response handling.
   */
  const createTarget = async () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/targets/`, {
        title: target,
        description: description.trim() ? description : null,
        type: type,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          toast.success('Create completed successfully.')
          setType('')
          formik.resetForm()
          setIsAction(true)
          setTargetPage(2)
          setInnerId(res.data.id)
          setVerifyCode(res.data.verification_code)

          setLoading(false)
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data?.error)
        toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /**
   * Fetches the verification code for a target from the server.
   * 
   * This function sends a GET request to retrieve the verification code for a specific target.
   * It updates the UI based on the response, setting the verification code and changing the target page.
   * If an error occurs, it displays an error message.
   *
   * @param {string} id - The unique identifier of the target for which to fetch the verification code.
   * @returns {Promise<void>} A promise that resolves when the verification code fetch operation is complete.
   * @throws {Error} If there's an error during the API call or response handling.
   */
  const fetchVerifyCode = async (id: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setVerifyCode(data.data.verification_code)
        setTargetPage(2)
      } else {
        setErrShow(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setErrShow(intl.formatMessage({id: 'Sorry, an error has occurred'}))
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Defines the validation schema for the login form using Yup.
   * 
   * This schema enforces the following rules:
   * - 'title' field:
   *   - Must be a string
   *   - Minimum length of 3 characters
   *   - Maximum length of 50 characters
   *   - Is required
   *   - Must match the regex pattern of the selected type (if available), otherwise defaults to allowing 1-50 characters
   * - 'type' field:
   *   - Must be a string
   *   - Is required
   * 
   * @constant
   * @type {Yup.ObjectSchema}
   * @property {Yup.StringSchema} title - Validation schema for the title field
   * @property {Yup.StringSchema} type - Validation schema for the type field
   * @returns {Yup.ObjectSchema} The complete validation schema for the login form
   */
  const loginSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Target is required'}))
      .matches(
        new RegExp(findType(type)?.regex?findType(type)?.regex as string:"^.{1,50}$"),
        intl.formatMessage({id: `${findType(type)?.name||'Target'} is not valid`})
      ),
    type: Yup.string().required(intl.formatMessage({id: 'Type is required'})),
  })

  /**
   * Initializes and configures the Formik form for creating a new target.
   * 
   * This hook sets up form validation, initial values, and submission handling
   * for the target creation form.
   * 
   * @param {Object} options - The configuration options for Formik.
   * @param {Object} options.initialValues - The initial values for the form fields.
   * @param {Object} options.validationSchema - The Yup schema for form validation.
   * @param {Function} options.onSubmit - The function to call when the form is submitted.
   * 
   * @returns {FormikProps<typeof initialValues>} The Formik form object with methods and state.
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      createTarget()
    },
  })

  /**
   * Closes the create target modal and resets the action state.
   * 
   * This function performs the following actions:
   * 1. Finds the modal element in the DOM.
   * 2. If the modal element exists, it initializes or retrieves the modal instance.
   * 3. Hides the modal using the Bootstrap Modal API.
   * 4. Resets the action state to false.
   * 
   * @function closeModal
   * @returns {void}
   */
  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_create_target')
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement) // Initialize or get the modal instance
      modalInstance.hide() // Close the modal
    }
    setIsAction(false)
  }

  /**
   * Fetches target types from the server and updates the application state.
   * 
   * This function performs the following actions:
   * 1. Sets the loading state to true.
   * 2. Sends a GET request to retrieve target types.
   * 3. Handles the response, updating the Redux store with the fetched data if successful.
   * 4. Logs any errors that occur during the process.
   * 5. Sets the loading state back to false regardless of the outcome.
   *
   * @async
   * @function fetchTargetTypes
   * @throws {Error} If there's an error during the API call or response handling.
   * @returns {Promise<void>} A promise that resolves when the fetch operation is complete.
   */
  const fetchTargetTypes = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/targets/types/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataTargetTypes(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/types/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * A layout effect hook that manages the fetching of verification codes and target types.
   * 
   * This hook performs the following actions:
   * 1. Fetches the verification code if an ID is present and the target page is 2.
   * 2. Fetches target types if they are expired or not present, and the target page is 1.
   * 
   * @param {Function} fetchVerifyCode - Function to fetch the verification code for a given ID.
   * @param {Function} fetchTargetTypes - Function to fetch the target types.
   * @param {string | null} id - The ID of the target, if available.
   * @param {1 | 2} targetPage - The current page of the target creation/verification process.
   * @param {Object} targetTypes - The current target types data, including expiration time.
   * 
   * @returns {void}
   */
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (id && targetPage === 2) {
      fetchVerifyCode(id)
    }
    if (targetTypes?.expireTime && targetTypes.expireTime >= nowDate) {
    } else {
      if (targetPage === 1) {
        fetchTargetTypes()
      }
    }
  }, [id, targetPage])

  return (
    <div
      className={`modal fade pb-0`}
      id='kt_modal_create_target'
      style={{backdropFilter: 'blur(3px)'}}
      role='dialog'
      aria-bs-labelledby='staticBackdrop'
      data-bs-backdrop='static'
    >
      <div className={`modal-dialog ${loading && 'skeleton'}`} style={{minWidth: '50%'}}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title'>
              {' '}
              {targetPage === 1
                ? intl.formatMessage({id: 'Create New Target'})
                : intl.formatMessage({id: 'Verify Target'})}
            </h3>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => {
                setTarget('')
                setDescription('')
                setId(null)
                setTargetPage(1)
                setInnerId('')
                setType('')
                formik.resetForm()
                if (isAction) {
                  setIsAction(false)
                  get()
                }
                closeModal()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 image_change_theme2'
              />
            </div>
          </div>
          <div className='modal-body'>
            {/* verify target */}
            {targetPage === 2 && (
              <div>
                <div className='card w-100 p-4 bg-gray-400  '>
                  <span style={{lineHeight: 1.6}}>
                    {intl.formatMessage({id: 'config verifyCode to DNS'})}{' '}
                    <mark
                      className=' text-nowrap'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        navigator.clipboard.writeText(verifyCode)
                      }}
                    >
                      {' '}
                      {verifyCode}{' '}
                    </mark>
                  </span>
                </div>
                <div className='d-flex gap-5 mt-5'>
                  <Button
                    onClick={() => {
                      verifyTXT()
                    }}
                  >
                    {intl.formatMessage({id: 'Verify with DNS'})}
                  </Button>
                  <Button
                    onClick={() => {
                      verifyFile()
                    }}
                  >
                    {intl.formatMessage({id: 'Verify with File'})}
                  </Button>
                </div>
              </div>
            )}
            {/* create target */}
            {targetPage === 1 && (
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className=''>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Type'})}
                    </label>
                    <select
                      className={clsx(
                        'form-select form-select-solid form-control form-control-lg form-control-solid ',
                        {
                          'is-invalid': formik.touched.type && formik.errors.type,
                        },
                        {
                          'is-valid': formik.touched.type && !formik.errors.type,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      name='type'
                      onChange={(e) => {
                        if (!e.target.value) {
                          setTarget('')
                          formik.setFieldValue('title', '')
                        }
                        setType(e.target.value)
                        formik.setFieldValue('type', e.target.value)
                      }}
                      value={type}
                    >
                      <option></option>
                      {targetTypes.data&&(targetTypes.data)?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{intl.formatMessage({id: 'Type is required'})}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='form-label fs-6 fw-bolder  '>
                        {intl.formatMessage({id: 'Target'})}{' '}
                      </label>
                    </div>

                    <input
                      placeholder={findType(type)?.example || ''}
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                      type='text'
                      name='title'
                      autoComplete='off'
                      value={target}
                      disabled={!type}
                      onChange={(e) => {
                        setTarget(e.target.value)
                        formik.setFieldValue('title', e.target.value)
                      }}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='form-label fs-6 fw-bolder  '>
                        {intl.formatMessage({id: 'Description'})}{' '}
                      </label>
                    </div>

                    <textarea
                      {...formik.getFieldProps('description')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      name='description'
                      autoComplete='off'
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        formik.setFieldValue('description', e.target.value)
                      }}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' id='kt_sign_in_submit' className='btn btn-success'>
                    {intl.formatMessage({id: 'Create'})}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
