import WorkflowTable from './components/WorkflowTable'
export const WorkFlow = () => {

 

  return (
    <>
      <div className='row d-flex flex-row  mt-5'>
        <WorkflowTable />
      </div>
    </>
  )
}
