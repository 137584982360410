import React, {memo, useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import Warning2 from '../../images/Warning2'
import {Button} from 'react-bootstrap'
import Tool from '../../images/Tool'
interface ConfigItem {
  value: string | null
  status: boolean
  title: string
  discripton: string // Note: keeping the typo as it's in the original data
  command: string
}
type DynamicConfig = ConfigItem[]
const DrawerConfig = memo(
  ({
    show,
    setShow,
    setOtherDrawer,
    details,
  }: {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setOtherDrawer: React.Dispatch<React.SetStateAction<boolean>>
    details: DynamicConfig | null
  }) => {
    const [detailsState, setDetailsState] = useState(details)
    useLayoutEffect(() => {
      setDetailsState(details)
    }, [details])
    const intl = useIntl()
    return (
      <>
        {/* {start drawer} */}
        <div
          id='kt_Drawer_Config'
          className={`bg-body drawer drawer-end overflow-visible ${
            show ? 'drawer-on' : ''
          } work_drawer bg-secondary card`}
          data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
          style={{zIndex: show ? 9 : 10, minWidth: 280, maxWidth: 280}}
        >
          <div className='node_item'>
            <div className='drawer-content h-100'>
              <div className='card h-100 border-0'>
                <div className='card-body p-4 h-100'>
                  {detailsState ? (
                    <>
                      <Button>{intl.formatMessage({id: 'Save'})}</Button>
                      <div className='input-group my-4 '>
                        <span className='input-group-text  border-end-0'>
                          <i className='fas fa-search text-gray-500'></i>
                        </span>
                        <input
                          type='text'
                          className='form-control border-start-0 ps-0'
                          placeholder={`${intl.formatMessage({id: 'Search'})}...`}
                        />
                      </div>
                      <div className='accordion custom-accordion' id='kt_accordion_3'>
                        <div className='config-details'>
                          <div className='accordion-item border mb-3 rounded-3'>
                            <h2 className='accordion-header' id={`kt_accordion_3_header_1`}>
                              {' '}
                              <button
                                className='accordion-button fs-5 fw-semibold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#kt_accordion_3_body_1`}
                                aria-expanded='false'
                                aria-controls={`kt_accordion__body_1`}
                              >
                                {intl.formatMessage({id: 'Enable config'})}
                              </button>
                            </h2>
                            <div
                              id={`kt_accordion_3_body_1`}
                              className='accordion-collapse collapse p-2 gap-2'
                              aria-labelledby={`kt_accordion_3_header_1`}
                              data-bs-parent='#kt_accordion_3'
                            >
                              {detailsState.map((item, index) =>
                                item.status ? (
                                  <div
                                    key={index}
                                    className='config-item mb-3 p-2 rounded bg-light d-flex flex-column gap-2 '
                                  >
                                    <strong className='text-gray-700'>{item.title}</strong>
                                    <div className='w-100 d-flex align-items-center justify-content-between'>
                                      <input
                                        type='text'
                                        className='form-control  m-1 p-1'
                                        defaultValue={item.value || ''}
                                      />
                                      <input
                                        id={`btn${index}`}
                                        className='check_box'
                                        type='checkbox'
                                        checked={item.status}
                                        onChange={(e) => {
                                          if (detailsState) {
                                            const copy = [...detailsState]
                                            copy[index].status = e.target.checked
                                            setDetailsState(copy)
                                          }
                                        }}
                                      />
                                      <label htmlFor={`btn${index}`}></label>
                                    </div>
                                  </div>
                                ) : null
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='accordion custom-accordion' id='kt_accordion_2'>
                        <div className='config-details'>
                          <div className='accordion-item border mb-3 rounded-3'>
                            <h2 className='accordion-header' id={`kt_accordion_2_header_1`}>
                              {' '}
                              <button
                                className='accordion-button fs-5 fw-semibold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#kt_accordion_2_body_1`}
                                aria-expanded='false'
                                aria-controls={`kt_accordion_2_body_1`}
                              >
                                {intl.formatMessage({id: 'All config'})}
                              </button>
                            </h2>
                            <div
                              id={`kt_accordion_2_body_1`}
                              className='accordion-collapse collapse p-2'
                              aria-labelledby={`kt_accordion_2_header_1`}
                              data-bs-parent='#kt_accordion_2'
                            >
                              {detailsState.map((item, index) => (
                                <div
                                  key={index}
                                  className='config-item mb-3 p-2 rounded bg-light d-flex gap-2 '
                                >
                                  <div
                                    data-tooltip-id={`${index}`}
                                    data-tooltip-content={item.discripton}
                                    style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                                  >
                                    <Warning2
                                      className=' image_change_theme2'
                                      svgClassName='info-icon'
                                    />
                                  </div>
                                  <ReactTooltip
                                    id={`${index}`}
                                    style={{
                                      borderRadius: 5,
                                      maxWidth: 200,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      zIndex: 10001,
                                    }}
                                  />

                                  <div className='w-100 d-flex align-items-center justify-content-between'>
                                    <strong className='text-gray-700'>{item.title}</strong>

                                    <input
                                      id={`btn${index}`}
                                      className='check_box'
                                      type='checkbox'
                                      checked={item.status}
                                      onChange={(e) => {
                                        if (detailsState) {
                                          const copy = [...detailsState]
                                          copy[index].status = e.target.checked
                                          setDetailsState(copy)
                                        }
                                      }}
                                    />
                                    <label htmlFor={`btn${index}`}></label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='text-center py-4 text-gray-600'>
                      <i className='fas fa-info-circle mb-3 fs-2'></i>
                      <p className='mb-0'>
                        {intl.formatMessage({
                          id: 'To display the configuration, first select the desired node.',
                        })}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className='work_drawer_button_config bg-secondary'
            style={{border: 'none'}}
            onClick={() => {
              setShow(!show)
              console.log('aaa', show)
              if (!show) {
                setOtherDrawer(false)
              }
            }}
          >
            <div className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle  '>
              <Tool className='svg-icon-primary svg-icon-2x' />
            </div>
          </button>
        </div>
        {/* {end drawer} */}
      </>
    )
  }
)
export default DrawerConfig
