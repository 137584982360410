import React, { useLayoutEffect, useState} from 'react'

import './animation.scss'
import Starts from '../images/Starts'
import Modal from './Modal'
import {useIntl} from 'react-intl'
import {Button} from 'react-bootstrap'
import Thunder from '../images/Thunder'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import usePermission from '../../../Utils/usePermission'
import Warning from '../images/Warning'
import PageLoading from '../loading/PageLoading'
import CodeEditor from './CodeEditor'


/**
 * AiTemplate - A component for AI-powered vulnerability scanning and content generation
 * 
 * @component
 * @description Provides an interface for scanning targets and generating AI-assisted content
 * 
 * @returns {React.ReactElement} Rendered AI template interface
 * 
 * @example
 * <AiTemplate />
 */
export default function AiTemplate () {
  // Hooks and state initialization
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const {isPermission, getPermission} = usePermission()
 const[loading, setLoading] = React.useState(false)
  // State variables for managing component state
  const [error, setError] = useState<string | null>(null)
  const [answer, setAnswer] = useState<string>('')
  const [status, setStatus] = useState<'pending' | 'accepted' | 'failed'>('accepted')
  let checkStatus: NodeJS.Timeout | undefined

  /**
   * Fetches the current status of an AI chat request
   * 
   * @param {string} id - Unique identifier for the chat request
   * @async
   * @description Checks the status of a pending AI request and updates component state
   */
  const fetchChatStatus = async (id: string) => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/nuclei/${id}/status/`)
      errorHandling(data.status) // Handle potential authentication errors
      if (data.status >= 200 && data.status < 300) {
        if (data.data === 'pending') {
          // Still processing
          return
        } else {
          clearInterval(checkStatus)
          if (data.data === 'accepted') {
            fetchChatList(id)
          } else {
            setStatus('failed')
          }
        }
      } else {
        clearInterval(checkStatus)
        console.error(
          `Error fetching status from ${process.env.REACT_APP_API_ENDPOINT}/nuclei/${id}/status/`
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Fetches the results of an AI chat request
   * 
   * @param {string} id - Unique identifier for the chat request
   * @async
   * @description Retrieves the generated content and updates component state
   */
  const fetchChatList = async (id: string) => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/nuclei/${id}/`)
      errorHandling(data.status) // Handle potential authentication errors
      getPermission(data.status)
      
      if (data.status >= 200 && data.status < 300) {
        setAnswer(data.data)
        setStatus('accepted')
      } else {
        clearInterval(checkStatus)
        console.error(
          `Error fetching data from ${process.env.REACT_APP_API_ENDPOINT}/nuclei/questions/`
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Sends a message to the AI service
   * 
   * @param {string} content - The content to be processed by the AI
   * @description Initiates an AI request and sets up status checking
   */
  const sendMessage = (content: string) => {
    setStatus('pending')
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/nuclei/`, {
        content: content,
      })
      .then((res) => {
        // Set up periodic status checking
        checkStatus = setInterval(() => {
          console.log('status')
          fetchChatStatus(res.data.id)
        }, 10000)
      })
      .catch((e) => {
        console.error(e)
        console.log('message error', e?.response?.data?.error)
      })
  }

  // Component render logic remains the same


  const test = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/nuclei/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {

      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/nuclei/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useLayoutEffect(()=>{
    test()
  },[])
  return (
    <div className={`position-relative w-100 h-100 ${loading&&'skeleton'}`}>
      {isPermission()}
      <div className='h-50px row'>
        <div className='col-10 form-group' dir='ltr'>
          <div className='input-group input-group-sm'>
            <div className='input-group-prepend'>
              <span className='rounded-end-0 input-group-text'>
                {intl.formatMessage({id: 'Target'})}
              </span>
            </div>
            <input type='text' className='form-control' placeholder='www.example.com' />
          </div>
        </div>
        <div className='col-2'>
          <Button
            size='sm'
            style={{height: 42}}
            className='d-flex align-items-center gap-1 w-100 btn btn-active-light-primary btn-icon'
            disabled={status === 'pending'|| !answer}
            onClick={()=>{
              setStatus('pending')
            }}
          >
            <Thunder /> {intl.formatMessage({id: 'Scan Now'})}
          </Button>
        </div>
      </div>
      <div
        className='position-relative bg-body w-100 overflow-hidden card'
        dir='ltr'
        style={{height: 'calc(100% - 50px) '}}
      >
        {!answer  && (
          <button
            className='d-flex gap-2 btn_animation'
            data-bs-toggle={'modal'}
            data-bs-target={`#kt_modal_AI`}
            disabled={status === 'pending'}
          >
            <Starts className='image_change_theme' />
            {status !== 'pending' && intl.formatMessage({id: 'Start with AI'})}
            {status === 'pending' && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Please wait'}) + '...'}

                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}

       <div className={`w-100 h-100 ${status === 'pending' && 'skeleton'}`}> <CodeEditor setError={setError} setAnswer={setAnswer} answer={answer}  /></div>

        {error && <div style={{color: 'red'}}>{error}</div>}
        {status === 'pending' && (
          <div className='position-absolute' style={{bottom: 70, left: 70}}>
            <PageLoading />
          </div>
        )}
        {status === 'failed' && (
          <div
            className='position-absolute px-5 h-50px'
            style={{bottom: 10, left: 30, zIndex: 1000}}
          >
            <Warning className='svg-icon-3x svg-icon-danger' />
          </div>
        )}
      </div>
      <Modal generate={sendMessage} />
    </div>
  )
}
