import React, { useContext } from 'react'
import { I18nContext } from '../_metronic/i18n/Metronici18n'

/**
 * Converts and displays a date based on the selected language and provided date formats.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.date - The date string to be converted and displayed.
 * @param {string} [props.jalali] - Optional. The date in Jalali (Persian) format.
 * @returns {JSX.Element} A div element containing the formatted date.
 */
export default function ConvertDate ({date , jalali ,className}: {date: string , jalali?:string,className?:string}) {
  const lang = useContext(I18nContext)?.lang.selectedLang
  function formatDateString (dateString: string): string {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${year}/${month}/${day} at ${hours}:${minutes}`
  }
  return (
    <div className={`Date ${className}`}>
      {
        lang === 'fa' && jalali ? <span>{jalali}</span> :
      <span>{formatDateString(date)}</span>
      }
    </div>
  )
}

