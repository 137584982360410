// SMTP.tsx
import React, {useState, useLayoutEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import axios from 'axios'
import {Card, Button, Alert, Spinner} from 'react-bootstrap'
import {RootState} from '../../../setup/redux/Store'
import {SetDataSmtp} from '../../modules/auth'
import {SMTP_DATA} from '../../modules/auth/redux/DEMO_DATA'
import {MailServerData} from '../../modules/types/smtp'
import useErrorHandling from '../../../Utils/useErrorHandling'
import usePermission from '../../../Utils/usePermission'
import './smtp.scss'
import {Section} from './components/Section'

export interface SectionData {
  title: string
  status?: 'pass' | 'fail'
  detail?: string
  data?: string[]
  description?: string
  [key: string]: any
}

const SMTP: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [activeSection, setActiveSection] = useState<number | null>(null)

  const errorHandling = useErrorHandling()
  const {isPermission, getPermission} = usePermission()
  const dispatch = useDispatch()
  const intl = useIntl()

  const smtpData = useSelector((state: RootState) => state?.root?.data?.dataSmtp)
  const user = useSelector((state: RootState) => state?.root?.user)
  /**
   * Fetches the SMTP ID list from the server.
   *
   * This function sends a GET request to the SMTP endpoint, handles the response,
   * updates permissions, and dispatches the fetched data to the Redux store.
   * It also manages the loading state and handles any errors that occur during the process.
   *
   * @async
   * @throws {Error} Logs any error that occurs during the fetch operation.
   */
  const fetchSmtpIdList = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/smtp/`)
      errorHandling(data.status)
      getPermission(data.status)

      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataSmtp(data.data))
      } else {
        console.error(`Error fetching SMTP data:`, data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Effect hook to fetch SMTP data when necessary.
   *
   * This hook checks if the current SMTP data has expired or doesn't exist,
   * and if so, it triggers a fetch operation to update the data.
   */
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (!smtpData?.expireTime || smtpData.expireTime < nowDate) {
      fetchSmtpIdList()
    }
  }, [])

  /**
   * Handles navigation to the previous SMTP entry.
   *
   * This function decrements the current index if it's not already at the beginning,
   * effectively moving to the previous SMTP server entry in the list.
   */
  const handlePreviousEntry = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  /**
   * Handles navigation to the next SMTP entry.
   *
   * This function increments the current index if it's not at the end of the list,
   * effectively moving to the next SMTP server entry. It also determines the correct
   * data source based on the availability of SMTP data and user scan status.
   */
  const handleNextEntry = () => {
    const dataSource =
      !smtpData?.data?.length && !user?.is_user_scan ? SMTP_DATA : smtpData?.data || []

    if (currentIndex < dataSource.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const dataSource =
    !smtpData?.data?.length && !user?.is_user_scan ? SMTP_DATA : smtpData?.data || []

  return (
    <div
      className={`container py-4 ${loading && 'skeleton'}`}
      style={!smtpData?.data?.length && !user?.is_user_scan ? {filter: 'blur(3px'} : {}}
    >
      {isPermission()}
      <>
        <Card className='mb-4 smtp-header'>
          <Card.Body className='d-flex justify-content-between align-items-center' dir={'ltr'}>
            <Button
              variant='light'
              onClick={handlePreviousEntry}
              disabled={currentIndex === 0}
              aria-label='Previous server'
            >
              <i className='fas fa-chevron-left'></i>
            </Button>

            <h2 className='mb-0 d-flex align-items-center'>
              <i className='fas fa-server me-2'></i>
              <span>
                {dataSource[currentIndex]?.data?.host || intl.formatMessage({id: 'SMTP Server'})}
              </span>
            </h2>

            <Button
              variant='light'
              onClick={handleNextEntry}
              disabled={currentIndex === dataSource.length - 1}
              aria-label='Next server'
            >
              <i className='fas fa-chevron-right'></i>
            </Button>
          </Card.Body>
        </Card>

        <div className='smtp-sections'>
          {!smtpData?.data?.length && (
            <Alert variant='warning'>
              <i className='fas fa-exclamation-circle me-2'></i>
              {intl.formatMessage({id: 'No SMTP data available. Please scan your servers first.'})}
            </Alert>
          )}

          {Object.entries(dataSource[currentIndex]?.data || {})
            .filter(([key]) => !['host', 'score', 'timestamp', 'smtp_address'].includes(key))
            .map(([key, section], index) => (
              <Section
                key={key}
                data={section as SectionData}
                isActive={activeSection === index}
                onToggle={() => setActiveSection(activeSection === index ? null : index)}
              />
            ))}
        </div>
      </>
    </div>
  )
}

export {SMTP}
