import React, {useState, useEffect, useLayoutEffect} from 'react'
import BarChartAsset from './BarChartAsset'
import axios from 'axios'
import {useSearchParams} from 'react-router-dom'
import useErrorHandling from '../../../../../Utils/useErrorHandling'
import {SSL_DETAILS_DATA} from '../../../../modules/auth/redux/DEMO_DATA'
import {DataSsl} from '../../../../modules/types/ssl'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../setup/redux/Store'
import usePermission from '../../../../../Utils/usePermission'

/**
 * Determines the color based on the SSL grade
 * @param {string} item - The SSL grade
 * @returns {string} The corresponding color code
 */
const getColor = (item: string) => {
  switch (item.toUpperCase()) {
    case 'A':
    case 'A+':
      return '#1fa055'
    case 'B':
      return '#255a8f'
    case 'C':
      return '#ffff4d'
    case 'D':
      return '#ff66a3'
    case 'F':
    case 'T':
    case 'M':
    case 'N':
      return '#FF0000'
    default:
      return ''
  }
}

/**
 * SSLDetails component displays detailed SSL information for a specific host
 */
const SSLDetails = () => {
  const [loading, setLoading] = useState(false)
  const [sslDetailData, setSslDetailData] = useState<DataSsl>()
  const [hostName, setHostName] = useState('')
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const id = searchParams.get('id')
  const intl = useIntl()
  const { isPermission, getPermission } = usePermission()
  const user = useSelector((state: RootState) => state?.root?.user)

  /**
   * Fetches SSL data from the API
   */
  const fetchSslData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ssl/${id}/`)
      errorHandling(data.status) // Logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        setHostName(data?.data?.host)
        setSslDetailData(data?.data?.data)
      } else {
        console.error(
          `Error in getting data from ${process.env.REACT_APP_API_ENDPOINT}/ssl/${id} \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // Fetch SSL data on component mount
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODE === 'demo') {
      setSslDetailData(SSL_DETAILS_DATA?.data)
      setHostName(SSL_DETAILS_DATA?.host)
    } else {
      fetchSslData()
    }
  }, [])

  return (
    <>
      <div className={`row d-flex flex-row mt-5 position-relative ${loading && 'skeleton'}`}>
        {isPermission()}
        <div className='w-100 h-100' style={!sslDetailData && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}>
          {/* Summary Report Card */}
          <div className='card card-body bg-gray-100 mb-7'>
            <div className='w-100 d-flex mb-1 pb-4 align-items-center pt-2 border-gray-400 border-3 border-bottom'>
              <div className='fs-1'>{`Summary Report for ${hostName}`}</div>
            </div>
            <div className='row mt-8 mb-8'>
              {/* Overall Rating */}
              <div className='col-5 d-flex flex-column justify-content-center align-items-center'>
                <div className='mb-3'>{intl.formatMessage({id: 'Overall Rating'})}</div>
                <div
                  className='w-150px h-150px d-flex justify-content-center align-items-center rounded-2 text-capitalize'
                  style={{
                    fontSize: '7em',
                    fontWeight: 'bold',
                    backgroundColor: getColor((sslDetailData || SSL_DETAILS_DATA.data)?.rating[3]?.finding || ''),
                  }}
                >
                  {(sslDetailData || SSL_DETAILS_DATA.data)?.rating[3]?.finding}
                </div>
              </div>
              {/* Bar Chart */}
              <div className='col-7'>
                <BarChartAsset data={(sslDetailData || SSL_DETAILS_DATA.data)?.rating} />
              </div>
            </div>

            {/* Info Items */}
            {(sslDetailData || SSL_DETAILS_DATA.data)?.info?.map((item, ind: number) => (
              <div
                key={ind}
                className={`w-100 d-flex justify-content-center fs-6 my-2 px-8 rounded-2 border-gray-400 border-2 p-3 border bg-body`}
              >
                {item.finding}
              </div>
            ))}
          </div>

          {/* Detailed Report Cards */}
          {(sslDetailData || SSL_DETAILS_DATA.data)?.report?.map(
            (item, index: number) => {
              const {title} = item
              const row = item.detail
              return (
                <div className={`card card-body bg-gray-100 mb-7 px-5`} key={index}>
                  <div className='w-100 d-flex my-2 pb-4 flex-row justify-content-between align-items-start pt-2 border-gray-400 border-3 border-bottom'>
                    <div className='fs-1'>{title}</div>
                  </div>
                  {row?.map((it, ind: number) => (
                    <div
                      key={ind}
                      className={`w-100 my-2 px-8 border-gray-400 border-1 pb-3 border-bottom `}
                    >
                      <div className='w-100 d-flex fs-6 fw-bold justify-content-between align-items-center'>
                        <div className='col-4 p-1 h-auto'> {it?.title} </div>
                        <div className='col-8 p-1 ps-2 ms-5 h-auto'> {it?.finding} </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            }
          )}
        </div>
      </div>
    </>
  )
}

export default SSLDetails

