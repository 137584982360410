import React, { useLayoutEffect } from 'react'
import WafPage from './components/WafPage'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import usePermission from '../../../Utils/usePermission'


export const Waf = () => {
  const errorHandling = useErrorHandling()
  const {getPermission,isPermission}=usePermission()
  const [loading, setLoading] = React.useState(false)
  const test = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/waf/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {

      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/plans/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    } finally{
      setLoading(false)
    }

  }
  useLayoutEffect(()=>{
    test()
  },[])
  return (
    <>
      <div className={`position-absolute d-flex flex-row mt-5 row ${loading&&'skeleton'}`}>
      {isPermission()}
        <WafPage />
      </div>
    </>
  )
}
