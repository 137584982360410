import React, {useState, useEffect} from 'react'
import axios from 'axios'

import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {SetDataScope} from '../../modules/auth'
import {useIntl} from 'react-intl'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import CreateTargetModal from './components/CreateTargetModal'
import {Badge, Button, Card, Table} from 'react-bootstrap'
import ConvertDate from '../../../Utils/ConvertDate'

export const Target = () => {
  const [loading, setLoading] = useState(false)
  const targetData = useSelector((state: RootState) => state?.root?.data?.dataScope)
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const [id, setId] = useState<null | string>(null)
  const [targetPage, setTargetPage] = useState<1 | 2>(1)
  const handlePageClick = (event: {selected: number}) => {
    fetchTargetData(event.selected + 1)
  }
  /**
   * Fetches target data from the API for a specific page.
   * @param {number} page - The page number to fetch (default: 1).
   * @returns {Promise<void>}
   */
  const fetchTargetData = async (page = 1) => {
    setLoading(true)
    try {
      // Make API request to fetch target data
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page}`)

      // Handle potential errors (e.g., invalid token, 404)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        // If the request was successful, update the Redux store
        dispatch(SetDataScope(data.data))
      } else {
        // Log an error if the request was unsuccessful
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page} \n`,
          data
        )
      }
    } catch (e) {
      // Log any unexpected errors
      console.log(e)
    } finally {
      // Ensure loading state is set to false, regardless of success or failure
      setLoading(false)
    }
  }

  /**
   * Effect hook to manage fetching of target data based on expiration time.
   *
   * This hook checks if the current target data has expired. If it has,
   * or if there's no expiration time set, it triggers a fetch for new data.
   *
   * @remarks
   * This effect runs only once when the component mounts, due to the empty dependency array.
   * The eslint-disable comment is used to suppress the exhaustive-deps warning,
   * as we intentionally want this effect to run only on mount.
   *
   * @example
   * ```tsx
   * useEffect(() => {
   *   // Effect body
   * }, [])
   * ```
   */
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (targetData?.expireTime && targetData.expireTime >= nowDate) {
    } else {
      fetchTargetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className={`shadow-sm ${loading && 'skeleton'}`}>
      <Card.Header className='border-0 bg-transparent'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <h4 className='mb-0'>{intl.formatMessage({id: 'Targets'})}</h4>
          <Button
            type='button'
            size='sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_target'
            disabled={loading}
          >
            {intl.formatMessage({id: 'Create New Target'})}
          </Button>
        </div>
      </Card.Header>

      <Card.Body className='p-0'>
        <div className='table-responsive'>
          <Table hover className='mb-0 align-middle'>
            <thead className='bg-light'>
              <tr>
                <th className='border-0 p-0' style={{minWidth: '250px'}}>
                  <div className='d-flex align-items-center p-3 cursor-pointer'>
                    {intl.formatMessage({id: 'Target'})}
                  </div>
                </th>
                <th className='border-0 text-center'>{intl.formatMessage({id: 'Type'})}</th>
                <th className='border-0 text-center'>
                  {/* <div className='d-flex align-items-center w-100 text-center cursor-pointer'> */}
                    {intl.formatMessage({id: 'Create Date'})}
                  {/* </div> */}
                </th>
                <th className='border-0 text-center'>{intl.formatMessage({id: 'Action'})}</th>
              </tr>
            </thead>
            <tbody>
              {targetData.data?.results?.map((item) => (
                <tr key={item.id} className='border-top'>
                  <td className='ps-3'>
                    <div className='fw-medium'>{item.target}</div>
                  </td>

                  <td className='text-center'>{item.type || '-'}</td>

                  <td className='d-flex justify-content-center text-center'>{<ConvertDate className='w-100 text-center' date={item.created_at} jalali={item.created_at_jalali} />}</td>
                  <td className='text-center'>
                    <Badge
                      bg={item.is_verified ? 'success' : 'warning'}
                      style={{
                        cursor: item.is_verified ? 'default' : 'pointer',
                      }}
                      data-bs-toggle={item.is_verified ? '' : 'modal'}
                      data-bs-target={item.is_verified ? '' : '#kt_modal_create_target'}
                      data-tooltip-id={'my-tooltip' + item.id}
                      data-tooltip-content={intl.formatMessage({
                        id: 'This target is not verified. Click to verify the target.',
                      })}
                      onClick={() => {
                        if (!item.is_verified) {
                          setTargetPage(2)
                          setId(item.id)
                        }
                      }}
                    >
                      {item.is_verified
                        ? intl.formatMessage({id: 'Verified'})
                        : intl.formatMessage({id: 'Verifi now'})}
                    </Badge>
                    {!item.is_verified && (
                      <ReactTooltip
                        id={'my-tooltip' + item.id}
                        style={{
                          maxWidth: 250,
                          fontSize: 12,
                          textAlign: 'center',
                          zIndex: 100,
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>

      <Card.Footer className='bg-transparent p-4'>
        <div className='d-flex justify-content-end align-items-center'>
          <ReactPaginate
          
            nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={targetData?.data?.total_pages || 1}
            forcePage={targetData?.data?.current_page ? targetData?.data?.current_page - 1 : 0}
            previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        </div>
        <CreateTargetModal
          get={fetchTargetData}
          id={id}
          setId={setId}
          setTargetPage={setTargetPage}
          targetPage={targetPage}
        />
      </Card.Footer>
    </Card>
  )
}
