import React from 'react'
import {useIntl} from 'react-intl'

export default function Footer ({list = []}: {list: any[]}) {
  const intl = useIntl()
  return (
    <>
      <hr />
      <div className='card_footer font_size'>
        {list.map((item, index) => (
          <div key={index} style={{width: '100%'}}>
            {item?.icon}
            <div>
              <span>
              {intl.formatMessage({id:item?.name||'' })}
                </span>
              <strong>{item?.value}</strong>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
