import ApexCharts from 'apexcharts'
import React, {memo, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'

/**
 * DonutChart component renders a donut chart using ApexCharts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number[]} [props.data=[43, 33, 1, 1, 25]] - An array of numbers representing the data for the donut chart.
 * 
 * @example
 * <DonutChart data={[30, 40, 20, 10]} />
 */
const DonutChart = memo(({data = [43, 33, 1, 1, 25]}: {data?: number[]}) => {
  const intl = useIntl()
  const lang = useLang()?.lang.selectedLang
  ///////////////////////////////////////////////////////
  const chartRef = useRef<HTMLDivElement | null>(null)
  
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, {
      series: data,
      labels: [
        `${intl.formatMessage({id: 'Grade'})}: A`,
        `${intl.formatMessage({id: 'Grade'})}: B`,
        `${intl.formatMessage({id: 'Grade'})}: C`,
        `${intl.formatMessage({id: 'Grade'})}: D`,
        `${intl.formatMessage({id: 'Grade'})}: F`,
      ],
      colors: ['#02694a', '#255a8f', '#9c8705', '#c02407', '#75041e'],
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        fontSize: '14px',
        labels: {
          colors: '#92929F',
        },
      },
      chart: {
        type: 'donut',
        height: "100%",
        width: "100%",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,

            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ['#ff003300'],
        width: 2,
        dashArray: 0,
      },
      plotOptions: {
        pie: {
          offsetY: 0,
          customScale: 1,
          donut: {
            size: '50%',
          },
        },
      },
    })
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data, lang])
  
  ////////////////////////////////////////////////////////
  return (
    <>
      <div
        ref={chartRef}
        id='kt_charts_widget_7_chart'
        style={{height: '100%',maxHeight:'100%'}}
      />
    </>
  )
})

export default DonutChart