import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import {Alert, Button} from 'react-bootstrap'
import Modal from './Modal'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import useErrorHandling from '../../../Utils/useErrorHandling'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {FilterSelectedTicket, SetDataTicket} from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import ConvertDate from '../../../Utils/ConvertDate'
import {useNavigate} from 'react-router-dom'
interface FilterOptions {
  status: {
    name: string
    count: number
  }[]
}
export default function Ticket () {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [loadingOption, setLoadingOption] = useState(false)
  const [filterOption, setFilterOption] = useState<FilterOptions>()
  const status = useSelector((state: RootState) => state.root.filterOption.ticket)
  const [isOpen, setIsOpen] = useState(false)
  const errorHandling = useErrorHandling()
  const ticketData = useSelector((state: RootState) => state?.root?.data?.dataTicket)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [createLoading, setCreateLoading] = useState(false)
  const handlePageClick = (event: {selected: number}) => {
    fetchTicketData(event.selected + 1)
  }
  /**
   * Fetches ticket data from the API based on the specified page and filter properties.
   * 
   * @param page - The page number to fetch. Defaults to 1.
   * @param filterProps - The filter properties to apply. Defaults to an empty string.
   * @returns {Promise<void>} - A promise that resolves when the data is fetched and processed.
   * 
   * @description
   * This function performs the following actions:
   * 1. Sets the loading state to true.
   * 2. Makes an API call to fetch ticket data.
   * 3. Handles any errors that occur during the API call.
   * 4. Dispatches the fetched data to the Redux store if successful.
   * 5. Logs errors if the API call fails.
   * 6. Sets the loading state back to false when finished.
   */
  const fetchTicketData = async (page = 1, filterProps = '') => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ticket/?page=${page}&status=${
          filterProps ? filterProps : status
        }`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataTicket(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ticket/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  /**
   * Fetches ticket filter options from the API.
   * 
   * @description
   * This function performs the following actions:
   * 1. Sets the loading state for options to true.
   * 2. Makes an API call to fetch ticket filter options.
   * 3. Handles any errors that occur during the API call.
   * 4. Updates the filter options state with the fetched data if successful.
   * 5. Logs errors if the API call fails.
   * 6. Sets the loading state for options back to false when finished.
   * 
   * @returns {Promise<void>} A promise that resolves when the filter options are fetched and processed.
   * 
   * @throws {Error} If there's an error during the API call or data processing.
   */
  const fetchTicketFilterOption = async (): Promise<void> => {
    setLoadingOption(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ticket/filter/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setFilterOption(data.data)
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ticket/filter/ \n`,
          data
        )
      }
      setLoadingOption(false)
    } catch (e) {
      console.log(e)
      setLoadingOption(false)
    }
  }

  /**
   * Filters tickets based on the provided status and fetches the corresponding data.
   * 
   * @param status - The status to filter tickets by. This should be a string representing
   *                 the desired ticket status (e.g., 'open', 'closed', 'pending').
   * 
   * @returns {void} This function doesn't return a value, but it triggers state updates
   *                 and data fetching as side effects.
   * 
   * @description
   * This function performs two main actions:
   * 1. Dispatches an action to update the selected ticket filter in the Redux store.
   * 2. Fetches ticket data for the first page with the newly selected status filter.
   */
  function filter(status: string) {
    dispatch(FilterSelectedTicket(status))
    fetchTicketData(1, status)
  }

  /**
   * Effect hook to initialize ticket data and filter options.
   * 
   * This effect runs once when the component mounts. It performs the following actions:
   * 1. Fetches ticket filter options.
   * 2. Checks if the existing ticket data is expired.
   * 3. If expired or not present, fetches new ticket data.
   * 
   * @remarks
   * This effect has dependencies that are intentionally omitted to prevent unnecessary re-runs.
   * The ESLint rule for exhaustive dependencies is disabled for this reason.
   * 
   * @returns {void}
   */
  useEffect(() => {
    fetchTicketFilterOption()
    const nowDate = new Date().getTime()
    if (ticketData?.expireTime && ticketData.expireTime >= nowDate) {
    } else {
      fetchTicketData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className='w-100 position-relative '>
        <div
          className=' d-flex flex-column align-items-center position-absolute top-10 start-auto right-auto w-100 '
          style={{zIndex: 100, top: -50}}
        >

        </div>
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <h1>{intl.formatMessage({id: 'Ticket'})}</h1>
          <Button
            size='sm'
            className='fs-6'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_ticket'
            disabled={createLoading}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {!createLoading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'New Ticket'})}</span>
            )}
            {createLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Please wait'}) + '...'}
                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        <div className='w-100 d-flex gap-10'>
          {/* /////////////////////////////////// */}
          <div className='w-25'>
            <div className={`card px-2 ${loadingOption && 'skeleton'}`}>
              <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary my-5'>
                {filterOption?.status.map((item, ind) => (
                  <div className='menu-item mb-3' key={ind}>
                    <span
                      className={`menu-link  ${status === item.name && 'active'}`}
                      onClick={() => filter(item.name)}
                    >
                      <span className='menu-icon'>
                        <KTIcon iconName='directbox-default' className='fs-1 me-3' />
                      </span>
                      <span className='menu-title fw-bold select'>
                        {intl.formatMessage({id: item.name})}
                      </span>
                      <span className='badge badge-light-success'>{item.count}</span>
                    </span>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
          {/* /////////////////////////////////// */}
          <div className={`w-75  ${loading && 'skeleton'}`}>
            <div className='card'>
              <div className='card-header align-items-center p-5 gap-2 gap-md-5'>
                <div className='d-flex flex-wrap gap-2 w-100'>
                  <div className='card-body p-0'>
                    <div
                      id='kt_inbox_listing_wrapper'
                      className='dt-container dt-bootstrap5 dt-empty-footer'
                    >
                      <div id='' className='table-responsive'>
                        <table
                          className='table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable'
                          id='kt_inbox_listing'
                        >
                          <thead className='d-none'>
                            <tr>
                              <th
                                data-dt-column='3'
                                className='dt-orderable-asc dt-orderable-desc'
                                aria-label='Title: Activate to sort'
                              >
                                <span className='dt-column-title' role='button'>
                                  Title
                                </span>
                                <span className='dt-column-order'></span>
                              </th>
                              <th
                                data-dt-column='4'
                                className='dt-orderable-asc dt-orderable-desc'
                                aria-label='Date: Activate to sort'
                              >
                                <span className='dt-column-title' role='button'>
                                  Date
                                </span>
                                <span className='dt-column-order'></span>
                              </th>
                            </tr>
                          </thead>

                          <tbody className='w-100'>
                            {ticketData?.data?.results?.map((item, ind: number) => (
                              <tr
                                className='w-100'
                                key={item.id}
                                onClick={() => {
                                  navigate({
                                    pathname: '/ticket-details',
                                    search: `?id=${item.id}`,
                                  })
                                }}
                              >
                                <td className='w-150px w-md-175px bg-with rounded-0 ps-3'>
                                  <a href='#' className='d-flex align-items-center text-gray-900'>
                                    <div className='symbol symbol-35px me-3'>
                                      <div className='symbol-label bg-light-danger'>
                                        <span className='text-danger text-uppercase'>
                                          {item.creator.trim()[0] || 'A'}
                                        </span>
                                      </div>
                                    </div>

                                    <span className='fw-semibold'>{item.title}</span>
                                  </a>
                                </td>

                                <td className='rounded-0'>
                                  <div className='text-gray-900 gap-1 pt-2'>
                                    <a href='#' className='text-gray-900'>
                                      <span className='fw-bold text_hide_width'>
                                        {item.description}
                                      </span>
                                    </a>
                                  </div>

                                  <div className='badge badge-light-primary mt-2 ms-1'>
                                    {intl.formatMessage({id: item.status || ' '})}
                                    {}
                                  </div>

                                  <div
                                    className={`badge mt-2 ms-1 ${
                                      item.priority === 'low'
                                        ? 'badge-light-success'
                                        : item.priority === 'medium'
                                        ? 'badge-light-warning'
                                        : 'badge-light-danger'
                                    }`}
                                  >
                                    {intl.formatMessage({id: item.priority || ' '})}
                                  </div>
                                </td>

                                <td className='w-100px text-end fs-7 pe-9 rounded-0'>
                                  <span className='fw-semibold'>
                                    <ConvertDate date={item.created_at} />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
                      <ReactPaginate
                        nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={ticketData?.data?.total_pages || 1}
                        forcePage={ticketData?.data?.current_page?ticketData?.data?.current_page - 1 : 0}
                        previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                        pageClassName='page-item'
                        pageLinkClassName='page-link'
                        previousClassName='page-item'
                        previousLinkClassName='page-link'
                        nextClassName='page-item'
                        nextLinkClassName='page-link'
                        breakLabel='...'
                        breakClassName='page-item'
                        breakLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        getData={fetchTicketData}
        setCreateLoading={setCreateLoading}
      />
    </>
  )
}
