import React, {memo} from 'react'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
interface Props {
  icon: JSX.Element
  header: number | null | undefined
  subHeader: string
  bgColor: string
  subColor: string
  chartColors: string[]
  data: number[] | undefined
  categories: string[]
  tooltip?: string
  loading: boolean
}
const Card = memo(
  ({
    icon = <i className='bi bi-bookmarks-fill fs-2x' />,
    header = 55,
    subHeader = 'SAP UI Progress',
    bgColor = 'bg-dark',
    subColor = ' ',
    chartColors = ['#96bded'],
    data = [10, 20, 30, 10],
    categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
    tooltip = 'Tooltip...',
    loading,
  }: Props) => {
    /**
     * Renders a card component displaying a header, subheader, and a chart.
     * 
     * @component
     * @param {JSX.Element} icon - The icon to display in the card.
     * @param {number|null|undefined} header - The main header value to display.
     * @param {string} subHeader - The subheader text to display.
     * @param {string} bgColor - The background color of the card.
     * @param {string} subColor - The color for the subheader text.
     * @param {string[]} chartColors - An array of colors for the chart.
     * @param {number[]|undefined} data - The data to be displayed in the chart.
     * @param {string[]} categories - The categories for the chart.
     * @param {string} [tooltip] - Optional tooltip text for additional information.
     * @param {boolean} loading - Indicates if the card is in a loading state.
     * 
     * @returns {JSX.Element} The rendered card component.
     */
    const intl = useIntl();
    const user = useSelector((state: RootState) => state.root.user);

    return (
      <div className='d-flex flex-column h-150px col-3'>
        <div className={`h-100px card card-body ${bgColor} ${loading && 'skeleton'}`}>
          <div className='row d-flex flex-row'>
            <div className='col-6'>
              {icon}
              <div
                className={`${subColor} fw-bolder fs-1 mt-6 mb-8`}
                style={!data.length && !user?.is_user_scan ? { filter: 'blur(3px)' } : {}}
              >
                {header}
              </div>
            </div>

            <div
              className='col-6 h-100 mt-1'
              style={!data.length && !user?.is_user_scan ? { filter: 'blur(3px)' } : {}}
            >
              <ColumnChart
                categories={categories}
                height={80}
                data={data.length ? data : !user?.is_user_scan ? [60, 40, 60, 30] : []}
                colors={chartColors}
              />
            </div>
          </div>
          <div className='d-flex fw-bold text-igray'>
            {intl.formatMessage({ id: subHeader })}
            <span
              data-tooltip-id={'my-tooltip' + subHeader}
              data-tooltip-content={intl.formatMessage({ id: tooltip })}
            >
              <InfoIcon />
            </span>
            <Tooltip
              id={'my-tooltip' + subHeader}
              style={{ maxWidth: 250, fontSize: 12, textAlign: 'center' }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default Card;
