import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import useErrorHandling from '../../../Utils/useErrorHandling'
import * as Yup from 'yup'
import {toast} from 'sonner'
export default function Modal ({
  getData,
  setCreateLoading,
}: {
  getData: (page?: number) => Promise<void>
  setCreateLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const intl = useIntl()
  const [base64, setBase64] = useState<string | ArrayBuffer | null>(null)
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  
  /**
   * Defines the validation schema for the ticket creation form.
   * 
   * @remarks
   * This schema uses Yup to define validation rules for the ticket form fields.
   * It ensures that the title, description, and priority fields meet specific criteria.
   * 
   * @param {Object} Yup - The Yup validation library object.
   * @param {Function} intl.formatMessage - Function to format internationalized messages.
   * 
   * @returns {Yup.ObjectSchema} A Yup schema object defining the validation rules.
   */
  const loginSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Title is required'})),
    description: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .required(intl.formatMessage({id: 'Description is required'})),
    priority: Yup.string().required(intl.formatMessage({id: 'priority is required'})),
  })

  /**
   * Initializes and configures the Formik form for ticket creation.
   * 
   * @remarks
   * This function sets up the form with initial values, validation schema, and submit handler.
   * It manages the form state, handles form submission, and performs API calls to create a new ticket.
   * 
   * @returns {FormikProps<{description: string, title: string, priority: string}>} The Formik form object
   */
  const formik = useFormik({
    initialValues: {description: '', title: '', priority: 'low'},
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true)
      setCreateLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/ticket/`, {
          ...values,
          attachment: base64 ? base64 : null,
        })
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            getData()
            formik.resetForm()
            toast.success(intl.formatMessage({id:'Ticket added successfully.'}))
            setBase64('')
          } else {
            toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          }
        })
        .catch((e) => {
          console.log('message \n', e?.response?.data?.error)
          toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          setLoading(false)
        })
        .finally(() => {
          setCreateLoading(false)
        })
    },
  })


  /**
   * Handles the file change event for image uploads.
   * 
   * This function is triggered when a file is selected in the input field.
   * It reads the selected file and converts it to a base64 string.
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - The file change event.
   * @returns {void}
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64String = reader.result
        setBase64(base64String)
      }
      reader.readAsDataURL(file)
    }
  }


  return (
    <>
      <div
        className={`modal fade`}
        id='kt_modal_ticket'
        style={{backdropFilter: 'blur(3px)'}}
        role='dialog'
        aria-bs-labelledby='staticBackdrop'
       
        data-bs-backdrop='static'
      >
        <div className='modal-dialog' style={{minWidth: '50%'}}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{intl.formatMessage({id: 'New Ticket'})}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  formik.resetForm()
                  setBase64('')
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Title'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'Title'})}
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                      name='title'
                      autoComplete='off'
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Description'})}
                    </label>
                    <textarea
                      placeholder={intl.formatMessage({id: 'Description'})}
                      {...formik.getFieldProps('description')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      name='description'
                      autoComplete='off'
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Priority'})}
                    </label>
                    <select
                      className={`form-select form-select-solid ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.priority && formik.errors.priority},
                        {
                          'is-valid': formik.touched.priority && !formik.errors.priority,
                        }
                      )}`}
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      {...formik.getFieldProps('priority')}
                      name='priority'
                    >
                      <option value='low'>{intl.formatMessage({id: 'Low'})}</option>
                      <option value='medium'>{intl.formatMessage({id: 'Medium'})}</option>
                      <option value='high'>{intl.formatMessage({id: 'High'})}</option>
                    </select>
                    {formik.touched.priority && formik.errors.priority && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.priority}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label' htmlFor='customFile'>
                      {intl.formatMessage({id: 'Image Attachment'})}
                    </label>
                    <input
                      onChange={handleFileChange}
                      type='file'
                      accept='image/png, image/jpeg'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      id='customFile'
                    />
                  </div>
                  {process.env.REACT_APP_MODE === 'demo' && (
                    <p className='text-danger'>
                      {' '}
                      {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                    </p>
                  )}
                  <button
                    // type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-success'
                    data-bs-dismiss='modal'
                    // aria-label='Close'
                    disabled={process.env.REACT_APP_MODE === 'demo' || loading}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'Send Ticket'})}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Please wait'}) + '...'}
                        <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
