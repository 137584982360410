import React, {useCallback, useState} from 'react'


import './WorkFlow.scss'
import {KTSVG} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import DrawerDags from './DrawerDags'
import {useIntl} from 'react-intl'
import DrawerConfig from './DrawerConfig'
import {useDispatch} from 'react-redux'
import {SetNodeConect} from '../../../modules/auth'
import FlowProvide from './Flow'

/**
 * Defines custom interfaces and initial values for the workflow component.
 *
 * @interface CustomEdge
 * Extends the Edge interface with an optional markerEnd property.
 * @property {string} [markerEnd] - Optional string to define the marker end of an edge.
 *
 * @interface DragHandlerProps
 * Defines the properties for draggable items in the workflow.
 * @property {string} id - Unique identifier for the draggable item.
 * @property {string} label - Display label for the draggable item.
 * @property {string} className - CSS class name for styling the draggable item.
 * @property {string} type - Type of the draggable item (e.g., 'input', 'output').
 *
 * @const initialValues
 * Initial values for the workflow form.
 * @property {string} name - Initial value for the workflow name.
 * @property {string} description - Initial value for the workflow description.
 *
 * @const edgeTypes
 * Defines custom edge types used in the workflow.
 * @property {CustomEdge} custom - Custom edge type implementation.
 *
 * @const nodeTypes
 * Defines custom node types used in the workflow.
 * @property {CustomNode} custom - Custom node type implementation.
 */

interface ConfigItem {
  value: string | null
  status: boolean
  title: string
  discripton: string // Note: keeping the typo as it's in the original data
  command: string
}
export type DynamicConfig = ConfigItem[]

export interface DragHandlerProps {
  id: string
  data: {
    details: string
    config: {
      [key: string]: string
    }
    label: string
  }
  type: string
}

const initialValues = {
  name: '',
  description: '',
}

const CreateDAG = () => {
  const dispatch = useDispatch()
  const [selectedNodeItem, setSelectedNodeItem] = useState<DragHandlerProps | undefined>()
  const [details, setDetails] = useState<null | DynamicConfig | any>(null)
  const [isOpenDagDrawer, setIsOpenDagDrawer] = useState<boolean>(false)
  const [isOpenConfigDrawer, setIsOpenConfigDrawer] = useState<boolean>(false)
  const navigate = useNavigate()
  const intl = useIntl()

  /**
   * Defines the validation schema for the login form using Yup.
   */
  const loginSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Name is required'})),
    description: Yup.string().required(intl.formatMessage({id: 'Description is required'})),
  })

  /**
   * Initializes the Formik form with initial values, validation schema, and submit handler.
   */
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  /**
   * Handles the drag event for workflow items.
   * @param {DragHandlerProps} item - The dragged item properties.
   * @returns {void}
   */
  function dragHandler (item: DragHandlerProps) {
    setSelectedNodeItem(item)
  }

  return (
    <>
      <DrawerDags
        dragHandler={dragHandler}
        show={isOpenDagDrawer}
        setShow={setIsOpenDagDrawer}
        setOtherDrawer={setIsOpenConfigDrawer}
      />
      <DrawerConfig
        show={isOpenConfigDrawer}
        setShow={setIsOpenConfigDrawer}
        setOtherDrawer={setIsOpenDagDrawer}
        details={details}
      />


      {/* {start workflow} */}
      <div
        style={{width: '100%', height: '100% ', direction: 'ltr'}}
        onMouseUp={() => {
          dispatch(SetNodeConect(null))
        }}
      >
        <FlowProvide
          setDetails={setDetails}
          setIsOpenConfigDrawer={setIsOpenConfigDrawer}
          setIsOpenDagDrawer={setIsOpenDagDrawer}
          selectedNodeItem={selectedNodeItem}
        />
      </div>

      <>
        <div className='fade modal' id='kt_modal_save' style={{backdropFilter: 'blur(3px)'}}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title'>{intl.formatMessage({id: 'Save'})}</h3>

                <div
                  className='btn btn-active-light-primary btn-icon btn-sm ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='image_change_theme2 svg-icon-2'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form
                  className='w-100 form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  <div className='mb-10 fv-row'>
                    <label className='form-label fw-bolder fs-6'>
                      {intl.formatMessage({id: 'Name'})}
                    </label>
                    <input
                      type='text'
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}`}
                      placeholder={intl.formatMessage({id: 'Name'})}
                      {...formik.getFieldProps('name')}
                      name='name'
                    />

                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className='mb-10 fv-row'>
                    <label className='form-label fw-bolder fs-6'>
                      {intl.formatMessage({id: 'Description'})}
                    </label>
                    <textarea
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}`}
                      placeholder={intl.formatMessage({id: 'Description'})}
                      {...formik.getFieldProps('description')}
                      name='description'
                    />

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    formik.resetForm()
                    navigate('/workFlow')
                  }}
                >
                  {intl.formatMessage({id: 'Save'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default CreateDAG
