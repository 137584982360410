import React, { useState, useLayoutEffect, lazy, Suspense } from 'react';
import axios from 'axios';

import Card from './components/Card';
import MainChart from './components/MainChart';
import RecentVulnerabilities from './components/RecentVulnerabilities';
import Assets from './components/Assets';
import WAFSecurity from './components/WAFSecurity';
import BarChartAsset from './components/BarChartAsset';
import FPlus from './components/FPlus';
import HalfCircleChart from './components/HalfCircleChart';
import IssueByStatus from './components/IssueByStatus';
// import Map from './components/Map'
import { StatisticsWidget3 } from '../../../_metronic/partials/widgets';
import sample from '../sample';
import ServerIcon from './imgs/ServerIcon';
import BugIcon from './imgs/BugIcon';
import PadLock from './imgs/PadLock';
import { useDispatch, useSelector } from 'react-redux';
import useErrorHandling from '../../../Utils/useErrorHandling';
import AssetsRisk from './components/AssetsRisk';
import { RootState } from '../../../setup/redux/Store';
import { SetDataDashboard } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import InfoIcon from '../images/InfoIcon';
import { useIntl } from 'react-intl';

/**
 * Dashboard1 component that renders the main dashboard view.
 * It fetches and displays various statistics and charts related to assets and vulnerabilities.
 * 
 * @returns {JSX.Element} The rendered dashboard component.
 */
export default function Dashboard1() {
  const Map = lazy(() => import('./components/Map')); // Lazy load the Map component
  const intl = useIntl(); // Internationalization hook
  const { heatMap } = sample; // Sample data for heat map
  const [loading, setLoading] = useState(false); // Loading state for data fetching
  const errorHandling = useErrorHandling(); // Custom hook for error handling
  const dashboardData = useSelector((state: RootState) => state.root.data.dataDashboard); // Dashboard data from Redux store
  const dispatch = useDispatch(); // Redux dispatch function
  const user = useSelector((state: RootState) => state.root.user); // User data from Redux store
  const navigate = useNavigate(); // Hook for navigation

  /**
   * Fetches dashboard data from the API.
   * Handles loading state and error handling.
   */
  const fetchDashboardData = async () => {
    setLoading(true); // Set loading state to true
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/`, {
        withCredentials: true,
      });
      errorHandling(data.status); // Handle errors based on response status
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataDashboard(data.data)); // Dispatch action to set dashboard data in Redux store
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dashboard/ \n`,
          data
        );
      }
      setLoading(false); // Set loading state to false
    } catch (e) {
      console.log(e); // Log any errors
    } finally {
      setLoading(false); // Ensure loading state is false in case of error
    }
  };

  useLayoutEffect(() => {
    // Check if the user is new and navigate accordingly
    if (user?.is_user_new) {
      navigate('/services'); // Redirect new users to services page
    } else {
      const nowDate = new Date().getTime(); // Get current timestamp
      // Check if dashboard data is expired or not available
      if (dashboardData?.expireTime && dashboardData.expireTime >= nowDate && dashboardData?.data) {
        // Data is valid, do nothing
      } else {
        fetchDashboardData(); // Fetch new dashboard data
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    card1,
    card2,
    card3,
    card4,
    vulnerAbilities,
    zeroCard,
    earning,
    asset1,
    asset2,
    barChartAsset,
  } = sample; // Sample data for various components
  return (
    <>
      <div className='mx-auto' style={{maxWidth: 1320}}>
        <div className='d-flex flex-row mb-8 g-8 row'>
          <FPlus loading={loading}>
            <HalfCircleChart title={card1.total} percent={dashboardData?.data?.total_score} />
          </FPlus>

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={'Total Assets'}
            header={dashboardData.data?.total_assets?.assets}
            icon={<ServerIcon />}
            chartColors={card2.chartColors}
            data={dashboardData.data?.total_assets?.assets_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Total Assets tooltip'
          />

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={card3.subHeader}
            header={dashboardData?.data?.total_vulnerabilities?.vulnerabilities}
            icon={<BugIcon />}
            chartColors={card3.chartColors}
            data={dashboardData?.data?.total_vulnerabilities?.vulnerabilities_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Total Vulnerabilities tooltip'
          />

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={card4.subHeader}
            header={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities}
            icon={<PadLock />}
            chartColors={card4.chartColors}
            data={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Severe Vulnerabilities tooltip'
          />
        </div>
        <div className='d-flex flex-row mb-8 g-8 row'>
          <MainChart
            loading={loading}
            title={vulnerAbilities?.title}
            data={dashboardData?.data?.vulnerabilities_based_severity}
          />
          <RecentVulnerabilities
            loading={loading}
            recentIssues={dashboardData?.data?.recent_vulnerabilities}
          />
        </div>
        <div className='d-flex flex-row mb-8 g-8 row'>
          <AssetsRisk loading={loading} newTechnologies={dashboardData?.data?.assets_at_risk} />
          <div className='position-relative col-4' style={{height: 270}}>
            <div style={{position: 'absolute', left: 70, top: -11, zIndex: 2}}>
              <BugIcon width={30} height={30} color='#e74c3c' />
            </div>
            <StatisticsWidget3
              loading={loading}
              className='mb-xl-8 h-100 card card-body card-xl-stretch'
              color='danger'
              title={zeroCard?.title}
              description={zeroCard?.desc}
              change={zeroCard?.value}
              // data={zeroCard.data}
            />
          </div>
          <IssueByStatus loading={loading} data={dashboardData?.data?.assets_by_grade} />
        </div>
        <div className='d-flex flex-row mb-8 g-8 row'>
          <div className='h-500px col-6'>
            <div className='d-flex flex-row h-100 gx-8 row'>
              <div className='mb-8 col-12' 
              style={{height: 235}}
              >
                {/* <Assets /> */}
                <BarChartAsset loading={loading} data={barChartAsset} />
                {/* <WAFSecurity loading={loading} data={earning?.data} /> */}
              </div>

              {/* <div className='mb-8 col-6' 
              style={{height: 235}}
              >
          
              </div> */}

              <div className='col-6' 
              style={{height: 235}}
              >
                <Assets
                  loading={loading}
                  data={{...asset2, count: dashboardData?.data?.dns_score}}
                  tooltip='EXPIRING SSL CERTIFICATES tooltip'
                />
              </div>

              <div className='col-6' 
              style={{height: 235}}
              >
                      <Assets
                  loading={loading}
                  data={{...asset1, count: dashboardData?.data?.ssl_exp}}
                  tooltip='EXPIRING SSL CERTIFICATES tooltip'
                />
              </div>
            </div>
          </div>
          <div className='h-500px col-6'>
            <div className={`card card-body h-100 bg-body p-0 `}>
              <div
                className={`card card-body ${loading && 'skeleton'}`}
                style={{width: '100%', height: '100%'}}
              >
                <div className='d-flex flex-column me-2'>
                  <p className='d-flex fw-bolder fs-3'>
                    {intl.formatMessage({id: heatMap.title})}
                    <span
                      data-tooltip-id='my-tooltip-map'
                      data-tooltip-content={intl.formatMessage({id: 'Hosts By Country tooltip'})}
                    >
                      <InfoIcon />
                    </span>
                    <Tooltip
                      id='my-tooltip-map'
                      style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                    />
                  </p>
                </div>
                <Suspense fallback={<></>}>
                  <Map countries={dashboardData?.data?.host_by_country} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
