import React, {useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {Button} from 'react-bootstrap'
import {Modal} from 'bootstrap'
import {toast} from 'sonner'
import {TargetList, ScanType} from '../../../modules/types/scan'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const DomainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
const initialValues = {
  title: '',
  description: '',
  type: '',
}
interface Props {
  get: () => Promise<void>
  id: string | undefined
  targetPage: 1 | 2
  setTargetPage: React.Dispatch<React.SetStateAction<1 | 2>>
  setId: React.Dispatch<React.SetStateAction<TargetList | null>>
  // typeOptions: ScanType[] | undefined
  needVerify: boolean
  workflowId: string | null
}
export default function CreateTargetModal ({
  get,
  id,
  setId,
  targetPage,
  setTargetPage,
  needVerify,
  workflowId,
}: Props) {
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [innerId, setInnerId] = useState('')
  const [target, setTarget] = useState('')
  const [description, setDescription] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [type, setType] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [errShow, setErrShow] = useState('')
  const [typeOptions, setTypeOptions] = useState<any | null>(null)

  const fetchTypeOptions = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/workflow/${workflowId}/available-types/`
      )
      if (response.status >= 200 && response.status < 300) {
        setTypeOptions(response.data)
      } else {
        toast.error(intl.formatMessage({id: 'Failed to fetch target types'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'Failed to fetch target types'}))
    } finally {
      setLoading(false)
    }
  }
  /**
   * Finds and returns a scan type object from the typeOptions array based on the given type ID.
   *
   * @param type - The ID of the scan type to find.
   * @returns The matching ScanType object if found, or undefined if not found.
   */
  function findType (type: string) {
    return typeOptions?.find((item: any) => item.id === type)
  }
  /**
   * Verifies the target using DNS TXT record.
   *
   * This function sends a GET request to verify the DNS TXT record for the target.
   * It handles the response, shows appropriate success or error messages, and
   * updates the component state based on the verification result.
   *
   * @async
   * @function verifyTXT
   * @throws {Error} If there's an error during the API call or verification process.
   * @returns {Promise<void>} A promise that resolves when the verification process is complete.
   */
  const verifyTXT = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/dns-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/dns-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Verifies the target using file verification method.
   *
   * This function sends a GET request to verify the target using a file-based method.
   * It handles the response, shows appropriate success or error messages, and
   * updates the component state based on the verification result.
   *
   * @async
   * @function verifyFile
   * @throws {Error} If there's an error during the API call or verification process.
   * @returns {Promise<void>} A promise that resolves when the verification process is complete.
   */
  const verifyFile = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/file-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/file-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Creates a new target by sending a POST request to the server.
   *
   * This function handles the creation of a new target, including form submission,
   * error handling, and state updates based on the server response.
   *
   * @async
   * @function createTarget
   * @throws {Error} If there's an error during the API call or target creation process.
   * @returns {Promise<void>} A promise that resolves when the target creation process is complete.
   */
  const createTarget = async () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/targets/`, {
        title: target,
        description: description.trim() ? description : null,
        type: type,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          toast.success('Create completed successfully.')
          setType('')
          formik.resetForm()
          if (needVerify) {
            setIsAction(true)
            setTargetPage(2)
            setInnerId(res.data.id)
            setVerifyCode(res.data.verification_code)
          } else {
            closeModal()
            setTarget('')
            setDescription('')
            setId(null)
            setTargetPage(1)
            setInnerId('')
            get()
          }
          setLoading(false)
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data?.error)
        toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /**
   * Fetches the verification code for a target from the server.
   *
   * This function sends a GET request to retrieve the verification code for a specific target.
   * It updates the component state based on the server response, including setting the
   * verification code and changing the target page if successful.
   *
   * @param id - The unique identifier of the target for which to fetch the verification code.
   * @returns A Promise that resolves when the fetch operation is complete.
   * @throws Will throw an error if the API request fails.
   */
  const fetchVerifyCode = async (id: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setVerifyCode(data.data.verification_code)
        setTargetPage(2)
      } else {
        setErrShow(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setErrShow(intl.formatMessage({id: 'Sorry, an error has occurred'}))
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Defines the validation schema for the login form using Yup.
   *
   * @remarks
   * This schema validates the 'title' and 'type' fields of the form.
   *
   * @param intl - The internationalization object for formatting messages
   * @param findType - A function that finds and returns a scan type object based on the given type ID
   * @param type - The current selected type value
   *
   * @returns A Yup schema object that can be used for form validation
   */
  const loginSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Target is required'}))
      .matches(
        new RegExp(findType(type)?.regex ? (findType(type)?.regex as string) : '^.{1,50}$'),
        intl.formatMessage({id: `${findType(type)?.name || 'Target'} is not valid`})
      ),
    type: Yup.string().required(intl.formatMessage({id: 'Type is required'})),
  })

  /**
   * Initializes and configures the Formik form handling for the target creation.
   *
   * @remarks
   * This hook sets up form validation, initial values, and submission handling for the target creation form.
   *
   * @param initialValues - The initial values for the form fields.
   * @param loginSchema - The Yup validation schema for form field validation.
   * @param createTarget - The function to be called when the form is submitted successfully.
   *
   * @returns A Formik form handler object with methods and properties for form management.
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      createTarget()
    },
  })

  /**
   * Closes the modal for creating a target and resets the action state.
   *
   * This function performs the following actions:
   * 1. Finds the modal element in the DOM.
   * 2. If the modal element exists, it initializes or retrieves the modal instance.
   * 3. Hides the modal using the Bootstrap Modal API.
   * 4. Resets the action state to false.
   *
   * @returns {void}
   */
  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_create_target')
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement) // Initialize or get the modal instance
      modalInstance.hide() // Close the modal
    }
    setIsAction(false)
  }

  /**
   * A useLayoutEffect hook that fetches the verification code when the component mounts or updates.
   *
   * This effect runs when either the 'id' or 'targetPage' dependencies change. It calls the
   * fetchVerifyCode function if there's an 'id' and the 'targetPage' is 2.
   *
   * @param {() => void} effect - The effect function to run.
   * @param {Array} dependencies - An array of dependencies for the effect.
   *   - id: The ID of the target.
   *   - targetPage: The current page of the target creation/verification process.
   *
   * @returns {void}
   */
  useLayoutEffect(() => {
    if (id && targetPage === 2) {
      fetchVerifyCode(id)
    }
    if (workflowId) {
      fetchTypeOptions()
    }
  }, [id, targetPage, workflowId])

  return (
    <div
      className={`modal fade pb-0`}
      id='kt_modal_create_target'
      style={{backdropFilter: 'blur(3px)'}}
      role='dialog'
      aria-bs-labelledby='staticBackdrop'
      data-bs-backdrop='static'
    >
      <div className={`modal-dialog ${loading && 'skeleton'}`} style={{minWidth: '50%'}}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title'>
              {' '}
              {targetPage === 1
                ? intl.formatMessage({id: 'Create New Target'})
                : intl.formatMessage({id: 'Verify Target'})}
            </h3>
            <div
              className='btn btn-active-light-primary btn-icon btn-sm ms-2'
              onClick={() => {
                setTarget('')
                setDescription('')
                setId(null)
                setTargetPage(1)
                setInnerId('')
                setType('')
                formik.resetForm()
                if (isAction) {
                  setIsAction(false)
                  get()
                }
                closeModal()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='image_change_theme2 svg-icon-2'
              />
            </div>
          </div>
          <div className='modal-body'>
            {/* create target*/}
            {targetPage === 2 && (
              <Tabs defaultActiveKey='DNS' id='uncontrolled-tab-example' className='mb-3'>
                <Tab eventKey='DNS' title='DNS Verification'>
                  <div>
                    <div className='bg-gray-400 p-4 w-100 card'>
                      <h1>DNS Verification Instructions</h1>
                      <p>
                        To proceed, please verify your ownership of the domain by adding a{' '}
                        <strong>TXT record</strong> to your domain's DNS settings.
                      </p>
                      <p>
                        Please deploy a DNS TXT record under the name:
                        <br />
                        <pre>
                          <code>
                            {' '}
                            _acme-challenge.app2-api.netbaan.net
                            (https://_acme-challenge.app2-api.netbaan.net/)
                          </code>
                          .
                        </pre>
                      </p>
                      <p>
                        with the following value:
                        <br />
                        <pre>
                          <code>rLJNdl5xPfKE7tvfn85XUX_NtjmpoSxfn1ssvkmDa3M</code>
                        </pre>
                      </p>
                      <p>
                        This is a standard process to ensure your domain is secure and linked to
                        your account.
                      </p>
                      <p>
                        After adding the TXT record, allow some time for DNS propagation, then
                        continue with the verification process.
                      </p>
                    </div>
                    <div className='d-flex gap-5 mt-5'>
                      <Button
                        onClick={() => {
                          verifyTXT()
                        }}
                      >
                        {intl.formatMessage({id: 'Verify with DNS'})}
                      </Button>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='File' title='File Verification'>
                  <div>
                    <div className='bg-gray-400 p-4 w-100 card'>
                      <h1>File Verification Instructions</h1>
                      <p>To verify using the file method, follow these steps:</p>
                      <ol>
                        <li style={{marginBottom: 13}}>
                          <span>
                            Create a file named <code>netbaan.txt</code>.
                          </span>
                        </li>
                        <li>
                          <span>
                            Add the provided code to the file:
                            <pre
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                navigator.clipboard.writeText(verifyCode)
                              }}
                            >
                              <code> {verifyCode}</code>
                            </pre>
                          </span>
                        </li>
                        <li>Upload the file to the following directory on your website:</li>
                        <pre>
                          <code>example.com/.well-known/netbaan.txt</code>
                        </pre>
                        <li>
                          Once uploaded, ensure that the file is accessible by visiting this URL in
                          your browser:
                        </li>
                        <pre>
                          <code>https://example.com/.well-known/netbaan.txt</code>
                        </pre>
                      </ol>
                    </div>
                    <div className='d-flex gap-5 mt-5'>
                      <Button
                        onClick={() => {
                          verifyFile()
                        }}
                      >
                        {intl.formatMessage({id: 'Verify with File'})}
                      </Button>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            )}
            {/* verify target*/}
            {targetPage === 1 && (
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className=''>
                  <div className='mb-10 fv-row'>
                    <label className='form-label fw-bolder fs-6'>
                      {intl.formatMessage({id: 'Type'})}
                    </label>
                    <select
                      className={clsx(
                        'form-control form-control-lg form-control-solid form-select form-select-solid',
                        {
                          'is-invalid': formik.touched.type && formik.errors.type,
                        },
                        {
                          'is-valid': formik.touched.type && !formik.errors.type,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      name='type'
                      onChange={(e) => {
                        if (!e.target.value) {
                          setTarget('')
                          formik.setFieldValue('title', '')
                        }
                        setType(e.target.value)
                        formik.setFieldValue('type', e.target.value)
                      }}
                      value={type}
                    >
                      <option></option>
                      {typeOptions?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='mt-2 text-danger fv-plugins-message-container'>
                        <span role='alert'>{intl.formatMessage({id: 'Type is required'})}</span>
                      </div>
                    )}
                  </div>
                  <div className='mb-10 fv-row'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <label className='form-label fw-bolder fs-6'>
                        {intl.formatMessage({id: 'Target'})}{' '}
                      </label>
                    </div>

                    <input
                      placeholder={findType(type)?.example || ''}
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                      type='text'
                      name='title'
                      autoComplete='off'
                      value={target}
                      disabled={!type}
                      onChange={(e) => {
                        setTarget(e.target.value)
                        formik.setFieldValue('title', e.target.value)
                      }}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='mt-2 text-danger fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    )}
                  </div>
                  <div className='mb-10 fv-row'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <label className='form-label fw-bolder fs-6'>
                        {intl.formatMessage({id: 'Description'})}{' '}
                      </label>
                    </div>

                    <textarea
                      {...formik.getFieldProps('description')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      name='description'
                      autoComplete='off'
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        formik.setFieldValue('description', e.target.value)
                      }}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='mt-2 text-danger fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-success'
                    // onClick={() => {
                    //   createTarget()
                    // }}

                    // disabled={!target.match(findType(type)?.regex)}
                  >
                    {intl.formatMessage({id: 'Create'})}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
