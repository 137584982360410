import {ApexOptions} from 'apexcharts'
import {memo} from 'react'
import Chart from 'react-apexcharts'

/**
 * Props for the ColumnChart component
 */
interface Props {
  height?: number
  colors?: string[]
  data: number[]
  categories?: string[]
}

/**
 * ColumnChart component that renders a column chart using ApexCharts
 * @param {Object} props - Component props
 * @param {number} [props.height=80] - Height of the chart
 * @param {string[]} [props.colors=['#96bded']] - Colors for the chart columns
 * @param {number[]} [props.data=[10, 20, 30, 15, 18, 5]] - Data points for the chart
 * @param {string[]} [props.categories=['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']] - Categories for x-axis
 * @returns {JSX.Element} Rendered ColumnChart component
 */
const ColumnChart = memo(
  ({
    height = 80,
    colors = ['#96bded'],
    data = [10, 20, 30, 15, 18, 5],
    categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
  }: Props) => {
    // Define the series data for the chart
    const series = [
      {
        name: 'Number',
        data: data,
      },
    ]

    // Configure the chart options
    const options: ApexOptions = {
      colors: colors,
      chart: {
        height: '100%',
        width:'100%',
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '4px',
          borderRadius: 2,
        },
      },
      markers: {
        size: 2,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        // categories: categories, // Uncomment if you want to use categories
      },
      yaxis: {
        show: false,
      },
  
    }

    // Render the ApexCharts component
    return <div style={{width:'calc(100% - 60px)'}}><Chart  width={'100%'}  height={height} options={options} series={series} type='bar'/></div>
  }
)

export default ColumnChart

