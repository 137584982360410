import React, {useLayoutEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch} from 'react-redux'
import {SetDataPaymentHistory, SetDataPricePlans} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import HistoryDetails from './HistoryDetails'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
const statusColors = {
  completed: 'success',
  pending: 'warning',
  failed: 'danger',
}
import ReactPaginate from 'react-paginate'
/**
 * History Component for displaying payment transaction history
 *
 * @component
 * @description Renders a table of payment transactions with search and filter capabilities
 * @returns {React.ReactElement} Rendered History component
 */
export default function History () {
  // Error handling hook for managing API errors
  const errorHandling = useErrorHandling()

  // Internationalization hook for multi-language support
  const intl = useIntl()

  // Current language direction (LTR/RTL)
  const direction = useLang()?.lang.dir

  // Redux dispatch for state management
  const dispatch = useDispatch()

  // Selector to retrieve payment history from Redux store
  const paymentHistory = useSelector((state: RootState) => state.root.data.dataPaymentHistory)

  // State to manage loading status of data fetching
  const [loading, setLoading] = useState(false)

  // State to manage selected transaction ID for details modal
  const [id, setId] = useState<string | number>('')

  /**
   * Fetches payment history from the API
   *
   * @async
   * @function fetchPaymentHistory
   * @description Retrieves payment transactions and updates Redux store
   * @throws {Error} Handles potential API request errors
   */
  const fetchPaymentHistory = async (page = 1) => {
    // Set loading state to true before API call
    setLoading(true)
    try {
      // Fetch payment history from API endpoint
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/payments/?page=${page}`)

      // Handle potential token invalidation or error responses
      errorHandling(data.status)

      // Check for successful response
      if (data.status >= 200 && data.status < 300) {
        // Dispatch fetched data to Redux store
        dispatch(SetDataPaymentHistory(data.data))
        console.log(data.data)
      } else {
        // Log error for unsuccessful requests
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/payments/ \n`,
          data
        )
        setLoading(false)
      }
    } catch (e) {
      // Log and handle any errors during API call
      console.log(e)
      setLoading(false)
    } finally {
      // Ensure loading state is set to false
      setLoading(false)
    }
  }

  /**
   * Lifecycle hook to fetch payment history on component mount
   * @remarks Commented out logic for potential future cache mechanism
   */
  useLayoutEffect(() => {
    // Potential future cache implementation
    // const nowDate = new Date().getTime()
    // if (paymentHistory?.expireTime && paymentHistory.expireTime >= nowDate) {
    // } else {
    // }
    fetchPaymentHistory()
  }, [])
  const handlePageClick = (event: {selected: number}) => {
    fetchPaymentHistory(event.selected + 1)
  }
  return (
    <div className={`p-5 ${loading && 'skeleton'}`}>
      <div className='card'>
        {/* Header with filters */}
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <h3 className='fw-bold'>{intl.formatMessage({id: 'Payment history'})}</h3>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center gap-4'>
              {/* Search */}
              <div className='position-relative'>
                <i className='fas fa-search position-absolute top-50 translate-middle-y text-gray-500 ms-4'></i>
                <input
                  type='text'
                  className='form-control form-control-solid ps-12'
                  placeholder={intl.formatMessage({id: 'Search'})}
                  style={{width: '250px'}}
                />
              </div>
              {/* Status Filter */}
              <select className='form-select form-select-solid' style={{width: '200px'}}>
                <option value=''>{intl.formatMessage({id: 'All'})}</option>
                <option value='completed'>{intl.formatMessage({id: 'completed'})}</option>
                <option value='pending'>{intl.formatMessage({id: 'pending'})}</option>
                <option value='failed'>{intl.formatMessage({id: 'failed'})}</option>
              </select>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className='card-body pt-2'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-bordered fs-6 gy-4'>
              <thead>
                <tr className='fw-bold text-gray-800 bg-light'>
                  <th className='min-w-150px ps-4'>{intl.formatMessage({id: 'ID'})}</th>
                  <th className='min-w-150px text-end'>
                    {intl.formatMessage({id: 'Amount'})} (تومان)
                  </th>
                  <th className='min-w-150px text-end'>
                    {intl.formatMessage({id: 'Discount'})} (تومان)
                  </th>
                  <th className='min-w-150px text-end'>
                    {intl.formatMessage({id: 'Final amount'})} (تومان)
                  </th>
                  <th className='min-w-100px text-center'>{intl.formatMessage({id: 'Status'})}</th>
                  <th className='min-w-100px text-end pe-4'>
                    {intl.formatMessage({id: 'Details'})}
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-700'>
                {paymentHistory?.data?.results.map((item) => (
                  <tr key={item.id}>
                    <td className='ps-4'>
                      <span className='text-gray-600 fw-bold'>#{item.id}</span>
                    </td>
                    <td className='text-end'>{FormatAndConvertAmount(item.amount || 0)}</td>
                    <td className='text-end'>
                      {FormatAndConvertAmount(item.discount_amount || 0)}
                    </td>
                    <td className='text-end'>{FormatAndConvertAmount(item.final_amount || 0)}</td>
                    <td className='text-center'>
                      <div
                        className={`badge badge-light-${statusColors[item.payment_status]} fw-bold`}
                      >
                        {intl.formatMessage({id: item.payment_status})}
                      </div>
                    </td>
                    <td className='text-end pe-4'>
                      <button
                      onClick={() => setId(item.id)}
                        className='btn btn-sm btn-light btn-active-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_invoice'
                      >
                        {intl.formatMessage({id: 'View invoice'})}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-end pt-2'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={paymentHistory?.data?.total_pages || 1}
              forcePage={(paymentHistory?.data?.current_page || 1) - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
      <HistoryDetails id={id} />
    </div>
    // </div>
    // </div>
  )
}
