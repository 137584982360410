import React, { memo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'sonner';
import axios from 'axios';
import useErrorHandling from '../../../../Utils/useErrorHandling';
import PageLoading from '../../loading/PageLoading';
import { useIntl } from 'react-intl';

/**
 * Interface representing the structure of the form values.
 */
interface Value {
  id?: number;
  target: string | null;
  workflow: number | null;
  description: string | null;
  status: string | null;
  start_time: string | null;
  rate: string | null;
}

/**
 * Initial values for the form.
 */
const initialValues: Value = {
  id: 0,
  target: '',
  workflow: 0,
  description: '',
  status: '',
  start_time: '',
  rate: '',
};

/**
 * Props for the EditModal component.
 */
interface Props {
  id: null | number | string; // ID of the item to edit
  setId: React.Dispatch<React.SetStateAction<string | number | null>>; // Function to set the ID
  getData: (page?: number) => Promise<void>; // Function to fetch data
}

/**
 * EditModal component for editing scan details.
 * 
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const EditModal = memo(({ id, setId, getData }: Props) => {
  const [loading, setLoading] = useState(false); // Loading state
  const errorHandling = useErrorHandling(); // Custom error handling hook
  const intl = useIntl(); // Internationalization hook

  /**
   * Clears the form and resets the ID.
   */
  function clearForm() {
    formik.resetForm(); // Reset the form values
    setId(null); // Clear the ID
  }

  // Formik setup for handling form state and submission
  const formik = useFormik({
    initialValues,

    /**
     * Handles form submission.
     * 
     * @param {Value} values - The form values.
     */
    onSubmit: (values) => {
      delete values.id; // Remove ID from values before sending to API
      axios
        .patch(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`, values)
        .then((res) => {
          // Check if the response status indicates success
          if (res.status >= 200 && res.status < 300) {
            toast.success(intl.formatMessage({ id: 'Scan editing completed successfully.' }));
            getData(); // Fetch updated data
          } else {
            toast.error(res?.data?.detail || intl.formatMessage({ id: 'Sorry, an error has occurred' }));
          }
          clearForm(); // Clear the form after submission
        })
        .catch((e) => {
          console.log('message \n', e?.response?.data?.error); // Log error message
          clearForm(); // Clear the form on error
          toast.error(e.message || intl.formatMessage({ id: 'Sorry, an error has occurred' }));
          setLoading(false); // Stop loading
        });
    },
  });

  /**
   * Fetches scan data from the API.
   */
  const fetchScanData = async () => {
    setLoading(true); // Set loading state to true
    await axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`)
      .then((res) => {
        errorHandling(res.status); // Handle any errors based on response status
        // Check if the response status indicates success
        if (res.status >= 200 && res.status < 300) {
          formik.setValues(res.data); // Set form values with fetched data
        } else {
          toast.error(res.data.message || intl.formatMessage({ id: 'Sorry, an error has occurred' }));
        }
      })
      .catch((e) => {
        toast.error(e?.message || intl.formatMessage({ id: 'Sorry, an error has occurred' }));
      })
      .finally(() => {
        setLoading(false); // Stop loading regardless of success or failure
      });
  };

  // Effect to fetch scan data when the ID changes
  useEffect(() => {
    if (id !== null && process.env.REACT_APP_MODE !== 'demo') fetchScanData();
  }, [id]);
  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_edit'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clearForm()
        }}
      >
        <div
          className='modal-dialog'
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> {intl.formatMessage({id:'Edit Scan'})}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={clearForm}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {!loading ? (
                  <>
                    <div className='fv-row mb-10'>
                      <label className='form-label fs-6 fw-bolder'>{intl.formatMessage({id: 'Description'})}</label>
                      <textarea
                        placeholder={intl.formatMessage({id: 'Description'})}
                        {...formik.getFieldProps('description')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        name='description'
                        autoComplete='off'
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container mt-2 text-danger'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      )}
                    </div>
                    {process.env.REACT_APP_MODE === 'demo'&&<p className='text-danger'> {intl.formatMessage({id: 'This feature is not available in demo mode'})}</p>}
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-success'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        disabled={process.env.REACT_APP_MODE === 'demo'}
                      >
                         {intl.formatMessage({id:'Save'})}
                      </button>
                  </>
                ) : (
                  <PageLoading />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
export default EditModal
