/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useIntl } from 'react-intl'

type Props = {
  data?: any
}
/**
 * ChartLine component that renders a line chart using ApexCharts
 * @param {Object} props - Component props
 * @param {number[]} [props.data=[30, 45, 32, 70, 40]] - Array of data points for the chart
 * @returns {JSX.Element} Rendered ChartLine component
 */
const ChartLine: React.FC<Props> = ({data = [30, 45, 32, 70, 40]}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
   /**
   * Generates the chart options for ApexCharts
   * @param {number} height - Height of the chart
   * @param {string} labelColor - Color of the labels
   * @param {string} baseColor - Base color for the chart
   * @param {string} lightColor - Light color for the chart
   * @param {number[]} data - Data points for the chart
   * @returns {ApexOptions} ApexCharts options object
   */
function getChartOptions (
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string,
  data?: any
): ApexOptions {
  const options: ApexOptions = {
    series: [
      {
        name: intl.formatMessage({id: 'Vulnerable Software'}),
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: data,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      // normal: {
      //   filter: {
      //     type: 'none',
      //     value: 0,
      //   },
      // },
      hover: {
        filter: {
          type: 'none',
          // value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          // value: 0,
        },
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
      },
      y: {

        formatter: function (val: number) {
          return val?.toString()
        },
      },
      enabled: false
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
  return options
}
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(80, '#CDCDDE', '#F64E60', '#ff3c3c29', data)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, data])

  return (
    <div
      ref={chartRef}
      className='card-rounded-bottom statistics-widget-3-chart'
      style={{height: '80px' , maxWidth:'calc(100% - 60px)'}}
    ></div>
 
  )
}

export default ChartLine


