import React, { useLayoutEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import { useIntl } from 'react-intl'
import { Button } from 'react-bootstrap'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import { toast } from 'sonner'

export default function HistoryDetails({ id }: { id: string | number }) {
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const [loadingPay, setLoadingPay] = useState(false)

  // Your existing functions remain unchanged
  const fetchPaymentHistory = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/payments/${id}/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/payments/ \n`, data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function PostPayId(id: string) {
    setLoadingPay(true)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/pay/`, {
      payment: id,
    })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          const URL = res.data.payment_url
          if (URL) {
            window.location.href = URL
          }
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({ id: 'Sorry, an error has occurred' }))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data)
        toast.error(intl.formatMessage({ id: 'Sorry, an error has occurred' }))
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }

  useLayoutEffect(() => {
    if (id) fetchPaymentHistory()
  }, [id])

  return (
    <div
      className="modal fade"
      id="kt_modal_invoice"
      style={{ backdropFilter: 'blur(3px)' }}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: '550px' }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="modal-content shadow">
          {/* Modal Header */}
          <div className="modal-header border-0 py-3">
            <div className="modal-title">
              <h3 className="fw-bold m-0">{intl.formatMessage({ id: 'Invoice Details' })}</h3>
            </div>
            <div
              className="btn btn-icon btn-sm btn-light-primary ms-2"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2"
              />
            </div>
          </div>

          {/* Modal Body */}
          <div className={`modal-body p-5 ${loading && 'skeleton'}`}>
            {/* Status Badge */}
            <div className="mb-8 text-center">
              <div className={`fw-bold fs-3 mb-3`}>
                {intl.formatMessage({ id: 'Thank for your purchase' })}
              </div>
              <div className={`badge fs-6 badge-light-${
                data?.payment_status === 'pending' ? 'warning' :
                data?.payment_status === 'completed' ? 'success' : 'danger'
              } p-4`}>
                {intl.formatMessage({ id: data?.payment_status || 'Failed' })}
              </div>
            </div>

            {/* Invoice Details */}
            <div className="border rounded bg-light p-5">
              {/* Plan Info */}
              <div className="d-flex justify-content-between align-items-center mb-5">
                <div className="text-gray-600">
                  {intl.formatMessage({ id: 'ID' })}:   # {data?.id}
                </div>
              </div>

              {/* Amount Details */}
              <div className="border-bottom py-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>{intl.formatMessage({ id: 'Amount' })}</span>
                  <span>{FormatAndConvertAmount(data?.amount)}/{intl.formatMessage({ id: 'IR-T' })}</span>
                </div>
              </div>

              {/* Discount */}
              <div className="border-bottom py-3">
                <div className="d-flex justify-content-between mb-2">
                  <span>{intl.formatMessage({ id: 'Discount amount' })}</span>
                  <span>{FormatAndConvertAmount(data?.discount_amount)}/{intl.formatMessage({ id: 'IR-T' })}</span>
                </div>
              </div>

              {/* Final Amount */}
              <div className="pt-3">
                <div className="d-flex justify-content-between">
                  <span className="fw-bold fs-4">{intl.formatMessage({ id: 'Final amount' })}</span>
                  <span className="fw-bolder fs-4">
                    {FormatAndConvertAmount(data?.final_amount)}/{intl.formatMessage({ id: 'IR-T' })}
                  </span>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  )
}