import {useState} from 'react'

import './toltip.scss'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import Pause from '../../images/Pause'
import Trash from '../../images/Trash'
import axios from 'axios'
import ConvertDate from '../../../../Utils/ConvertDate'
import Edit from '../../images/Edit'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import EditModal from './EditModal'
import {useIntl} from 'react-intl'
import Warning from '../../images/Warning'
import Play from '../../images/Play'
import Check from '../../images/Ckeck'
import {SCAN_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {useNavigate} from 'react-router-dom'
import {toast} from 'sonner'
import { Result as ResultScan} from '../../../modules/types/scan' 
interface Props {
  setIdSelected: React.Dispatch<React.SetStateAction< null| string>>
  idSelected:  null| string
  scanData: ResultScan[]|undefined | null
  getData: (page?: number) => Promise<void>
  getLoading: boolean
}
const ScanTable = ({scanData, getData, setIdSelected, idSelected, getLoading}: Props) => {
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.root.user)
  const [loading, setLoading] = useState(false)
  const [del, setDel] = useState<null | number | string>(null)
  const [stop, setStop] = useState<null | number | string>(null)
  const [editId, setEditId] = useState<null | number | string>(null)
  /**
   * Stops a running scan.
   * 
   * This function sends a POST request to the API to stop a specific scan.
   * It handles the stopping process, including success and error notifications,
   * and updates the UI accordingly.
   * 
   * @param id - The unique identifier of the scan to be stopped.
   * @returns A Promise that resolves when the stopping process is complete.
   * 
   * @remarks
   * - This function only executes if the application is not in demo mode.
   * - It uses axios for the HTTP request and toast for notifications.
   * - On successful stop, it refreshes the scan data.
   * - Error handling includes both API and network errors.
   */
  async function stopScan(id: string): Promise<void> {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setStop(id)
      await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/stop/`, {
          status: 'stop',
        })
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            toast.success(intl.formatMessage({id: 'Scan successfully stopped.'}))
            getData()
          } else {
            console.log(res?.data?.detail)
            toast.error(
              res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
            )
          }
        })
        .catch((e) => {
          toast.error(e.message || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        })
        .finally(() => {
          setStop(null)
        })
    }
  }


  /**
   * Deletes a scan from the system.
   * 
   * This function sends a DELETE request to the API to remove a specific scan.
   * It handles the deletion process, including success and error notifications,
   * and updates the UI accordingly.
   * 
   * @param id - The unique identifier of the scan to be deleted.
   * @returns A Promise that resolves when the deletion process is complete.
   * 
   * @remarks
   * - This function only executes if the application is not in demo mode.
   * - It uses axios for the HTTP request and toast for notifications.
   * - On successful deletion, it refreshes the scan data.
   * - Error handling includes both API and network errors.
   */
  async function deleteScan(id: string): Promise<void> {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setDel(id)
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`)
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            toast.success(intl.formatMessage({id: 'Scan successfully deleted.'}))
            getData()
          } else {
            toast.error(
              res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
            )
          }
        })
        .catch((e) => {
          toast.error(e.message || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        })
        .finally(() => {
          setDel(null)
        })
    }
  }

  return (
    <>
      {/* start Alert */}
      <div
        className='top-10 right-auto position-absolute d-flex flex-column align-items-center start-auto'
        style={{zIndex: 100, top: -45}}
      >
      </div>
      {/* end Alert */}
      <div className={`w-100 h-100 ${getLoading && 'skeleton'} `}>
        <div className={` h-100  rounded-bottom-0 p-3`}>
          <div className='d-flex flex-row justify-content-between align-items-start pt-2 w-100 h-100px pe-3'>
            <div className='d-flex justify-content-between align-items-center mb-10 w-100'>
              <h3 className=' '>{intl.formatMessage({id: 'Scan'})}</h3>
              <button
                type='button'
                className='btn btn-primary'
                disabled={loading}
                onClick={() => {
                  navigate('create')
                }}
              >
                {loading
                  ? `${intl.formatMessage({id: 'Wait for create Scan'})} ...`
                  : intl.formatMessage({id: 'Create New Scan'})}
              </button>
            </div>
          </div>
          {/* start body */}
          <div className={`w-100 h-100 overflow-visible   m-0 `}>
            {(!scanData?.length && !user?.is_user_scan ? SCAN_DATA.results : scanData)?.map(
              (item, ind: number) => {
                return (
                  <div
                    key={item?.id}
                    className={`w-100 m-0 p-0 h-125px bg-gray-200 rounded-1 d-flex justify-content-center align-items-center mb-3 hover_Costume
                    ${item.id === idSelected && 'selected_Costume'}
                    `}
                    style={!scanData?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
                    onClick={() => {
                      if (scanData?.length) setIdSelected(item?.id)
                    }}
                  >
                    <div className='d-flex justify-content-between align-items-center p-2 pt-1 pb-1 w-100'>
                      <div
                        className={`w-3px h-100px rounded ${
                          item?.status === 'failed'
                            ? 'bg-danger'
                            : item?.status === 'running'
                            ? ' bg-primary'
                            : 'bg-success'
                        }`}
                      ></div>
                      <div
                        className='d-flex flex-column justify-content-between align-items-center p-1 mw-25 w-25 h-100px h-auto'
                        style={{maxWidth: 130}}
                      >
                        <div className={`w-100 h-auto text-primary fs-5`}>{item?.target}</div>
                        <div className='d-flex flex-column gap-1 w-100 h-auto'>
                          <span className='text-gray-800'>
                            {intl.formatMessage({id: 'Scan Profile'})}
                          </span>
                          <span className='text-primary fs-6'>{item?.profile_name || '_'}</span>
                        </div>
                      </div>
                      <div
                        className='d-flex flex-column justify-content-between p-1 mw-330px w-75 h-100px h-auto'
                        style={{maxWidth: 330}}
                      >
                        <div className='d-flex justify-content-between gap-3 px-2'>
                          {/* <div className='d-flex flex-column gap-1 w-50px h-auto'>
                            <span className='text-gray-800'>
                              {intl.formatMessage({id: 'Rate'})}
                            </span>
                            <div className='d-flex align-items-center p-1 h-auto'>
                              {item?.rate}
                            </div>
                          </div> */}

                          <div className='d-flex flex-column gap-1 h-auto'>
                            <span className='text-gray-800'>
                              {intl.formatMessage({id: 'Started'})}
                            </span>
                            <div className='d-flex justify-content-center p-1 h-auto'>
                              <ConvertDate
                                date={item?.start_time}
                                jalali={item?.start_time_jalali}
                              />
                            </div>
                          </div>
                          <div style={{minWidth: 108}}>
                            <div
                              className='d-flex justify-content-center align-items-center gap-2 bg-body px-2 p-1 rounded'
                              style={{height: 'fit-content', width: 'fit-content'}}
                            >
                              {item?.status === 'failed' ? (
                                <Warning className='svg-icon svg-icon-2x svg-icon-danger' />
                              ) : item?.status === 'running' ? (
                                <Play className='svg-icon svg-icon-2x svg-icon-primary' />
                              ) : (
                                <Check className='svg-icon svg-icon-2x svg-icon-success' />
                              )}
                              <span className='text-capitalize'>
                                {intl.formatMessage({id: item?.status || 'failed'})}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex flex-column gap-1 px-2 w-100'>
                          <span className='text-gray-800'>
                            {intl.formatMessage({id: 'Description'})}
                          </span>
                          <div
                            className='w-100 h-auto'
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '90%',
                            }}
                          >
                            {item?.description || '_'}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column justify-content-center gap-2 p-1 w-30px h-auto ms-2 pe-3'>
                        <button
                          className={`col-auto border border-0 text-white bg-transparent p-0 m-0`}
                          type='button'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_edit'
                          onClick={() => {
                            if (scanData?.length) setEditId(item.id)
                          }}
                          disabled={!scanData?.length}
                        >
                          <Edit className='svg-icon svg-icon-2x svg-icon-primary' />
                        </button>
                        <button
                          className={`col-auto border border-0 bg-transparent text-white bg-gray-200 p-0 m-0`}
                          disabled={stop === item.id}
                          onClick={() => {
                            toast.info(intl.formatMessage({id: 'stop scan title'}), {
                              duration: 10000,
                              className: 'sonner',
                              closeButton: true,
                              action: {
                                label: intl.formatMessage({id: 'Stop'}),
                                onClick: () => {
                                  if (scanData?.length) stopScan(item.id)
                                },
                              },
                            })
                          }}
                        >
                          <Pause
                            className={`svg-icon-2x ${
                              stop === item.id ? 'image_change_theme2' : 'svg-icon-warning'
                            }`}
                          />
                        </button>
                        <button
                          className={`col-auto border border-0 bg-transparent text-white bg-gray-200 p-0 m-0`}
                          disabled={del === item.id}
                          onClick={() => {
                            toast.info(intl.formatMessage({id: 'delete scan title'}), {
                              className: 'sonner',
                              closeButton: true,
                              duration: 10000,
                              action: {
                                label: intl.formatMessage({id: 'Delete'}),
                                onClick: () => {
                                  if (scanData?.length) deleteScan(item.id)
                                },
                              },
                            })
                          }}
                        >
                          <Trash
                            className={`svg-icon-2x ${
                              del === item.id ? 'image_change_theme2' : 'svg-icon-danger'
                            }`}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
      {/* <!-- Modal--> */}
      <EditModal getData={getData}  id={editId} setId={setEditId} />
    </>
  )
}

export default ScanTable
