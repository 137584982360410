import React from 'react'

/**
 * ImageModal component displays an image in a modal dialog
 * @param {Object} props - Component props
 * @param {string} props.url - The URL of the image to display
 * @param {string} [props.id=''] - Optional ID suffix for the modal
 * @returns {JSX.Element|null} ImageModal component or null if no URL is provided
 */
export default function ImageModal({url, id = ''}: {url: string, id?: string}) {
  // Return null if no URL is provided
  if (!url) return null;

  return (
    <div
      className='modal fade'
      id={`exampleModal${id}`}
      tabIndex={-1}
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-lg'>
        <div className='modal-content '>
          <div className='modal-header'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>

          <div className='p-5'>
            {/* Image container */}
            <div
              className='modal-body min-w-500px min-h-500px'
              style={{
                backgroundImage: `url(${url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            >
              {/* Image is displayed as a background */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

