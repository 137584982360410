import React, { useLayoutEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

// Utility and custom hooks
import useErrorHandling from '../../../../Utils/useErrorHandling'
import useGetFilterOptionsDnsRecord from '../../../../Utils/useGetFilterOptionsDnsRecord'
import usePermission from '../../../../Utils/usePermission'

// Component imports
import PageLoading from '../../loading/PageLoading'
import SearchAndSort from '../../components/SearchAndSort'

// Redux and data imports
import { RootState } from '../../../../setup/redux/Store'
import { EXPLORE_DNS_DATA as DnsData } from '../../../modules/auth/redux/DEMO_DATA'
import {
  FilterSelectedDnsRecord,
  SearchDnsRecord,
  SetDataDnsRecords,
  SortDnsRecord,
} from '../../../modules/auth'

// Type imports
import { SelectedType } from '../../../modules/types/filters'

// Empty sort options array (can be populated with actual sort options)
const SortOptions: any[] = []

/**
 * DNS Component for managing and displaying DNS records
 * 
 * @component
 * @description Provides comprehensive DNS record management with filtering, searching, and pagination
 * @returns {React.ReactElement} Rendered DNS records management interface
 */
export default function DNS () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)

  // Custom hooks and utility initializations
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const getFilterOptionsDnsRecord = useGetFilterOptionsDnsRecord()

  // Redux selectors for accessing global state
  const dnsRecordsData = useSelector((state: RootState) => state.root.data.dataDnsRecords)
  const filterOptionDnsRecord = useSelector(
    (state: RootState) => state?.root?.filterOption?.dnsRecord.options
  )
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.dnsRecord.selected)
  const sort = useSelector((state: RootState) => state.root.filterOption.dnsRecord)
  const search = useSelector((state: RootState) => state.root.filterOption.dnsRecord.search) 

    /**
   * Generates sorting query parameters for API request
   * 
   * @param {string} sortProps - Sorting property to be applied
   * @returns {string} Formatted sorting query string
   */
  function createPropsSort (sortProps: string) {
     // Prioritize explicit sort props, then check for ascending/descending sort
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }

    /**
   * Constructs search query parameters from search state
   * 
   * @returns {string} Concatenated search query string
   */
  function createPropsSearch () {
       // Map search items to query string format
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }

  /**
   * Handles pagination click event
   * 
   * @param {Object} event - Pagination event containing selected page
   */
  const handlePageClick = (event: {selected: number}) => {
    fetchDNSRecords({query: `?page=${event.selected + 1}`})
  }

  /**
   * Fetches DNS records from API with optional filtering and sorting
   * 
   * @param {Object} params - API request parameters
   * @param {string} [params.query='?page=1'] - Pagination query string
   * @param {Object|null} [params.filterProps=null] - Filter properties
   * @param {string} [params.sort=''] - Sorting parameters
   */
  const fetchDNSRecords = async ({
    query = '?page=1',
    filterProps = null,
    sort = '',
  }: {
    query?: string
    filterProps?: null | SelectedType
    sort?: string
  }) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/dns-record/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataDnsRecords(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(FilterSelectedDnsRecord(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchDNSRecords({query: '?page=1', filterProps: copy})
    }
  }
  function createPropsFilter (props?: null | SelectedType) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected || {})
      const text = keys.map((key) => {
        if (selected?.[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }

// Fetch initial data on component mount
useLayoutEffect(() => {
  const nowDate = new Date().getTime()
  
  // Refresh data if expired or not present
  if (dnsRecordsData?.expireTime && dnsRecordsData.expireTime >= nowDate) {
    // Use existing data
  } else {
    fetchDNSRecords({})
  }

  // Fetch filter options in non-demo mode
  if (!filterOptionDnsRecord && process.env.REACT_APP_MODE !== 'demo') {
    getFilterOptionsDnsRecord()
  }
}, [])

  return (
    <>
      <div
        className={`w-100  p-4 bg-body overflow-hidden position-relative ${loading && 'skeleton'}`}
        style={{maxWidth: 'calc(100% - 100px)'}}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'DNS Records'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div
            className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '
            style={!filterOptionDnsRecord ? {filter: 'blur(3px)'} : {}}
          >
            {filterOptionDnsRecord ? (
              Object.keys(filterOptionDnsRecord)?.map((items: string, index: number) => {
                return (
                  <div key={index} className='d-flex flex-column'>
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {filterOptionDnsRecord[items]?.length ? (
                      filterOptionDnsRecord[items]
                        .slice(
                          0,
                          filterOptionDnsRecord[items]?.length > 10
                            ? open === index
                              ? filterOptionDnsRecord[items]?.length - 1
                              : 10
                            : filterOptionDnsRecord[items]?.length
                        )
                        .map((item, ind: number) => (
                          <div key={ind}>
                            <hr className='my-2 mx-0' />
                            <div
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                selectedHandel(items, item.name)
                              }}
                              className='form-check form-check-custom form-check-solid form-check-sm  p-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={selected?.[items]?.includes(item.name)}
                              />
                              <div className='form-check-label w-100 d-flex justify-content-between mx-2'>
                                <span>{item.name}</span>
                                <span>{item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {filterOptionDnsRecord[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>
          <div className=' w-75 ps-3 '>
            <SearchAndSort
              className={'mb-5'}
              search={search}
              setSearch={SearchDnsRecord}
              sort={sort}
              setSort={SortDnsRecord}
              getData={fetchDNSRecords}
              sortOption={SortOptions}
              searchOption={Object.keys(filterOptionDnsRecord || {})}
            />
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>
                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex gap-5 fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-50 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Record'})}</span>
                      </div>
                    </div>
                    <div className='w-25 d-flex justify-content-start p-1  h-auto'>
                      {intl.formatMessage({id: 'Type'})}
                    </div>
                    <div className='w-25 p-1  h-auto d-flex justify-content-start'>
                      <div className='w-100 h-auto d-flex justify-content-start align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Domain'})}</span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {(dnsRecordsData?.data && dnsRecordsData?.data?.results?.length
                  ? dnsRecordsData.data.results
                  : DnsData.results
                ).map((item, index: number) => (
                  <div
                    style={!dnsRecordsData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                    key={index}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center mb-3`}
                  >
                    <div className='w-100 d-flex gap-5 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div className='w-50 p-1   h-auto text-nowrap text_hide_width'>
                        {' '}
                        {item.record}{' '}
                      </div>
                      <div className='w-25 d-flex justify-content-start align-items-center p-1 h-auto text-uppercase text-nowrap text_hide_width'>
                        {item.record_type}
                      </div>
                      <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-start text-nowrap text_hide_width'>
                        {item.domain}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={dnsRecordsData?.data?.total_pages || 1}
                  forcePage={
                    dnsRecordsData?.data?.current_page ? dnsRecordsData?.data?.current_page - 1 : 0
                  }
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
