import React from 'react'
import {Button} from 'react-bootstrap'
import { useIntl } from 'react-intl'
import {useNavigate} from 'react-router-dom'
/**
 * NotPermission - A component displayed when user lacks access permissions
 * 
 * @component
 * @description Renders a full-page overlay indicating insufficient permissions
 * 
 * @returns {React.ReactElement} Permission denied interface
 * 
 * @remarks
 * - Uses react-router-dom for navigation
 * - Supports internationalization via react-intl
 * 
 * @example
 * <NotPermission />
 */
export default function NotPermission () {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <div
      className='w-100 mw-100 h-100 position-absolute d-flex justify-content-center  p-0 m-0'
      style={{
        zIndex: 5,
        backdropFilter: 'blur(4px)',
      }}
    >
      <div
        className='p-10 d-flex gap-10 flex-column align-items-center  card position-sticky  '
        style={{
          height: 'fit-content',
          width: '70%',
          maxWidth: 600,
          minWidth: 400,
          backgroundColor: '#76767670',
          top: 90,
        }}
      >
        <h1 className='fw-bold text-center'>
        {intl.formatMessage({id: 'You do not have permission to access this page'})}
          </h1>
        <Button
          onClick={() => {
            navigate('/services')
          }}
        >
          {intl.formatMessage({id: 'Get access'})}
          
        </Button>
      </div>
    </div>
  )
}
