import React, {useState, useLayoutEffect} from 'react'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {SetDataDNS} from '../../modules/auth'
import {useIntl} from 'react-intl'
import {DNS_DATA} from '../../modules/auth/redux/DEMO_DATA'
import usePermission from '../../../Utils/usePermission'
import { Alert, Button, Card } from 'react-bootstrap'
import { SectionData } from '../smtp'
import { Section } from '../smtp/components/Section'

/**
 * DNSInspect component for displaying and managing DNS inspection data.
 * @returns {JSX.Element} The rendered DNSInspect component
 */
// export const DNSInspect = () => {
//   const [loading, setLoading] = useState(false)
//   const dnsData = useSelector((state: RootState) => state?.root?.data?.dataDns)
//   const dispatch = useDispatch()
//   const {isPermission, getPermission} = usePermission()
//   const [initialIndex, setInitialIndex] = useState(0)
//   const [isOpen, setIsOpen] = useState<null | number>(null)
//   const errorHandling = useErrorHandling()
//   const user = useSelector((state: RootState) => state?.root?.user)
//   const intl = useIntl()

//   /**
//    * Fetches the DNS ID list from the API.
//    */
//   const fetchDnsIdList = async () => {
//     setLoading(true)
//     try {
//       const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dns/`)
//       errorHandling(data?.status) // Logout when invalid token && redirect 404
//       getPermission(data.status)
//       if (data?.status >= 200 && data?.status < 300) {
//         dispatch(SetDataDNS(data?.data))
//       } else {
//         console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dns \n`, data)
//       }
//       setLoading(false)
//     } catch (e) {
//       console.error(e)
//       setLoading(false)
//     }
//   }

//   useLayoutEffect(() => {
//     const nowDate = new Date().getTime()
//     // Check if the DNS data is expired and fetch new data if necessary
//     if (dnsData?.expireTime && dnsData?.expireTime >= nowDate) {
//       // Data is still valid, no need to fetch
//     } else {
//       fetchDnsIdList()
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   return (
//     <>
//       <div className={`row d-flex flex-row mt-5  position-relative ${loading && 'skeleton'}`}>
//         {isPermission()}
//         <div className='w-100 h-100'>
//           <div
//             className={` h-100  `}
//             style={!dnsData.data?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
//           >
//             <div className='d-flex flex-row justify-content-between align-items-center mb-5 p-3 w-100 h-50px card card-body'>
//               {/* Previous button */}
//               <button
//                 className={`col-auto border border-0 text-white bg-body px-4 m-0`}
//                 onClick={() => {
//                   if (initialIndex > 0 && dnsData.data?.length) {
//                     setInitialIndex(initialIndex - 1)
//                   }
//                 }}
//               >
//                 <KTSVG
//                   svgClassName='image_change_theme2'
//                   path='/media/icons/duotune/arrows/arr002.svg'
//                   className='ms-2 svg-icon svg-icon-2x svg-icon-white'
//                 />
//               </button>

//               {/* Title */}
//               <h3 className='m-0 text-center'>
//                 {(!dnsData?.data?.length && !user?.is_user_scan ? DNS_DATA : dnsData?.data || [])[
//                   initialIndex
//                 ]?.data?.host &&
//                   `${intl.formatMessage({
//                     id: 'DNS Inspect for',
//                   })} ${
//                     (!dnsData?.data?.length && !user?.is_user_scan
//                       ? DNS_DATA
//                       : dnsData?.data || [])[initialIndex]?.data?.host
//                   }`}
//               </h3>

//               {/* Next button */}
//               <button
//                 className={` col-auto border border-0 text-white bg-body px-4 m-0`}
//                 onClick={() => {
//                   // Check if there's more data to show and increment the index
//                   if (
//                     dnsData.data?.length &&
//                     initialIndex <
//                       (!dnsData?.data?.length && !user?.is_user_scan
//                         ? DNS_DATA
//                         : dnsData?.data || []
//                       )?.length -
//                         1
//                   ) {
//                     setInitialIndex(initialIndex + 1)
//                   }
//                 }}
//               >
//                 <KTSVG
//                   svgClassName='image_change_theme2'
//                   path='/media/icons/duotune/arrows/arr001.svg'
//                   className='ms-2 svg-icon svg-icon-2x svg-icon-white'
//                 />
//               </button>
//             </div>
//             {/* Render DNS data sections */}
//             {Object.keys(
//               (!dnsData?.data?.length && !user?.is_user_scan ? DNS_DATA : dnsData?.data || [])[
//                 initialIndex
//               ]?.data || {}
//             )
//               ?.filter((item) => item !== 'host' && item !== 'score' && item !== 'timestamp')
//               ?.map(
//                 (item) =>
//                   (
//                     (!dnsData?.data?.length && !user?.is_user_scan
//                       ? DNS_DATA
//                       : dnsData?.data || [])[initialIndex]?.data as Record<string, any>
//                   )[item]
//               )
//               ?.map((section, ind) => (
//                 <Section
//                   key={section?.title + ind}
//                   data={section}
//                   ind={ind}
//                   isOpen={isOpen}
//                   setIsOpen={setIsOpen}
//                 />
//               ))}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

export const DNSInspect: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const errorHandling = useErrorHandling();
  const { isPermission, getPermission } = usePermission();
  const dispatch = useDispatch();
  const intl = useIntl();

  const DnsData = useSelector((state: RootState) => state?.root?.data?.dataDns);
  const user = useSelector((state: RootState) => state?.root?.user);
 /**
  * Fetches the DNS ID list from the server.
  * 
  * This function sends a GET request to the DNS endpoint, handles the response,
  * updates permissions, and dispatches the fetched data to the Redux store.
  * It also manages the loading state and handles any errors that occur during the process.
  * 
  * @async
  * @throws {Error} Logs any error that occurs during the fetch operation.
  */
 const fetchDnsIdList = async () => {
   setLoading(true);
   try {
     const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dns/`);
     errorHandling(data.status);
     getPermission(data.status);

     if (data.status >= 200 && data.status < 300) {
       dispatch(SetDataDNS(data.data));
     } else {
       console.error(`Error fetching DNS data:`, data);
     }
   } catch (e) {
     console.error(e);
   } finally {
     setLoading(false);
   }
 };

 /**
  * Effect hook to fetch DNS data when necessary.
  * 
  * This hook checks if the current DNS data has expired or doesn't exist,
  * and if so, it triggers a fetch operation to update the data.
  */
 useLayoutEffect(() => {
   const nowDate = new Date().getTime();
   if (!DnsData?.expireTime || DnsData.expireTime < nowDate) {
    fetchDnsIdList();
   }
 }, []);

 /**
  * Handles navigation to the previous DNS entry.
  * 
  * This function decrements the current index if it's not already at the beginning,
  * effectively moving to the previous DNS server entry in the list.
  */
 const handlePreviousEntry = () => {
   if (currentIndex > 0) {
     setCurrentIndex(currentIndex - 1);
   }
 };

 /**
  * Handles navigation to the next DNS entry.
  * 
  * This function increments the current index if it's not at the end of the list,
  * effectively moving to the next DNS server entry. It also determines the correct
  * data source based on the availability of DNS data and user scan status.
  */
 const handleNextEntry = () => {
   const dataSource = !DnsData?.data?.length && !user?.is_user_scan 
     ? DNS_DATA 
     : DnsData?.data || [];

   if (currentIndex < dataSource.length - 1) {
     setCurrentIndex(currentIndex + 1);
   }
 };

  const dataSource = !DnsData?.data?.length && !user?.is_user_scan 
    ? DNS_DATA 
    : DnsData?.data || [];

  return (
    <div className={`container py-4 position-relative ${loading&& 'skeleton'}`}
    
    >
    {isPermission()}
        <>
          <Card className="mb-4 smtp-header"
          style={!DnsData?.data?.length && !user?.is_user_scan ?{filter:'blur(3px'}:{}}
          >
            <Card.Body className="d-flex justify-content-between align-items-center" dir={'ltr'}>
              <Button
                variant="light"
                onClick={handlePreviousEntry}
                disabled={currentIndex === 0}
                aria-label='Previous server'
              >
                <i className="fa-chevron-left fas"></i>
              </Button>
              
              <h2 className="d-flex align-items-center mb-0">
                <i className="fa-server fas me-2"></i>
                <span>
                  {dataSource[currentIndex]?.data?.host || 
                   intl.formatMessage({ id: 'DNS Server' })}
                </span>
              </h2>

              <Button
                variant="light"
                onClick={handleNextEntry}
                disabled={currentIndex === dataSource.length - 1}
                aria-label='Next server'
              >
                <i className="fa-chevron-right fas"></i>
              </Button>
            </Card.Body>
          </Card>

          <div className="smtp-sections">
            {!DnsData?.data?.length && (
              <Alert variant="warning">
                <i className="fa-exclamation-circle fas me-2"></i>
                {intl.formatMessage({ id: 'No DNS data available. Please scan your servers first.' })}
              </Alert>
            )}

            {Object.entries(dataSource[currentIndex]?.data || {})
              .filter(([key]) => !['host', 'score', 'timestamp', 'smtp_address'].includes(key))
              .map(([key, section], index) => (
                <Section
                  key={key}
                  data={section as SectionData}
                  isActive={activeSection === index}
                  onToggle={() => setActiveSection(activeSection === index ? null : index)}
                />
              ))}
          </div>
        </>
      
    </div>
  );
};