import React, {useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useIntl} from 'react-intl'
import {Badge, Button, Card, Col, Row, Table} from 'react-bootstrap'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import {toast} from 'sonner'
import dayjs from 'dayjs'
import {InvoiceType} from '../../modules/types/paymentInvoices'

// Format currency with proper separator and currency symbol
const formatCurrency = (amount: string) => {
  return new Intl.NumberFormat('fa-IR', {
    style: 'currency',
    currency: 'IRR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(amount))
}

// Get status badge variant
const getStatusVariant = (status: string, isExpired: boolean) => {
  if (isExpired) return 'danger'
  switch (status) {
    case 'completed':
      return 'success'
    case 'pending':
      return 'warning'
    case 'failed':
      return 'danger'
    default:
      return 'secondary'
  }
}

const handlePrint = () => {
  window.print()
}

export default function InvoicesDetails ({id , setId}: {id: string,setId:React.Dispatch<React.SetStateAction<string>>}){
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<InvoiceType>()
  const [loadingPay, setLoadingPay] = useState(false)

  // Your existing functions remain unchanged
  const fetchPaymentHistory = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/invoices/${id}/`)
      errorHandling(data?.status)
      if (data?.status >= 200 && data?.status < 300) {
        setData(data?.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/invoices/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function PostPayId (id: string) {
    setLoadingPay(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/pay/`, {
        payment: id,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          const URL = res.data?.payment_url
          if (URL) {
            window.location.href = URL
          }
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data)
        toast.error(intl.formatMessage({id: 'Sorry, an error has occurred'}))
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }

  useLayoutEffect(() => {
    if (id) fetchPaymentHistory()
  }, [id])

  return (
    <div
      className='fade modal'
      id='kt_modal_invoice'
      style={{backdropFilter: 'blur(3px)'}}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <div
        className='modal-dialog modal-dialog-centered'
        style={{maxWidth: '70vw', width: '800px'}}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className='shadow modal-content'>
          {/* Modal Header */}
          <div className='border-0 py-3 modal-header'>
            <div className='modal-title'>
              <h3 className='m-0 fw-bold'>{intl.formatMessage({id: 'Invoice Details'})}</h3>
            </div>
            <div className='btn btn-icon btn-light-primary btn-sm ms-2' data-bs-dismiss='modal' onClick={()=>{
              setData(undefined)
              setId('')
            }}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>

          {/* Modal Body */}
          <div className={`modal-body p-5 ${loading && 'skeleton'}`}>
            <Card className='shadow-sm mb-4'>
              <Card.Body className='py-4'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <div>
                    <h3 className='d-inline-block mb-0'>
                      {intl.formatMessage({id: 'Invoice Details'})}
                    </h3>
                  </div>
                  <div>
                    <Button variant='outline-primary' onClick={handlePrint}>
                      <i className='bi bi-printer me-2'></i>
                      {intl.formatMessage({id: 'Print'})}
                    </Button>
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className='mb-4'>
                      <div className='mb-2 text-muted'>
                        {intl.formatMessage({id: 'Invoice ID'})}
                      </div>
                      <div className='fw-bold'>{data?.id}</div>
                    </div>
                  </Col>
                  <Col md={6} className='text-md-end'>
                    <div className='mb-4'>
                      <Badge
                        bg={getStatusVariant(data?.payment_status || '', data?.is_expired || false)}
                        className='px-3 py-2 fs-6'
                      >
                        {data?.is_expired
                          ? intl.formatMessage({id: 'Expired'})
                          : intl.formatMessage({id: data?.payment_status || ' '})}
                      </Badge>
                    </div>
                    <div>
                      <div className='mb-2 text-muted'>
                        {intl.formatMessage({id: 'Expiry Date'})}
                      </div>
                      <div className={`fw-bold ${data?.is_expired ? 'text-danger' : ''}`}>
                        {dayjs(data?.expiry_date).format('YYYY/MM/DD HH:mm')}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Plan Details Section */}
            <Card className='shadow-sm mb-4'>
              <Card.Header className='bg-transparent text-center'>
                <h5 className='my-auto text-center'>
                  {intl.formatMessage({id: 'Product Details'})}
                </h5>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <Table className='table-borderless'>
                      <tbody>
                        <tr>
                          <td className='text-muted ps-0' style={{width: '150px'}}>
                            {intl.formatMessage({id: 'Name'})}
                          </td>
                          <td className='fw-bold'>{data?.invoiced_object.name}</td>
                        </tr>
                        <tr>
                          <td className='text-muted ps-0' style={{width: '150px'}}>
                            {intl.formatMessage({id: 'Type'})}
                          </td>
                          <td className='fw-bold'>{data?.invoiced_object_type}</td>
                        </tr>
                        <tr>
                          <td className='text-muted ps-0'>
                            {intl.formatMessage({id: 'Duration'})}
                          </td>
                          <td className='fw-bold'>
                            {data?.invoiced_object.duration_days} {intl.formatMessage({id: `Days`})}
                          </td>
                        </tr>
                        {/* <tr>
                          <td className='text-muted ps-0'>
                            {intl.formatMessage({id: 'Scan Limit'})}
                          </td>
                          <td className='fw-bold'>{data?.invoiced_object.scan_limit}</td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6}>
                    <Table className='table-borderless'>
                      <tbody>
                        <tr>
                          <td className='text-muted ps-0' style={{width: '150px'}}>
                            {intl.formatMessage({id: 'Period Start'})}
                          </td>
                          <td className='fw-bold'>
                            {dayjs(data?.billing_period_start).format('YYYY/MM/DD HH:mm')}
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted ps-0'>
                            {intl.formatMessage({id: 'Period End'})}
                          </td>
                          <td className='fw-bold'>
                            {dayjs(data?.billing_period_end).format('YYYY/MM/DD HH:mm')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Payment Details Section */}
            <Card className='shadow-sm'>
              <Card.Header className='d-flex justify-content-between align-items-center bg-transparent w-100'>
                <h5 className='my-auto'>{intl.formatMessage({id: 'Payment Details'})}</h5>
                {data&&!data?.is_expired && data?.payment_status !== 'completed' && (
                  <Button
                    variant='success'
                    className='px-4 py-2'
                    onClick={() => {
                      PostPayId(id)
                    }}
                  >
                    {intl.formatMessage({id: 'Payment'})}
                  </Button>
                )}
              </Card.Header>
              <Card.Body>
                <Table className='mb-0 table-borderless'>
                  <tbody>
                    <tr>
                      <td className='text-muted ps-0' style={{width: '200px'}}>
                        {intl.formatMessage({id: 'Base Amount'})}
                      </td>
                      <td className='fw-bold'>{FormatAndConvertAmount(data?.amount || '0')}</td>
                    </tr>

                    <tr>
                      <td className='text-muted ps-0'>
                        {intl.formatMessage({id: 'Discount Amount'})}
                      </td>
                      <td className='fw-bold text-success'>
                        - {FormatAndConvertAmount(data?.discount_amount || '0')}
                      </td>
                    </tr>

                    <tr className='border-top'>
                      <td className='text-muted ps-0'>
                        {intl.formatMessage({id: 'Final Amount'})}
                      </td>
                      <td className='fw-bold fs-4'>{FormatAndConvertAmount(data?.final_amount || '0')}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
