import {useNavigate} from 'react-router-dom'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Arr001 from '../../components/Arr001'
import {useIntl} from 'react-intl'
import {RootState} from '../../../../setup/redux/Store'
import {useSelector} from 'react-redux'
import { AssetAtRisk } from '../../../modules/types/dashboard'

const DefaultData = [
  {
    id: 4992,
    address: 'sub2.targetB.com',
    total_vulnerabilities: 54,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [54]},
  },
  {
    id: 4993,
    address: 'portal.sub2.targetB.com',
    total_vulnerabilities: 10,
    vulnerabilities_over_time: {x: ['2023-06-17'], y: [10]},
  },
]
const AssetsRisk = ({
  newTechnologies,
  loading,
}: {
  /** Array of assets at risk, or undefined if not available */
  newTechnologies: AssetAtRisk[] | undefined;
  /** Boolean indicating if the data is currently loading */
  loading: boolean;
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.root.user);

  return (
    <div className='col-4' style={{ height: 270 }}>
      <div className={`card card-body h-100 bg-body ${loading && 'skeleton'}`}>
        {/* Title section with tooltip */}
        <div className='d-flex flex-row justify-content-between align-items-center pt-2 pb-5'>
          <div className='d-flex flex-row align-items-center'>
            <h3 className='card-title align-items-start flex-column m-0'>
              {intl.formatMessage({ id: 'Assets at Risk' })}
            </h3>
            <span
              data-tooltip-id='my-tooltip-assetsAtRisk'
              data-tooltip-content={intl.formatMessage({ id: 'Assets at Risk tooltip' })}
            >
              <InfoIcon />
            </span>
            <ReactTooltip
              id='my-tooltip-assetsAtRisk'
              style={{ maxWidth: 250, fontSize: 12, textAlign: 'center' }}
            />
          </div>
          <button
            className={`col-auto border border-0 bg-body p-0 m-0`}
            onClick={() => {
              navigate('/assets');
            }}
          >
            {intl.formatMessage({ id: 'View All' })}{' '}
            <Arr001 fill={'#fff'} className={'image_change_theme'} />
          </button>
        </div>
        {/* Body section displaying asset data */}
        <div
          className={`w-100 h-100 overflow-auto pe-2 m-0`}
          style={!newTechnologies?.length && !user?.is_user_scan ? { filter: 'blur(3px)' } : {}}
        >
          <div className='w-100 d-flex bg-secondary rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
            <div className='w-200px p-1 h-auto'> {intl.formatMessage({ id: 'Address' })} </div>
            <div className='w-150px p-1 ms-2 d-flex justify-content-center border-2 border border-dark border-top-0 border-bottom-0 h-auto'>
              {intl.formatMessage({ id: 'Issues' })}
            </div>
            <div className='w-100 p-1 ms-2 d-flex justify-content-center h-auto'>
              {intl.formatMessage({ id: 'Risk over time' })}
            </div>
          </div>
          {(user?.is_user_scan ? newTechnologies : DefaultData)?.map((item, index, arr) => {
            return (
              <div
                key={item?.id}
                className={`d-flex flex-row w-100 h-70px bg-secondary p-2 border border-box m-0 align-items-center rounded-1 ${
                  index === arr?.length - 1 ? null : 'mb-3'
                } `}
              >
                <div className='w-200px p-1 text-primary text-truncate h-auto'>{item?.address}</div>
                <div className='w-150px p-1 ms-2 h-auto d-flex justify-content-center'>
                  {item?.total_vulnerabilities}
                </div>
                <div className='w-100 p-4 pb-11 ms-2 h-auto d-flex justify-content-center'>
                  {item?.vulnerabilities_over_time?.y ? (
                    <ColumnChart data={item?.vulnerabilities_over_time?.y || [2, 6, 8, 4]} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssetsRisk;
