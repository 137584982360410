import React from 'react'
import './style.scss'
export default function Details () {
  return (
    <div className='d-flex flex-column content-wrapper gap-3 app_d' style={{minHeight: 792, width: 'calc(100% - 100px)'}}>
      <a id='information' className='anchor'></a>
      <section className='d-flex flex-column gap-3 content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'></h5>
                  <div className='row'>
                    <div className='col-2'>
                      <p>
                        <strong>
                          <i className='fa-check-double fas'></i> APP SCORES
                        </strong>
                      </p>

                      <img
                        id='app_icon'
                        src='/media/test2.png'
                      />
                      <p>
                        <span className='bg-warning text-white badge'>Security Score</span>{' '}
                        <strong>42/100 </strong>
                        <br />
                        <span className='bg-success text-white badge'>Trackers Detection</span>{' '}
                        <strong>0/432</strong>
                        <br />
                      </p>

                      <p>
                        {' '}
                        <a className='btn btn-outline-primary btn-sm' role='button'>
                          <i className='fa-user-shield fas'></i> MobSF Scorecard
                        </a>
                      </p>
                    </div>

                    <div className='col-6'>
                      <p>
                        <strong>
                          <i className='fa-box-open fas'></i> FILE INFORMATION{' '}
                        </strong>
                      </p>
                      <span className='bg-primary text-white badge'>File Name</span>
                      LETTER_LINK_46.0.0_apkcombo.com.apk
                      <br />
                      <span className='bg-primary text-white badge'>Size</span>
                      2.0MB
                      <br />
                      <span className='bg-primary text-white badge'>MD5</span>
                      1a7acfed3b610936f794394b601c51c6
                      <br />
                      <span className='bg-primary text-white badge'>SHA1</span>
                      70e5b20af9eaa41aa9b5c5397e88319a72edb1e9
                      <br />
                      <span className='bg-primary text-white badge'>SHA256</span>
                      5b78106f3716f420be1513289111a8989b28a755f38e017fa27ddcf56d4567c6
                      <br />
                    </div>

                    <div className='col-4'>
                      <p>
                        <strong>
                          <i className='fa-info fas'></i> APP INFORMATION{' '}
                        </strong>
                      </p>
                      <span className='bg-primary text-white badge'>App Name</span>
                      Letter Link
                      <br />
                      <span className='bg-primary text-white badge'>Package Name</span>
                      com.sm.letterlink
                      <br />
                      <span className='bg-primary text-white badge'>Main Activity</span>
                      com.sm.letterlink.MainActivity
                      <br />
                      <span className='bg-primary text-white badge'>Target SDK</span>
                      29
                      <span className='bg-primary text-white badge'>Min SDK</span>4
                      <span className='bg-primary text-white badge'>Max SDK</span>
                      <br />
                      <span className='bg-primary text-white badge'>Android Version Name</span>
                      46.0.0
                      <span className='bg-primary text-white badge'>Android Version Code</span>
                      460000
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'></h5>
                  <div className='row'>
                    <div className='col-sm-6 col-12 col-md-3'>
                      <div className='bg-info small-box'>
                        <div className='inner'>
                          <h3> 0 / 1</h3>

                          <p>EXPORTED ACTIVITIES</p>
                        </div>
                        <div className='icon'>
                          <i
                            className='fa fa-language'
                            style={{fontSize: 70, color: ' rgba(0, 0, 0, .15)'}}
                          ></i>
                        </div>
                        <a className='small-box-footer'>
                          View All <i className='fa-arrow-circle-down fas'></i>
                        </a>
                      </div>
                    </div>

                    <div className='col-sm-6 col-12 col-md-3'>
                      <div className='bg-success small-box'>
                        <div className='inner'>
                          <h3>0 / 0</h3>

                          <p>EXPORTED SERVICES</p>
                        </div>
                        <div className='icon'>
                          <i
                            className='fa fa-cogs'
                            style={{fontSize: 70, color: ' rgba(0, 0, 0, .15)'}}
                          ></i>
                        </div>
                        <a className='small-box-footer'>
                          View All <i className='fa-arrow-circle-down fas'></i>
                        </a>
                      </div>
                    </div>

                    <div className='col-sm-6 col-12 col-md-3'>
                      <div className='bg-warning small-box'>
                        <div className='inner'>
                          <h3>0 / 0</h3>

                          <p>EXPORTED RECEIVERS</p>
                        </div>
                        <div className='icon'>
                          <i
                            className='fa fa-assistive-listening-systems'
                            style={{fontSize: 70, color: ' rgba(0, 0, 0, .15)'}}
                          ></i>
                        </div>
                        <a className='small-box-footer'>
                          View All <i className='fa-arrow-circle-down fas'></i>
                        </a>
                      </div>
                    </div>

                    <div className='col-sm-6 col-12 col-md-3'>
                      <div className='bg-danger small-box'>
                        <div className='inner'>
                          <h3>0 / 0</h3>

                          <p>EXPORTED PROVIDERS</p>
                        </div>
                        <div className='icon'>
                          <i
                            className='fa fa-database'
                            style={{fontSize: 70, color: ' rgba(0, 0, 0, .15)'}}
                          ></i>
                        </div>
                        <a className='small-box-footer'>
                          View All <i className='fa-arrow-circle-down fas'></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id='scan_options' className='anchor'></a>
      <section className='content'>
        <div className='container-fluid'>
          <div className='gap-3 row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='d-flex flex-wrap gap-5 card-body'>
                  <p className='w-100'>
                    <strong>
                      <i className='fa-cog fas'></i> SCAN OPTIONS
                    </strong>
                  </p>
                  <p className='d-flex flex-wrap gap-3'>
                    {' '}
                    <a className='btn btn-info' role='button'>
                      <i className='fa fa-sync'></i> Rescan
                    </a>
                    <a
                      id='supbtn'
                      role='button'
                      className='btn btn-primary'
                      data-target='#sup_list'
                      data-toggle='modal'
                    >
                      <i className='fa fa-table'></i> Manage Suppressions
                    </a>
                    <a className='btn btn-success' role='button'>
                      <i className='fa fa-play-circle'></i> Start Dynamic Analysis
                    </a>
                    <button
                      type='button'
                      className='btn btn-warning'
                      data-toggle='modal'
                      data-target='.scan-logs-mdl'
                    >
                      <i className='fa fa-list'></i> Scan Logs
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='card'>
                <div className='d-flex flex-wrap gap-5 card-body'>
                  <p>
                    <strong>
                      <i className='fa-file-code fas'></i> DECOMPILED CODE
                    </strong>
                  </p>
                  <p className='d-flex flex-wrap gap-3'>
                    <a target='_blank' role='button' className='btn btn-primary'>
                      <i className='fa fa-eye'></i> View AndroidManifest.xml
                    </a>

                    <a className='btn btn-info' role='button'>
                      <i className='fa fa-code'></i> View Source
                    </a>

                    <a className='btn btn-info' role='button'>
                      <i className='fa fa-code'></i> View Smali
                    </a>

                    <a className='btn btn-warning' role='button'>
                      {' '}
                      <i className='fa fa-download'></i> Download Java Code
                    </a>

                    <a className='btn btn-warning'>
                      {' '}
                      <i className='fa fa-download'></i> Download Smali Code
                    </a>

                    <a className='btn btn-warning'>
                      {' '}
                      <i className='fa fa-download'></i> Download APK
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id='certificate' className='anchor'></a>
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 test'>
              <div className='card'>
                <div className='card-body'>
                  <p>
                    <strong>
                      <i className='fa fa-certificate'></i> SIGNER CERTIFICATE
                    </strong>
                  </p>

                  <pre>
                    <code className='text-light-emphasis'>
{`Binary is signed v1 signature: True v2 signature: False v3 signature: False v4
signature: False X.509 Subject: C=sm, ST=sm, L=sm, O=sm, OU=sm, CN=sm
Signature Algorithm: rsassa_pkcs1v15 Valid From: 2016-07-29 00:51:32+00:00
Valid To: 3015-11-30 00:51:32+00:00 Issuer: C=sm, ST=sm, L=sm, O=sm, OU=sm,
CN=sm Serial Number: 0x41c01f1d Hash Algorithm: sha256 md5:
a900d49f59b0c24906f9c5dda4f76233 sha1:
2b2a8a344805de7ad89b580b252b2c8a693f7bb9 sha256:
a992c828eb5c2fa2c144cb5854fc74102ba3bee36bbaf9837830dee96fdcdee8 sha512:
21d3542ad0fb52c98407e0337e53bdcd92123fe2378ee409ee27ca65f35ac4a1a055c4e6ca98c181fd94bcb4ee3887ab933ec8bc28cb68f425abf5a1782aa405
Found 1 unique certificates`}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id='permissions' className='anchor'></a>
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <p>
                    <strong>
                      <i className='fa fa-list'></i> APPLICATION PERMISSIONS
                    </strong>
                  </p>
                  <p></p>
                  <div className='table-responsive'>
                    <div
                      id='table_permissions_wrapper'
                      className='dataTables_wrapper dt-bootstrap4 no-footer'
                    >
                      <div className='top d-flex justify-content-between w-100'>
                        <div className='center-col'>
                          <div className='flex-wrap btn-group dt-buttons'>
                            {' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-copy buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-copy'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-csv buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa-file-csv fas'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-excel buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-file-excel'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-html5 buttons-pdf'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-file-pdf'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-print'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-print'></i>
                              </span>
                            </button>{' '}
                          </div>
                        </div>
                        <div className='right-col'>
                          <div id='table_permissions_filter' className='dataTables_filter'>
                            <label>
                              Search:
                              <input
                                type='search'
                                className='form-control form-control-sm'
                                placeholder=''
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <table
                        id='table_permissions'
                        className='table-bordered dataTable no-footer table table-hover table-striped'
                        aria-describedby='table_permissions_info'
                      >
                        <thead>
                          <tr>
                            <th className='sorting sorting_asc' style={{width: 188.922}}>
                              PERMISSION
                            </th>
                            <th className='sorting' style={{width: 53.1094}}>
                              STATUS
                            </th>
                            <th className='sorting' style={{width: 39.5625}}>
                              INFO{' '}
                            </th>
                            <th className='sorting' style={{width: 96.375}}>
                              DESCRIPTION
                            </th>
                            <th className='sorting' style={{width: 75}}>
                              CODE MAPPINGS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='odd'>
                            <td className='sorting_1'>android.permission.INTERNET</td>
                            <td>
                              <span className='bg-info text-white badge'>normal</span>
                            </td>
                            <td>full Internet access</td>
                            <td>Allows an application to create network sockets.</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        className='dataTables_info'
                        id='table_permissions_info'
                        role='status'
                        aria-live='polite'
                      >
                        Showing 1 to 1 of 1 entries
                      </div>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='table_permissions_paginate'
                      >
                        <ul className='justify-content-end pagination'>
                          <li
                            className='disabled page-item paginate_button previous'
                            id='table_permissions_previous'
                          >
                            <a
                              aria-disabled='true'
                              role='link'
                              data-dt-idx='previous'
                              className='page-link'
                            >
                              Previous
                            </a>
                          </li>
                          <li className='active page-item paginate_button'>
                            <a
                              role='link'
                              aria-current='page'
                              data-dt-idx='0'
                              className='page-link'
                            >
                              1
                            </a>
                          </li>
                          <li
                            className='disabled next page-item paginate_button'
                            id='table_permissions_next'
                          >
                            <a
                              aria-disabled='true'
                              role='link'
                              data-dt-idx='next'
                              className='page-link'
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <a id='android_api' className='anchor'></a>
      <section className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <p>
                    <strong>
                      <i className='fa-android fab'></i> ANDROID API
                    </strong>
                  </p>
                  <div className='table-responsive'>
                    <div
                      id='table_so_wrapper'
                      className='dataTables_wrapper dt-bootstrap4 no-footer'
                    >
                      <div className='top d-flex justify-content-between w-100'>
                        <div className='center-col'>
                          <div className='flex-wrap btn-group dt-buttons'>
                            {' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-copy buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-copy'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-csv buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa-file-csv fas'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-excel buttons-html5'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-file-excel'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-html5 buttons-pdf'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-file-pdf'></i>
                              </span>
                            </button>{' '}
                            <button
                              className='btn-sm-menu btn btn-default btn-secondary btn-xs buttons-print'
                              type='button'
                            >
                              <span>
                                <i className='fa fa-print'></i>
                              </span>
                            </button>{' '}
                          </div>
                        </div>
                        <div className='right-col'>
                          <div id='table_so_filter' className='dataTables_filter'>
                            <label>
                              Search:
                              <input
                                type='search'
                                className='form-control form-control-sm'
                                placeholder=''
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <table
                        id='table_so'
                        className='table-bordered dataTable no-footer table table-hover table-striped'
                        aria-describedby='table_so_info'
                      >
                        <thead>
                          <tr>
                            <th className='sorting sorting_asc' style={{width: 333.672}}>
                              API
                            </th>
                            <th className='sorting' style={{width: 109.328}}>
                              FILES
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='odd'>
                            <td className='sorting_1'>Inter Process Communication</td>
                            <td>
                              <a
                                className='btn btn-primary btn-xs'
                                data-toggle='collapse'
                                role='button'
                                aria-expanded='false'
                              >
                                Show Files
                              </a>
                              <div className='collapse' id='collapseapi1'>
                                <small>
                                  <a> com/sm/letterlink/MainActivity.java</a>
                                </small>
                                <br />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        className='dataTables_info'
                        id='table_so_info'
                        role='status'
                        aria-live='polite'
                      >
                        Showing 1 to 1 of 1 entries
                      </div>
                      <div
                        className='justify-content-end dataTables_paginate paging_simple_numbers'
                        id='table_so_paginate'
                      >
                        <ul className='justify-content-end pagination'>
                          <li
                            className='disabled page-item paginate_button previous'
                            id='table_so_previous'
                          >
                            <a
                              aria-disabled='true'
                              role='link'
                              data-dt-idx='previous'
                              className='page-link'
                            >
                              Previous
                            </a>
                          </li>
                          <li className='active page-item paginate_button'>
                            <a
                              role='link'
                              aria-current='page'
                              data-dt-idx='0'
                              className='page-link'
                            >
                              1
                            </a>
                          </li>
                          <li
                            className='disabled next page-item paginate_button'
                            id='table_so_next'
                          >
                            <a
                              aria-disabled='true'
                              role='link'
                              data-dt-idx='next'
                              className='page-link'
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
