import {ApexOptions} from 'apexcharts'
import {memo} from 'react'
import Chart from 'react-apexcharts'
import { useIntl } from 'react-intl'
interface Props {
  height?: number
  colors?: string[]
  data: number[]
  categories?: string[]
}
const ColumnChart = memo(
  ({
    height = 80,
    colors = ['#96bded'],
    data = [10, 20, 30, 15, 18, 5],
    categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
  }: Props) => {
    /**
     * Renders a column chart using ApexCharts.
     * 
     * @component
     * @param {number} [height=80] - The height of the chart.
     * @param {string[]} [colors=['#96bded']] - An array of colors for the chart bars.
     * @param {number[]} [data=[10, 20, 30, 15, 18, 5]] - The data points for the chart.
     * @param {string[]} [categories=['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']] - The categories for the x-axis.
     * 
     * @returns {JSX.Element} The rendered column chart component.
     */
    const intl = useIntl();
    const series = [
      {
        name: intl.formatMessage({ id: 'Vulnerabilities' }),
        data: data,
      },
    ];

    const options: ApexOptions = {
      colors: colors,
      chart: {
        height: '100%',
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '4px',
          borderRadius: 2,
        },
      },
      markers: {
        size: 2,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        theme: 'dark',
        style: {
          fontSize: '12px',
          fontFamily: 'unset',
        },
        fixed: {
          enabled: true,
          position: 'bottomRight',
          // offsetY: -10,
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        categories: categories,
      },
      yaxis: {
        show: false,
      },
    };

    return <Chart width={120} height={height} options={options} series={series} type='bar' />;
  }
);

export default ColumnChart;
