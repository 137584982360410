import React, {useState, useEffect, useLayoutEffect} from 'react'

import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'

import useErrorHandling from '../../../../Utils/useErrorHandling'
import {VUL_DETAILS_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {useDispatch} from 'react-redux'
import {SetAiAssistantVulnerabilitiesID} from '../../../modules/auth'
import {VulnerabilitiesResult } from '../../../modules/types/vulnerabilities'
import Markdown from 'react-markdown'

const VulnerabilityDetails = () => {
  const [loading, setLoading] = useState(false)
  const [vulnerabilityDetailData, setAssetVulnerabilityData] = useState<VulnerabilitiesResult>()
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const lang = useLang()?.lang.selectedLang
  const dispatch = useDispatch()
  const id = searchParams.get('id')
  const intl = useIntl()
  const navigate = useNavigate()
  /**
   * Generates the content for displaying the severity level of a vulnerability.
   * 
   * @param severity - The severity level of the vulnerability. Can be 'info', 'low', 'medium', 'high', 'critical', or undefined.
   * @returns A JSX element representing the severity level with appropriate styling and text.
   */
  const severityContent = (severity: string|undefined) => {
    switch (severity) {
      case 'info':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-iinfo me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Info Severity'})} </span>
          </>
        )
      case 'low':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ilow me-3`} />
            <span className='m-auto'> {intl.formatMessage({id: 'Low Severity'})} </span>
          </>
        )

      case 'medium':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-imedium me-1`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Medium Severity'})} </span>
          </>
        )

      case 'high':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ihigh me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'High Severity'})} </span>
          </>
        )

      case 'critical':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-icritical me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Critical Severity'})} </span>
          </>
        )

      default:
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-white me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'None'})}</span>
          </>
        )
    }
  }

  /**
   * Fetches vulnerability detail data from the API.
   * 
   * This function sends a GET request to the API endpoint to retrieve vulnerability details.
   * It handles the loading state, error handling, and updates the component state with the fetched data.
   * 
   * @returns {Promise<void>}
   */
  const fetchVulnerabilityDetailData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setAssetVulnerabilityData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  /**
   * Effect hook to fetch vulnerability details or set demo data.
   * 
   * This hook runs after the component layout is painted. It checks if the app is in demo mode
   * and sets demo data, or fetches real data from the API.
   * 
   * @effect
   * @dependency searchParams - The hook re-runs when searchParams change.
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODE === 'demo') {
      setAssetVulnerabilityData(VUL_DETAILS_DATA)
    } else {
      fetchVulnerabilityDetailData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])


  return (
    <>
      <div className='w-100'>
        <div className={`card card-body h-100 bg-body ${loading && 'skeleton'}`}>
          <div
            className='d-flex flex-row justify-content-between align-items-start pt-2 h-50px'
            style={!vulnerabilityDetailData ? {filter: 'blur(3px)'} : {}}
          >
            <h3 className='w-50 text_hide_width'>
              {lang === 'fa'
                ? vulnerabilityDetailData?.title_fa || 'منتظر داده باشید'
                : vulnerabilityDetailData?.title}
            </h3>
            <div className='d-flex gap-3'>
              <button
                onClick={() => {
                  dispatch(
                    SetAiAssistantVulnerabilitiesID({id: id, title: vulnerabilityDetailData?.title})
                  )
                }}
                id='kt_drawer_chat_toggle'
                className='btn btn-primary'
                type='button'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_edit'
              >
                {intl.formatMessage({id: 'Send to AI Assisstant'})}
              </button>
              <button className='btn btn-success' onClick={()=>{navigate('/ticket')}}>
                {intl.formatMessage({id: 'Check With Expert'})}
              </button>
            </div>
          </div>

          <div
            className='d-flex align-items-center bg-secondary my-5 px-3 py-1 rounded-3 mw-200px'
            style={!vulnerabilityDetailData ? {filter: 'blur(3px)'} : {}}
          >
            {severityContent(vulnerabilityDetailData?.severity)}
          </div>
          <div
            className='d-flex justify-content-between align-items-center m-0 w-100 pe-3'
            style={!vulnerabilityDetailData ? {filter: 'blur(3px)'} : {}}
          >
            <div className='d-flex justify-content-between align-items-center mw-700px w-75'>

              <div className='d-flex flex-column justify-content-start'>
                <div className='p-1 w-200px h-auto text-gray-700'>
                  {intl.formatMessage({id: 'Target'})}{' '}
                </div>
                <div className='d-flex align-items-center gap-5 mt-1 p-1 w-200px h-auto text-gray-800'>
                  {/* <div
                    className={`w-30px h-30px bg-gray-500 text-white rounded-1 d-flex justify-content-center align-items-center `}
                  >
                    {vulnerabilityDetailData?.tag}
                  </div> */}
                  {vulnerabilityDetailData?.target}
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start'>
                <div className='p-1 min-w-200px h-auto text-gray-700'>
                  {intl.formatMessage({id: 'AFFECTED ENTITY'})}
                </div>
                <div className='mt-2 p-1 text-gray-900'>{vulnerabilityDetailData?.host}</div>
              </div>
              {vulnerabilityDetailData?.port&&<div className='d-flex flex-column justify-content-start'>
                <div className='p-1 h-auto text-gray-700'>
                  {intl.formatMessage({id: 'Port'})}{' '}
                </div>
                <div className='d-flex align-items-center mt-2 p-1 w-100px h-auto text-primary'>
                  {vulnerabilityDetailData?.port}
                </div>
              </div>}
            </div>

            <div className='d-flex flex-column justify-content-start'>
              <div className='p-1 w-150px h-auto text-gray-700'>
                {' '}
                {intl.formatMessage({id: 'Last Seen'})}{' '}
              </div>
              <span className='mt-2 p-1 w-150px h-auto text-gray-900'>
                <ConvertDate
                  date={vulnerabilityDetailData?.timestamp||''}
                  jalali={vulnerabilityDetailData?.timestamp_jalali||''}
                />
              </span>
            </div>
          </div>

          <div
            className={`w-100 h-100 pe-3 m-0 mt-5`}
            style={!vulnerabilityDetailData ? {filter: 'blur(3px)'} : {}}
          >
            <ul className='mb-5 fs-6 nav nav-line-tabs nav-tabs'>
              <li className='nav-item'>
                <a className='active nav-link' data-bs-toggle='tab' href={`#desc_tab`}>
                  {intl.formatMessage({id: 'Description'})}
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#remediation_tab`}>
                  {intl.formatMessage({id: 'Mitigation'})}
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href={`#ref_tab`}>
                  {intl.formatMessage({id: 'References'})}
                </a>
              </li>

              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#poc'>
                  {intl.formatMessage({id: 'PoC'})}
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#insight_tab'>
                  {intl.formatMessage({id: 'Ai Insight'})}
                </a>
              </li>
            </ul>

            <div className='bg-mh_black p-3 h-250px overflow-auto tab-content' id='myTabContent'>
              <div className='active fade show tab-pane' id={`desc_tab`} role='tabpanel'>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === 'fa'
                        ? vulnerabilityDetailData?.description_fa || ' '
                        : vulnerabilityDetailData?.description||'',
                  }}
                />
              </div>
              <div className='fade tab-pane' id={`insight_tab`} role='tabpanel'>
                <div>
                  <Markdown>{vulnerabilityDetailData?.ai_insight}</Markdown>
                </div>
              </div>
              <div className='fade tab-pane' id={`remediation_tab`} role='tabpanel'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: vulnerabilityDetailData?.remediation||'',
                  }}
                ></div>
              </div>

              <div className='fade tab-pane' id={`ref_tab`} role='tabpanel'>
                {vulnerabilityDetailData?.references?.map((reference) => (
                  <div key={reference} className='bg-secondary mb-2 p-2 rounded-2 col-6'>
                    {reference}
                  </div>
                ))}
              </div>

              <div className='fade tab-pane' id={`poc`} role='tabpanel'>
                {vulnerabilityDetailData?.poc &&
                  Object.entries(vulnerabilityDetailData.poc)?.map((item, ind) => (
                    // <div key={ind} id={item[0]} className='d-flex flex-column p-2'>
                    //   <div className='fs-4'>{item[0]}</div>
                    //   {`${item[1]}`}
                    // </div>
                    <div className='d-flex flex-column p-2'>
                      <div
                        key={ind}
                        id={item[0]}
                        dangerouslySetInnerHTML={{
                          __html: item[0],
                        }}
                      />

                      <div
                        className='fs-4'
                        dangerouslySetInnerHTML={{
                          __html: item[1] as string,
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VulnerabilityDetails
