import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import Warning2 from '../images/Warning2';
import { useLang } from '../../../_metronic/i18n/Metronici18n';
import { ServiceType } from '../../modules/types/service';


interface Props {
  props: ServiceType | null;
  setProps: React.Dispatch<React.SetStateAction<ServiceType | null>>;
}

const EditModal = memo(({ setProps, props }: Props) => {
  const intl = useIntl();
  const lang = useLang()?.lang.selectedLang;

  function clearForm() {
    setProps(null);
  }

  return (
    <div
      className="modal fade"
      id="kt_modal_service_details"
      tabIndex={-1}
      aria-labelledby="serviceDetailsModalLabel"
      aria-hidden="true"
      style={{ backdropFilter: 'blur(3px)' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        clearForm();
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ minWidth: '40%', maxWidth: '600px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content border-0 shadow-lg">
          {/* Modal Header */}
          <div className="modal-header border-bottom-0 py-3">
            {props && (
              <h5 className="modal-title visually-hidden" id="serviceDetailsModalLabel">
                {lang === 'fa' ? props.name_fa : props.name}
              </h5>
            )}
            <button
              type="button"
              className="btn-close btn-close-white position-absolute  end-0  me-3 "
              style={{top:15}}
              data-bs-dismiss="modal"
              // aria-label={intl.formatMessage({ id: 'Close' })}
              onClick={clearForm}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2 image_change_theme2"
              />
            </button>
          </div>

          {/* Modal Body */}
          <div className="modal-body px-4 py-4">
            {props && (
              <div className="service-details">
                {/* Service Header */}
                <div className="service-header d-flex align-items-center gap-3 mb-4">
                  <div className="service-icon bg-primary rounded-3 p-3 d-flex align-items-center justify-content-center">
                    <KTIcon
                      iconName={props.icon_name}
                      className="text-white"
                      style={{ fontSize: '35px' }}
                    />
                  </div>
                  <h3 className="service-title mb-0 h4">
                    <strong>{lang === 'fa' ? props.name_fa : props.name}</strong>
                  </h3>
                </div>

                {/* Service Description */}
                <div className="service-description card border bg-light mb-4">
                  <div className="card-body p-4">
                    <div className="d-flex gap-3">
                    <Warning2 className=" image_change_theme2 flex-shrink-0" svgClassName="service-warning-icon" />
                      <div className="description-text">
                        {lang === 'fa' ? props.description_fa : props.description}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="service-actions d-flex gap-3 justify-content-center">
                  <button
                    className="btn btn-outline-secondary px-4"
                    data-bs-dismiss="modal"
                    onClick={clearForm}
                  >
                    <i className="fas fa-check me-2"></i>
                    {intl.formatMessage({ id: 'I Understand' })}
                  </button>
                  <button className="btn btn-primary px-4">
                    <i className="fas fa-book me-2"></i>
                    {intl.formatMessage({ id: 'Learn More' })}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditModal;