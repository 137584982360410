import React, {useEffect, useLayoutEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import {FilterSelectedPort, SearchPort, SetDataPorts, SortPort} from '../../../modules/auth'
import PageLoading from '../../loading/PageLoading'
import Check from '../../images/Ckeck'
import Close from '../../images/Close'
import useGetFilterOptionsPort from '../../../../Utils/useGetFilterOptionsPort'
import SearchAndSort from '../../components/SearchAndSort'
import usePermission from '../../../../Utils/usePermission'
import {SelectedType} from '../../../modules/types/filters'

const SortOptions: any[] = []

/**
 * Ports component for displaying and managing port data
 * @returns {JSX.Element} Rendered Ports component
 */
export default function Ports () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const portData = useSelector((state: RootState) => state?.root?.data?.dataPorts)
  const dispatch = useDispatch()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const sort = useSelector((state: RootState) => state.root.filterOption.port)
  const search = useSelector((state: RootState) => state.root.filterOption.port.search)
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.port.selected)
  const getFilterOptionsPort = useGetFilterOptionsPort()

  const filterOptionPorts = useSelector(
    (state: RootState) => state?.root?.filterOption?.port.options
  )

  /**
   * Creates the sort props string for the API request
   * @param {string} sortProps - Sort properties
   * @returns {string} Formatted sort string
   */
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }

  /**
   * Creates the search props string for the API request
   * @returns {string} Formatted search string
   */
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }

  /**
   * Handles page change in pagination
   * @param {Object} event - Page change event
   * @param {number} event.selected - Selected page number
   */
  const handlePageClick = (event: {selected: number}) => {
    fetchPortsData({query: `?page=${event.selected + 1}`})
  }

  /**
   * Handles selection of filter options
   * @param {string} items - Filter category
   * @param {string} item - Selected filter item
   */
  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(FilterSelectedPort(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchPortsData({query: '?page=1', filterProps: copy})
    }
  }

  /**
   * Creates the filter props string for the API request
   * @param {SelectedType | null} props - Filter properties
   * @returns {string} Formatted filter string
   */
  function createPropsFilter (props?: null | SelectedType) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected || {})
      const text = keys.map((key) => {
        if (selected?.[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }

  /**
   * Fetches ports data from the API
   * @param {Object} options - Fetch options
   * @param {string} options.query - Query string
   * @param {SelectedType | null} options.filterProps - Filter properties
   * @param {string} options.sort - Sort string
   */
  const fetchPortsData = async ({
    query = '?page=1',
    filterProps = null,
    sort = '',
  }: {
    query?: string
    filterProps?: null | SelectedType
    sort?: string
  }) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/port/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPorts(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/port/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (portData?.expireTime && portData.expireTime >= nowDate) {
    } else {
      fetchPortsData({})
    }
    if (!filterOptionPorts && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsPort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div
        className={`w-100  p-4 bg-body overflow-hidden position-relative ${loading && 'skeleton'}`}
        style={{
          maxWidth: 'calc(100% - 100px)',
          filter: !portData?.data?.results.length ? 'blur(3px)' : '',
        }}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'Ports'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          {/* Filter options sidebar */}
          <div className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '>
            {filterOptionPorts ? (
              Object.keys(filterOptionPorts)?.map((items: string, index: number) => {
                return (
                  <div
                    key={index}
                    className='d-flex flex-column'
                  >
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {filterOptionPorts[items]?.length ? (
                      filterOptionPorts[items]
                        .slice(
                          0,
                          // Determine how many items to show based on the 'open' state
                          filterOptionPorts[items]?.length > 10
                            ? open === index
                              ? filterOptionPorts[items]?.length - 1
                              : 10
                            : filterOptionPorts[items]?.length
                        )
                        .map((item, ind: number) => (
                          <div key={ind}>
                            <hr className='my-2 mx-0' />
                            <div
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                selectedHandel(items, item.name)
                              }}
                              className='form-check form-check-custom form-check-solid form-check-sm  p-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={
                                 selected?.[items]?.includes(item.name)
                                }
                              />
                              <div className='form-check-label w-100 d-flex justify-content-between mx-2'>
                                <span>{ item.name}</span>
                                <span>{item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {/* Show 'show more' or 'show less' button if there are more than 10 items */}
                    {filterOptionPorts[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>
          {/* Main content area */}
          <div className=' w-75 ps-3'>
            <SearchAndSort
              className={'mb-5'}
              search={search}
              setSearch={SearchPort}
              sort={sort}
              setSort={SortPort}
              getData={fetchPortsData}
              sortOption={SortOptions}
              searchOption={Object.keys(filterOptionPorts || {})}
            />
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>
                {/* Table header */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-25 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Port Number'})}</span>
                      </div>
                    </div>
                    <div className='w-50 p-1 h-auto d-flex justify-content-start'>
                      <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Domain'})}</span>
                      </div>
                    </div>
                    <div className='w-25 d-flex justify-content-start p-1 ms-2 h-auto'>
                      {intl.formatMessage({id: 'Protocol'})}
                    </div>
                    <div className='w-80px p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      {intl.formatMessage({id: 'Status'})}
                    </div>
                  </div>
                </div>
                {/* Table body */}
                {portData?.data?.results?.map((item, index: number) => (
                  <div
                    key={index}
                    className={`w-100 m-0 py-2 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center mb-3`}
                  >
                    <div className='w-100 d-flex p-2 pt-1 pb-1  align-items-center'>
                      <div className='w-25 p-1   h-auto'> {item.port} </div>
                      <div className='w-50 p-1 h-auto d-flex justify-content-start'>
                        {item.host_domain}
                      </div>
                      <div className='w-25 d-flex justify-content-start align-items-center p-1 h-auto text-uppercase'>
                        {item.protocol}
                      </div>
                      <div className='w-80px p-1  h-auto d-flex justify-content-center pe-3'>
                        {item.state === 'open' ? (
                          <Check className=' svg-icon-2x svg-icon-success ' />
                        ) : (
                          <Close className='  svg-icon-2x  svg-icon-danger ' />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Pagination */}
              <div className='d-flex justify-content-end bg-body mt-5 pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={portData?.data?.total_pages || 1}
                  forcePage={portData?.data?.current_page ? portData?.data?.current_page - 1 : 0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}