import React, {lazy, Suspense, useState} from 'react'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import {RootState} from '../../../../setup/redux/Store'
import {useSelector} from 'react-redux'

/** 
 * Default dataset when no data is available 
 * @constant
 */
const DefaultData = {
  x: ['start', '2023-06-17'],
  y: {
    critical: [0, 60],
    high: [0, 55],
    info: [0, 69],
    low: [0, 65],
    medium: [0, 50],
  },
}

/** 
 * Empty dataset for scenarios with no data 
 * @constant
 */
const NoData = {
  x: ['', ''],
  y: {
    critical: [0, 0],
    high: [0, 0],
    info: [0, 0],
    low: [0, 0],
    medium: [0, 0],
  },
}

/**
 * Interface defining the structure of chart data
 * @interface
 */
interface dataProp {
  x: string[]
  y: {
    critical: number[]
    high: number[]
    info: number[]
    low: number[]
    medium: number[]
  }
}

/**
 * MainChart component renders a vulnerability chart with severity filters
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.title=''] - Chart title
 * @param {dataProp} [props.data] - Chart data
 * @param {boolean} [props.loading=false] - Loading state of the chart
 */
const MainChart = ({
  title = '',
  data,
  loading,
}: {
  data: dataProp | undefined
  title: string
  loading: boolean
}) => {
  // Lazy load the ChartsWidget8 component for performance optimization
  const ChartsWidget8 = lazy(
    () => import('../../../../_metronic/partials/widgets/charts/ChartsWidget8')
  )

  // State to manage visibility of different severity levels
  const [severityValue, setSeverityValue] = useState({
    info: true,
    low: true,
    medium: true,
    high: true,
    critical: true,
  })

  // Initialize internationalization
  const intl = useIntl()

  // Retrieve user data from Redux store
  const user = useSelector((state: RootState) => state.root.user)

  /**
   * Toggles visibility of severity level in chart
   * 
   * @param {string} severity - Severity level to toggle
   */
  const toggleSeverity = (severity: keyof typeof severityValue) => {
    // Create a copy of current severity state
    const copy = {...severityValue}
    
    // Toggle the specific severity level
    copy[severity] = !copy[severity]
    
    // Update state with new configuration
    setSeverityValue(copy)
  }

  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body bg-body ${loading && 'skeleton'}`}>
        {/* Chart title and info tooltip */}
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column pt-2'>
            {intl.formatMessage({id: title})}
          </h3>
          <span
            data-tooltip-id={'my-tooltip' + title}
            data-tooltip-content={intl.formatMessage({
              id: 'Vulnerabilities Based on Severity tooltip',
            })}
          >
            <InfoIcon />
          </span>
          <ReactTooltip
            id={'my-tooltip' + title}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </div>

        {/* Conditionally apply blur effect */}
        <div style={!data?.x?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}>
          <Suspense fallback={<div className=' h-300px'></div>}>
            <ChartsWidget8
              // Dynamically select data based on availability
              chartData={data?.x?.length ? data : !user?.is_user_scan ? DefaultData : NoData}
              severityValue={severityValue}
              className=''
            />
          </Suspense>
        </div>

        {/* Severity filter buttons */}
        <div className={`row d-flex flex-row`}>
          {/* Dynamically generate severity filter buttons */}
          {Object.keys(severityValue).map((severity) => (
            <button
              key={severity}
              value={severity}
              onClick={() => toggleSeverity(severity as keyof typeof severityValue)}
              style={{border: `${severityValue[severity as keyof typeof severityValue] && '2px solid'}`}}
              className={`w-auto h-auto ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
                severityValue[severity as keyof typeof severityValue] 
                  ? 'border border-1 border-gray-900' 
                  : 'border border-1'
              }`}
            >
              <div className={`w-15px h-15px me-2 rounded-3 border-i${severity} border border-2`} />
              {intl.formatMessage({id: severity})}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MainChart