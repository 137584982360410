import React, {useState, useEffect, useContext, useLayoutEffect, Suspense, lazy} from 'react'
import axios from 'axios'
import ScanTable from './components/ScanTable'
// import ReactPaginate from 'react-paginate'
// import DAG from './components/DAG'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {ChangeUserStatus, SetDataScan, SetDataScanDAG} from '../../modules/auth'
// import ReactPaginate from 'react-paginate'
import {ColumnChart} from './components/ColumnChart'
import {LineChart} from './components/LineChart'
import Arr001 from '../components/Arr001'
import {TaskScan} from './components/TaskScan'
import {useIntl} from 'react-intl'
import {I18nContext} from '../../../_metronic/i18n/Metronici18n'
import Joyride, {CallBackProps} from 'react-joyride'
import usePermission from '../../../Utils/usePermission'
import {Button} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
/**
 * Defines the steps for a guided tour of the service page features.
 * Each step targets a specific element and provides a description in Persian.
 *
 * @constant
 * @type {Array<{target: string, content: string}>}
 * @property {string} target - The CSS selector for the element to highlight.
 * @property {string} content - The description of the feature in Persian.
 */
const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
]

/**
 * Represents the data structure for a chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values.
 *
 * @typedef {Object} ChartData
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {number[]} y - An array of numeric values corresponding to each x-axis label.
 */
const chartData = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13'],
  y: [
    77, 105, 87, 106, 11, 108, 63, 100, 66, 107, 88, 117, 86, 111, 88, 163, 60, 166, 77, 118, 87,
    186, 11, 118, 63, 110, 66, 117, 88, 117, 86, 111, 88, 113, 60, 116,
  ],
}

/**
 * Represents the data structure for a multi-series chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values for multiple series.
 *
 * @typedef {Object} MultiSeriesChartData
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {Array<{name: string, data: number[]}>} y - An array of series objects, each containing:
 *   @property {string} name - The name of the series.
 *   @property {number[]} data - An array of numeric values corresponding to each x-axis label for this series.
 */
const leftChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
    {
      name: 'string2',
      data: [3, 8, 11, 18, 25],
    },
  ],
}

/**
 * Represents the data structure for a single-series chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values for one series.
 *
 * @type {Object}
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {Array<{name: string, data: number[]}>} y - An array containing a single series object:
 *   @property {string} name - The name of the series.
 *   @property {number[]} data - An array of numeric values corresponding to each x-axis label for this series.
 */
const rightChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
  ],
}

export const Scan = () => {
  const DAG = lazy(() => import('./components/DAG'))
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const lang = useContext(I18nContext)?.lang.dir
  const [loading, setLoading] = useState(false)
  const [idSelected, setIdSelected] = useState<null |string>(null)
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const scanData = useSelector((state: RootState) => state?.root?.data?.dataScan)
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  /**
   * Asynchronously changes the user's status from new to existing.
   *
   * This function sends a PATCH request to update the user's status on the server.
   * It handles the response, updates the Redux store, and manages loading state.
   *
   * @async
   * @function changeUserStatus
   * @throws {Error} Throws an error if the API request fails.
   * @returns {Promise<void>} A promise that resolves when the status change is complete.
   */
  const changeUserStatus = async () => {
    try {
      const data = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/set_new/`,
        {}
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(ChangeUserStatus(false))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/auth/users/set_new/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /**
   * Handles the callback from the Joyride tour component.
   * This function is called when the tour status changes, specifically when it's finished or skipped.
   * It updates the user status and stops the tour.
   *
   * @param {CallBackProps} data - The callback data from Joyride
   * @param {string} data.status - The current status of the Joyride tour
   */
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    if (status === 'finished' || status === 'skipped') {
      // changeUserStatus()
      dispatch(ChangeUserStatus(false))
      setRun(false)
    }
  }

  const handlePageClick = (event: {selected: number}) => {
    fetchScanData(event.selected + 1)
  }

  /**
   * Fetches scan data from the server.
   *
   * This asynchronous function retrieves scan data from the API, handles errors,
   * checks permissions, and updates the application state with the fetched data.
   * It also manages the loading state during the API call.
   *
   * @param {number} [page=1] - The page number of the scan data to fetch. Defaults to 1.
   * @returns {Promise<void>} A promise that resolves when the data fetching is complete.
   * @throws Will throw an error if the API request fails.
   */
  const fetchScanData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page}`)
      errorHandling(data.status)
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScan(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /**
   * Fetches a scan report from the server and triggers its download as an HTML file.
   *
   * This function sends a GET request to retrieve the scan report, handles the response,
   * and initiates the download of the report as an HTML file. It also manages loading state
   * and performs error handling.
   *
   * @param {string} id - The unique identifier of the scan report to fetch.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   * @throws Will throw an error if the API request fails.
   */
  const fetchScanReport = async (id: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/report/${
          lang === 'rtl' ? '?language=fa' : ''
        }`
      )
      errorHandling(data.status)
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        const htmlContent = [`${data.data}`]
        const bl = new Blob(htmlContent, {type: 'text/html'})
        const a = document.createElement('a')
        a.href = URL.createObjectURL(bl)
        a.download = 'Report.html'
        a.hidden = true
        document.body.appendChild(a)
        a.click()
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/ \n`, data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /**
   * A layout effect hook that initializes the guided tour for new users and fetches scan data if necessary.
   *
   * This hook performs two main tasks:
   * 1. Starts the guided tour if the user is new.
   * 2. Fetches scan data if it's not available or has expired.
   *
   * @effect
   * @param {boolean} isUserNew - Indicates whether the user is new.
   * @param {Object} scanData - The current scan data object.
   * @param {number} scanData.expireTime - The expiration timestamp of the scan data.
   * @param {Array} scanData.data - The actual scan data.
   * @param {Function} setRun - Function to set the run state of the guided tour.
   * @param {Function} setInd - Function to set the index of the guided tour steps.
   * @param {Function} fetchScanData - Function to fetch new scan data from the server.
   * @returns {void}
   */
  useLayoutEffect(() => {
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }
    const nowDate = new Date().getTime()
    if (scanData?.expireTime && scanData.expireTime >= nowDate && scanData?.data) {
    } else {
      fetchScanData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <>
        <div className='position-relative d-flex gap-5 w-100'>
          {/*
           * Checks and renders the user's permission status.
           *
           * This function invokes the isPermission method, which is likely to check
           * the current user's permissions and render appropriate UI elements or
           * perform actions based on those permissions.
           *
           * @returns {React.ReactNode} The rendered permission-based content or null.
           */}
          {isPermission()}

          <div className='w-50 card' style={{minWidth: 540}}>
            <div className='service-feature-one'>
              <div className='p-3 pt-5 h-300px'>
                <div className='d-flex justify-content-between w-100 h-30px'>
                  <h3>{intl.formatMessage({id: 'Total Scans'})}</h3>
                  <div
                    className='d-flex align-items-center gap-3'
                    // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                    style={{filter: 'blur(3px)'}}
                  >
                    <div className='d-flex justify-content-center align-items-center gap-1 bg-gray-300 p-2 w-40px h-30px'>
                      <div
                        className='rounded-pill w-8px h-8px'
                        style={{backgroundColor: '#F15B46'}}
                      ></div>
                      <span>18</span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center gap-1 bg-gray-300 p-2 w-40px h-30px'>
                      <div
                        className='rounded-pill w-8px h-8px'
                        style={{backgroundColor: '#02694a'}}
                      ></div>
                      <span>18</span>
                    </div>
                  </div>
                </div>
                <div
                  style={
                    // !!!scanData.data?.length
                    //   ? {filter: 'blur(3px)', height: '100%'}
                    //   : {height: '100%'}
                    {filter: 'blur(3px)', height: '100%'}
                  }
                >
                  {/*
                   * Renders a column chart component using the provided chart data.
                   *
                   * @param {Object} props - The properties passed to the ColumnChart component.
                   * @param {ChartData} props.chartData - The data to be displayed in the column chart.
                   *                                      It should conform to the ChartData type, which includes
                   *                                      x-axis labels and corresponding y-axis values.
                   * @returns {JSX.Element} A rendered ColumnChart component displaying the provided data.
                   */}
                  <ColumnChart chartData={chartData} />
                </div>
              </div>
            </div>
            <hr className='mx-5' />
            <div className='h-100 service-feature-three'>
              {/*
               * Renders a table component displaying scan data with interactive features.
               *
               * @param {Object} props - The properties passed to the ScanTable component.
               * @param {boolean} props.getLoading - Indicates whether data is currently being loaded.
               * @param {function} props.setIdSelected - Function to set the ID of the selected scan.
               * @param {number | null} props.idSelected - The currently selected scan ID.
               * @param {Array<Object> | undefined} props.scanData - An array of scan data objects to be displayed in the table.
               * @param {function} props.getData - Function to fetch scan data, typically used for refreshing the table.
               * @returns {JSX.Element} A rendered ScanTable component.
               */}
              <ScanTable
                getLoading={loading}
                setIdSelected={setIdSelected}
                idSelected={idSelected}
                scanData={scanData?.data?.results}
                getData={fetchScanData}
              />
            </div>
            <div className='d-flex justify-content-end bg-body pb-4 rounded-bottom pe-4'>
              <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={scanData?.data?.total_pages || 1}
              forcePage={(scanData?.data?.current_page||1) - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
            </div>
          </div>
          <div className='w-50' style={{minWidth: 540}}>
            <div className='service-feature-two'>
              <div
                className='position-relative w-100 h-200px inner_box'
                style={idSelected !== null ? {transform: 'rotateX(180deg)'} : {}}
              >
                <div className='d-flex gap-5 w-100 front_card'>
                  <div className='py-5 p-3 w-50 card' style={{height: 'fit-content'}}>
                    <h2>{intl.formatMessage({id: 'Task Runs'})}</h2>
                    <div
                      className='d-flex align-items-center gap-5 mt-2 h-100px'
                      // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                      // style={{filter: 'blur(3px)'}}
                    >
                      <p
                        style={{
                          fontSize: 45,
                          margin: 0,
                          height: 'fit-content',
                          minWidth: 123,
                          textAlign: 'center',
                        }}
                      >
                        23
                      </p>
                      {/*
                       * Renders a line chart component using the provided chart data.
                       *
                       * @param {Object} props - The properties passed to the LineChart component.
                       * @param {MultiSeriesChartData} props.chartData - The data to be displayed in the line chart.
                       *                                                 It should conform to the MultiSeriesChartData type, which includes
                       *                                                 x-axis labels and corresponding y-axis values for multiple series.
                       * @returns {JSX.Element} A rendered LineChart component displaying the provided data.
                       */}
                      <LineChart chartData={leftChart} />
                    </div>
                  </div>
                  <div className='py-5 p-3 w-50 card' style={{height: 'fit-content'}}>
                    <h2>{intl.formatMessage({id: 'Average run time'})}</h2>
                    <div
                      className='d-flex align-items-center gap-5 mt-2 h-100px'
                      // style={{filter: 'blur(3px)'}}
                    >
                      <p
                        style={{
                          fontSize: 45,
                          margin: 0,
                          height: 'fit-content',
                          textWrap: 'nowrap',
                          minWidth: 123,
                          textAlign: 'center',
                        }}
                      >
                        32min
                      </p>
                      {/*
                       * Renders a line chart component using the provided chart data.
                       *
                       * @param {Object} props - The properties passed to the LineChart component.
                       * @param {MultiSeriesChartData} props.chartData - The data to be displayed in the line chart.
                       *                                                 It should conform to the MultiSeriesChartData type, which includes
                       *                                                 x-axis labels and corresponding y-axis values for multiple series.
                       * @returns {JSX.Element} A rendered LineChart component displaying the provided data.
                       */}
                      <LineChart chartData={rightChart} />
                    </div>
                  </div>
                </div>
                <div
                  className='position-relative w-100 h-200px back_card card'
                  // style={{filter: 'blur(3px)'}}
                >
                  <button
                    className={`col-auto border border-0   p-0 m-0 position-absolute `}
                    style={
                      lang && lang === 'rtl'
                        ? {rotate: '180deg', background: 'unset', top: 10, right: 10, zIndex: 100}
                        : {rotate: '180deg', background: 'unset', top: 10, left: 10, zIndex: 100}
                    }
                    onClick={() => {
                      setIdSelected(null)
                    }}
                  >
                    <Arr001 fill={'#fff'} className={'image_change_theme'} />
                  </button>
                  {/*
                   * Renders the DAG (Directed Acyclic Graph) component with Suspense for lazy loading.
                   *
                   * This component wraps the DAG in a Suspense boundary to enable lazy loading,
                   * which can improve initial load time by deferring the loading of the DAG
                   * until it's needed. The fallback is an empty fragment, showing nothing
                   * while the DAG is being loaded.
                   *
                   * @returns {JSX.Element} The DAG component wrapped in a Suspense boundary.
                   */}
                  <Suspense fallback={<></>}>
                    <DAG  id={idSelected} />
                  </Suspense>
                </div>
              </div>
            </div>
            <div className='mt-5 w-100 card'>
              <div className='d-flex flex-row justify-content-between align-items-center p-5 w-100'>
                <h3 className=' '>{intl.formatMessage({id: 'Vulnerabilities'})}</h3>
                {idSelected !== null && (
                  <Button
                    size='sm'
                    variant='info'
                    onClick={() => {
                        fetchScanReport(idSelected)
                    }}
                  >
                    {intl.formatMessage({id: 'Receive Report'})}
                  </Button>
                )}
              </div>
              {idSelected !== null ? (
                <>
                  {/*
                   * Renders a TaskScan component to display vulnerability data for a selected scan.
                   *
                   * @param {Object} props - The properties passed to the TaskScan component.
                   * @param {Array<Object>} [props.vulnerabilityData] - An array of vulnerability objects for the selected scan.
                   *                                                    Each object represents a specific vulnerability with its details.
                   * @param {string | number} [props.id] - The unique identifier of the selected scan.
                   *                                       This is used to fetch or identify specific scan data.
                   * @returns {JSX.Element} A rendered TaskScan component displaying vulnerability information for the selected scan.
                   */}
                  <TaskScan
                    id={idSelected}
                  />
                </>
              ) : (
                <div className='d-flex justify-content-center align-items-center gap-5 h-400px'>
                  <Arr001
                    fill={'#fff'}
                    className={'image_change_theme '}
                    style={{rotate: '180deg'}}
                  />
                  {!scanData.data?.results.length ? (
                    <h2>{intl.formatMessage({id: 'Please Create a scan first'})}</h2>
                  ) : (
                    <h2>{intl.formatMessage({id: 'Please select scan'})}</h2>
                  )}
                </div>
              )}
              {/* <EmptyPage/> */}
            </div>
          </div>
        </div>
        {/* Joyride Component */}
        {/*
         * Renders a Joyride component for creating an interactive guided tour of the application.
         * This component provides step-by-step instructions to users, highlighting different parts of the UI.
         *
         * @param {Object} props - The properties passed to the Joyride component.
         * @param {number | undefined} props.stepIndex - The index of the current step in the tour.
         * @param {Array<Object>} props.steps - An array of step objects defining each step of the tour.
         * @param {boolean} props.run - Determines whether the tour should start running.
         * @param {boolean} props.disableOverlay - If true, disables the overlay that dims the rest of the page.
         * @param {boolean} props.disableScrolling - If true, prevents automatic scrolling to steps.
         * @param {boolean} props.showProgress - If true, displays a progress indicator.
         * @param {boolean} props.disableOverlayClose - If true, prevents closing the tour by clicking on the overlay.
         * @param {boolean} props.continuous - If true, allows the tour to progress continuously without user interaction.
         * @param {boolean} props.showSkipButton - If true, displays a button to skip the tour.
         * @param {Function} props.callback - A function to be called at various points during the tour.
         * @param {Object} props.locale - An object containing localized strings for 'next' and 'skip' buttons.
         * @param {number} props.spotlightPadding - The padding around the spotlight area.
         * @param {Object} props.styles - Custom styles for the Joyride component.
         * @returns {JSX.Element} A Joyride component configured for the application's guided tour.
         */}
        <Joyride
          stepIndex={ind}
          steps={steps}
          run={run}
          disableOverlay={false}
          disableScrolling={true}
          showProgress={true}
          disableOverlayClose={true}
          continuous={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          locale={{next: 'بعدی', skip: 'رد کردن'}}
          spotlightPadding={5}
          styles={{
            options: {
              zIndex: 10000, // Ensure Joyride is above other elements
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay on the whole page
            },
          }}
        />
      </>
    </>
  )
}

