import React from 'react'
import DonutChart from './DonutChart'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store'

/** Default data array used when no data is available */
const DefaultData = [1, 1, 1, 1, 1]

/**
 * IssueByStatus component displays a donut chart representing assets by grade
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {number[]} [props.data] - Array of grade data points
 * @param {boolean} [props.loading=false] - Loading state of the component
 */
const IssueByStatus = ({data , loading}: {data: number[]|undefined , loading:boolean}) => {
  // Initialize internationalization
  const intl = useIntl()

  // Retrieve user data from Redux store
  const user = useSelector((state:RootState) => state.root.user)

  /**
   * Checks if the data array contains any non-zero values
   * 
   * @returns {boolean} True if data has non-zero values, false otherwise
   */
  function findData () {
    // Use optional chaining and some to check for non-zero values
    return data?.some((number) => number > 0)
  }

  return (
    <div className='col-4' style={{height: 270}}>
      {/* Conditional skeleton loading class */}
      <div className={`card card-body h-100 bg-body ${loading&&'skeleton'}`}>
        <div className='d-flex flex-row align-items-center'>
          {/* Chart title with internationalization */}
          <h3 className='card-title align-items-start flex-column   m-0'>
            {intl.formatMessage({id: 'Assets By Grade'})}
          </h3>

          {/* Tooltip information icon */}
          <span
            data-tooltip-id='my-tooltip-AssetsByGroup'
            data-tooltip-content={intl.formatMessage({id: 'Assets By Grade tooltip'})}
          >
            <InfoIcon />
          </span>

          {/* Tooltip configuration */}
          <ReactTooltip
            id='my-tooltip-AssetsByGroup'
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center', zIndex:100}}
          />
        </div>

        {/* Conditionally apply blur effect if no data and not user scan */}
        <div className='mh-100 h-100' style={!findData() && !user?.is_user_scan ?{filter:'blur(3px)'}:{}}>
          {/* Render DonutChart with data or default data */}
          <DonutChart data={user?.is_user_scan?data:DefaultData} />
        </div>
      </div>
    </div>
  )
}

export default IssueByStatus