/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import axios from 'axios'
import {Dispatch} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import Check from '../../../pages/images/Ckeck'
import {toast} from 'sonner'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
}
interface fetchLoginValueProps {
  email: string
  password: string
  confirmPassword: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignUp () {
  const [loading, setLoading] = useState(false)
  const [isOk, setIsOk] = useState(false)
  const [isError, setIsError] = useState(false)
  const [pass, setPass] = useState(false)
  const [confirmPass, setConfirmPass] = useState(false)
  const [password, setPassword] = useState('')
  const intl = useIntl()
  /**
   * Defines the validation schema for the login form using Yup.
   */
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Wrong email format'}))
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Email is required'})),
    password: Yup.string()
      .min(10, intl.formatMessage({id: 'Minimum 10 symbols'}))
      .required(intl.formatMessage({id: 'Password is required'})),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], intl.formatMessage({id: 'Passwords must match'}))
      .required(intl.formatMessage({id: 'Confirm Password is required'})),
  })

  /**
   * Handles the sign-up process by making an API call to register a new user.
   * @param values - An object containing the user's email and password.
   * @param setStatus - A function to set the status message of the form.
   * @param setSubmitting - A function to set the submitting state of the form.
   */
  const fetchSignUp = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>
  ) => {
    setStatus('')
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/`,
        {
          email: values.email,
          password: values.password,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('You have successfully registered,\n please confirm your email')
        toast.success('You have successfully registered,\n please confirm your email')
        setIsError(false)
        setIsOk(true)
      } else {
        console.error(`error in login \n`, data)
        setStatus('The si detail is incorrect')
        toast.error('The si detail is incorrect')
        setIsError(true)
      }
      setLoading(false)
    } catch (e: any) {
      console.log('aaa', e)
      setLoading(false)
      setSubmitting(false)
      setStatus(e?.message || 'The sign up detail is incorrect')
      toast.error(e?.message || 'The sign up detail is incorrect')
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Initializes and configures the Formik form for user sign-up.
   * @returns A Formik form instance with initial values, validation schema, and submit handler.
   */
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (
      values: fetchLoginValueProps,
      {setStatus, setSubmitting}: {setStatus: Dispatch<any>; setSubmitting: Dispatch<any>}
    ) => {
      setLoading(true)
      fetchSignUp(values, setStatus, setSubmitting)
    },
  })


  return (
    <>
      {!isOk ? (
        <div className='card'>
          <div className='card-body py-5 px-md-5'>
            <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
              <h1>{intl.formatMessage({id: 'Sign up To Panel'})}</h1>
              {formik.status ? (
                <div
                  className={`font-weight-bold alert-text ${
                    isError ? 'alert-danger' : 'alert-success'
                  } `}
                >
                  {intl.formatMessage({id: formik.status || ''})}
                  {}
                </div>
              ) : (
                <span className='opacity-75'>
                  {intl.formatMessage({id: 'Enter your details to Sign up'})}
                </span>
              )}
            </div>
            <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signup_form'>
              <div data-mdb-input-init className='form-outline mb-4'>
                <input
                  dir='ltr'
                  type='email'
                  id='form3Example3'
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  {...formik.getFieldProps('email')}
                />

                <div className='d-flex align-items-center justify-content-between py-1'>
                  <label className='form-label m-0' htmlFor='form3Example3'>
                    {intl.formatMessage({id: 'Email address'})}
                  </label>

                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container text-center text-danger'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  )}
                </div>
              </div>

              <div data-mdb-input-init className='form-outline mb-4 position-relative'>
                <input
                  dir='ltr'
                  type={pass ? 'text' : 'password'}
                  id='form3Example4'
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  placeholder=' '
                  {...formik.getFieldProps('password')}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    formik.setFieldValue('password', e.target.value)
                  }}
                />
                <span
                  className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
                  style={{top: 22, right: 13}}
                  data-kt-password-meter-control='visibility'
                  onClick={() => {
                    setPass((perv) => !perv)
                  }}
                >
                  {!pass ? (
                    <KTIcon
                      iconName={'eye-slash'}
                      className='fs-1  text-white image_change_theme'
                      style={{cursor: 'pointer'}}
                    />
                  ) : (
                    <KTIcon
                      iconName={'eye'}
                      className='fs-1 text-white image_change_theme'
                      style={{cursor: 'pointer'}}
                    />
                  )}
                </span>
                <div className='d-flex align-items-center justify-content-between py-1'>
                  <label className='form-label m-0' htmlFor='form3Example4'>
                    {intl.formatMessage({id: 'New password'})}
                  </label>
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container text-center text-danger'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  )}
                </div>
              </div>

              <div data-mdb-input-init className='form-outline mb-4 position-relative'>
                <input
                  dir='ltr'
                  type={confirmPass ? 'text' : 'password'}
                  id='form3Example4'
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                    {
                      'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                    }
                  )}
                  placeholder=' '
                  {...formik.getFieldProps('confirmPassword')}
                />
                <span
                  className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
                  style={{top: 22, right: 13}}
                  data-kt-password-meter-control='visibility'
                  onClick={() => {
                    setConfirmPass((perv) => !perv)
                  }}
                >
                  {!confirmPass ? (
                    <KTIcon
                      iconName={'eye-slash'}
                      className='fs-1 text-white  image_change_theme'
                      style={{cursor: 'pointer'}}
                    />
                  ) : (
                    <KTIcon
                      iconName={'eye'}
                      className='fs-1 text-white image_change_theme'
                      style={{cursor: 'pointer'}}
                    />
                  )}
                </span>
                <div className='d-flex align-items-center justify-content-between py-1'>
                  <label className='form-label m-0' htmlFor='form3Example4'>
                    {intl.formatMessage({id: 'Confirm password'})}
                  </label>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container text-center text-danger'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className='btn btn-lg btn-block btn-primary w-100'
                type='submit'
                id='kt_sign_in_submit'
                disabled={loading || !formik.isValid}
                style={{opacity: 0.9}}
              >
                {!loading && (
                  <span className='indicator-label'>{intl.formatMessage({id: 'Sign up'})}</span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Please wait'})}
                    ...
                    <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </form>
            <hr className='w-100' />
            <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8 mt-5'>
              <Link to='/auth' id='kt_login_forgot' className=' font-weight-bold'>
                {intl.formatMessage({id: 'Cancel'})}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex align-items-center justify-content-center flex-column gap-5 text-center card py-10  py-10 mb-lg-0'>
          <Check className='svg-icon-6x svg-icon-success' />
          <h1 className=' text-success'>
            {intl.formatMessage({id: 'Please check your emails.'})}
          </h1>
          <Link to={'/'} id='kt_login_forgot' className=' fw-bold'> {intl.formatMessage({id: 'Back to Login page'})} </Link>
        </div>
      )}
    </>
  )
}
