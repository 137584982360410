import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import PageLoading from '../../loading/PageLoading'
import ConvertDate from '../../../../Utils/ConvertDate'
import Sort from '../../images/Sort'

import {EXPLORE_EMAILS_DATA as EmailsData} from '../../../modules/auth/redux/DEMO_DATA'
import {SetDataEmails} from '../../../modules/auth'
import usePermission from '../../../../Utils/usePermission'

/**
 * App component for displaying email data with pagination.
 *
 * This component fetches and displays a list of email data, including email addresses and IDs.
 * It includes pagination functionality and handles loading states.
 *
 * @returns {JSX.Element} The rendered App component
 */
export default function App () {
  const navigate = useNavigate()
  // const [loading, setLoading] = useState(false)
  // const [open, setOpen] = useState<number | null>(null)
  // const errorHandling = useErrorHandling()
  // const dispatch = useDispatch()
  // const navigate = useNavigate()
  // const {isPermission, getPermission} = usePermission()
  // const emailsData = useSelector((state: RootState) => state.root.data.dataEmails)
  // const intl = useIntl()
  // const handlePageClick = (event: {selected: number}) => {
  //   fetchEmailData(`?page=${event.selected + 1}`)
  // }

  // const fetchEmailData = async (query = '?page=1') => {
  //   setLoading(true)
  //   try {
  //     const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/email/${query}`)
  //     errorHandling(data.status) //logout when invalid token && redirect 404
  //     if (data.status >= 200 && data.status < 300) {
  //       dispatch(SetDataEmails(data.data))
  //     } else {
  //       console.error(
  //         `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/email/${query} \n`,
  //         data
  //       )
  //     }
  //     setLoading(false)
  //   } catch (e) {
  //     console.log(e)
  //     setLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   const nowDate = new Date().getTime()
  //   if (emailsData?.expireTime && emailsData.expireTime >= nowDate) {
  //   } else {
  //     fetchEmailData()
  //   }
  // }, [])
  return false ? (
    <div className='w-100 h-400px'>
      <PageLoading />
    </div>
  ) : (
    // <>
    //   <div classNameName='bg-body p-4 w-100'>
    //     {isPermission()}
    //     <h1>{intl.formatMessage({id: 'Applications'})}</h1>
    //     <div classNameName='position-relative d-flex pt-5 w-100'>
    //       <div classNameName='w-100'>
    //         <div classNameName={`w-100  overflow-visible  m-0 pb-8`}>
    //           <div classNameName='d-flex flex-column align-items-center' style={{filter: 'blur(3px)'}}>
    //             {/* start titles */}
    //             <div classNameName='m-0 w-100'>
    //               <div classNameName='d-flex justify-content-between align-items-center bg-gray-400 mb-4 p-2 pt-1 pb-1 rounded-1 fw-bold w-100 fs-5'>
    //                 <div classNameName='p-1 w-50 h-auto'>
    //                   <div classNameName='d-flex justify-content-center align-items-center w-100 h-auto pointer'>
    //                     <span>{intl.formatMessage({id: 'Email'})}</span>
    //                   </div>
    //                 </div>
    //                 <div classNameName='justify-content-center p-1 w-50 h-auto'>
    //                   <div classNameName='d-flex justify-content-center align-items-center w-100 h-auto pointer'>
    //                     {' '}
    //                     <span>{intl.formatMessage({id: 'ID'})}</span>{' '}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             {/* end titles */}
    //             {(emailsData?.data && emailsData?.data?.results?.length
    //               ? emailsData.data.results
    //               : EmailsData.results
    //             )?.map((item, index: number) => (
    //               <div
    //                 style={!emailsData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
    //                 key={index}
    //                 classNameName={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center  mb-3`}
    //               >
    //                 <div classNameName='d-flex justify-content-between align-items-center p-2 pt-1 pb-1 w-100'>
    //                   <div classNameName='d-flex justify-content-center p-1 w-50 h-auto'>
    //                     {' '}
    //                     {item.email}{' '}
    //                   </div>
    //                   <div classNameName='d-flex justify-content-center p-1 w-50 h-auto'>{item.id}</div>
    //                 </div>
    //               </div>
    //             ))}
    //           </div>
    //           <div classNameName='d-flex justify-content-end bg-body rounded-bottom pe-4'>
    //             <ReactPaginate
    //               nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
    //               // onPageChange={handlePageClick}
    //               pageRangeDisplayed={3}
    //               marginPagesDisplayed={2}
    //               pageCount={emailsData?.data?.total_pages || 1}
    //               forcePage={
    //                 emailsData?.data?.current_page ? emailsData?.data?.current_page - 1 : 0
    //               }
    //               previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
    //               pageClassName='page-item'
    //               pageLinkClassName='page-link'
    //               previousClassName='page-item'
    //               previousLinkClassName='page-link'
    //               nextClassName='page-item'
    //               nextLinkClassName='page-link'
    //               breakLabel='...'
    //               breakClassName='page-item'
    //               breakLinkClassName='page-link'
    //               containerClassName='pagination'
    //               activeClassName='active'
    //               renderOnZeroPageCount={null}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='box'>
                <div className='with-border box-header'>
                  <h3 className='box-title'>
                    <i className='fa fa-rocket'></i> Recent Scans
                  </h3>

                  <p className='lead'>
                    View scan progress in the <strong></strong>
                  </p>
                </div>

                <div className='box-body'>
                  <div className='table-responsive'>
                    <table className='table-bordered table table-hover table-striped'>
                      <thead>
                        <tr>
                          <th>APP</th>
                          <th>FILE</th>
                          <th>TYPE</th>
                          <th>HASH</th>
                          <th>SCAN DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{textAlign: 'center'}}>
                            <img width={60} height={60} id='app_icon' src='/media/test4.png' />
                            <br />
                            <strong>Cobros Provisorios - 1.0.0 </strong>
                            <br />
                            com.sebaot.sebaotcobroprov
                            <p>
                              {' '}
                              <a className='btn btn-outline-primary btn-sm' role='button'></a>
                            </p>
                            <p>
                              <a
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  navigate('details')
                                }}
                              >
                                <i className='fa-eye fas'></i> Static Report
                              </a>

                              <a className='btn btn-sm btn-success disabled'>
                                <i className='fa fa-mobile'></i> Dynamic Report
                              </a>
                            </p>
                          </td>
                          <td>SebaotCobroProv64.apk</td>
                          <td style={{textAlign: 'center'}}>
                            <i className='fa-3x fa-android fab'></i>
                          </td>
                          <td>555226d44d9d8a3b4a1a352803d39cca</td>
                          <td>Jan. 22, 2025, 12:59 p.m.</td>
                        </tr>

                        <tr>
                          <td style={{textAlign: 'center'}}>
                            <img width={60} height={60} id='app_icon' src='/media/test1.png' />
                            <br />
                            <strong>Aptoide - 9.22.4.0 </strong>
                            <br />
                            cm.aptoide.pt
                            <p>
                              {' '}
                              <a className='btn btn-outline-primary btn-sm' role='button'></a>
                            </p>
                            <p>
                              <a
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  navigate('details')
                                }}
                              >
                                <i className='fa-eye fas'></i> Static Report
                              </a>

                              <a className='btn btn-sm btn-success disabled'>
                                <i className='fa fa-mobile'></i> Dynamic Report
                              </a>
                            </p>
                          </td>
                          <td>canarabank-recovery.apk</td>
                          <td style={{textAlign: 'center'}}>
                            <i className='fa-3x fa-android fab'></i>
                          </td>
                          <td>3e2c49caf9a2ff61b7dc6f7902fbdf13</td>
                          <td>Jan. 22, 2025, 12:58 p.m.</td>
                        </tr>

                        <tr>
                          <td style={{textAlign: 'center'}}>
                            <img width={60} height={60} id='app_icon' src='/media/test2.png' />
                            <br />
                            <strong>NoJumpStarts - 1.0 </strong>
                            <br />
                            com.mobisec.nojumpstarts
                            <p>
                              {' '}
                              <a className='btn btn-outline-primary btn-sm' role='button'></a>
                            </p>
                            <p>
                              <a
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  navigate('details')
                                }}
                              >
                                <i className='fa-eye fas'></i> Static Report
                              </a>

                              <a className='btn btn-sm btn-success disabled'>
                                <i className='fa fa-mobile'></i> Dynamic Report
                              </a>
                            </p>
                          </td>
                          <td>nojumpstarts.apk</td>
                          <td style={{textAlign: 'center'}}>
                            <i className='fa-3x fa-android fab'></i>
                          </td>
                          <td>3aa52b3dde1d26417ddbe455dbc33ae6</td>
                          <td>Jan. 22, 2025, 12:57 p.m.</td>
                        </tr>

                        <tr>
                          <td style={{textAlign: 'center'}}>
                            <img width={60} height={60} id='app_icon' src='/media/test3.png' />
                            <br />
                            <strong>AOS Society - 1.3 </strong>
                            <br />
                            com.aos_society.android
                            <p>
                              {' '}
                              <a className='btn btn-outline-primary btn-sm' role='button'></a>
                            </p>
                            <p>
                              <a
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  navigate('details')
                                }}
                              >
                                <i className='fa-eye fas'></i> Static Report
                              </a>

                              <a className='btn btn-sm btn-success disabled'>
                                <i className='fa fa-mobile'></i> Dynamic Report
                              </a>
                            </p>
                          </td>
                          <td>AOS_STAGING_FOR_TESTING.apk</td>
                          <td style={{textAlign: 'center'}}>
                            <i className='fa-3x fa-android fab'></i>
                          </td>
                          <td>5e37822bb83fb3310fb7bdd8f58ede09</td>
                          <td>Jan. 22, 2025, 12:56 p.m.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
