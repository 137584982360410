import { createSlice } from '@reduxjs/toolkit'
import { demoData } from './DEMO_DATA'
import { UserModel } from '../models/UserModel'
import axios from 'axios'
import { DashboardDataStructure } from '../../types/dashboard'
import { ScanProfile, ScanResults } from '../../types/scan'
import { TargetApiResponse, TargetCreateType } from '../../types/target'
import { AssetApiResponse } from '../../types/asset'
import { VulnerabilitiesResponse } from '../../types/vulnerabilities'
import { SslResponse } from '../../types/ssl'
import { DNSType } from '../../types/dns'
import { SmtpType } from '../../types/smtp'
import { ExposuresType } from '../../types/exposures'
import { DnsRecordResults } from '../../types/dnsRecord'
import { EmailType } from '../../types/email'
import { IpApiResponse } from '../../types/ip'
import { portType } from '../../types/port'
import { PlanType, SubscriptionData } from '../../types/plans'
import { PaymentHistoryType } from '../../types/paymentHistory'
import { IntegrationsType } from '../../types/integrations'
import { TicketResponse } from '../../types/ticket'
import { ServiceType } from '../../types/service'
import { NotificationType } from '../../types/notification'
import { OptionsType, SelectedType } from '../../types/filters'

/**
 * Interface for cached data with expiration time
 * @interface DATA
 */
interface DATA {
  /** The actual data being stored */
  data: undefined | any,
  /** Timestamp when the data expires */
  expireTime: null | number
}

/**
 * Interface defining basic filter structure
 * @interface BasicFilter
 */
interface BasicFilter {
  /** Available filter options */
  options: OptionsType,
  /** Currently selected filter options */
  selected: SelectedType,
  /** Ascending sort field */
  sortAsc: string,
  /** Descending sort field */
  sortDes: string,
  /** Search criteria array */
  search: { name: string, value: string }[],
}
interface NodeConnect {
  nodeId: string, portId?: string
}
/**
 * Main authentication state interface
 * @interface IAuthState
 */
export interface IAuthState {
  /** Contains all data states with expiration times */
  data: {
    dataDashboard: {
      data: DashboardDataStructure | undefined | null
      expireTime: null | number
    }
    dataScan: {
      data: ScanResults | undefined | null
      expireTime: null | number
    }
    dataScope: {
      data: TargetApiResponse | null | undefined
      expireTime: null | number
    }
    dataAsset: {
      data: AssetApiResponse | null | undefined
      expireTime: null | number
    }
    dataVulnerability: {
      data: VulnerabilitiesResponse | null | undefined
      expireTime: null | number
    }
    dataSSL: {
      data: SslResponse | null | undefined
      expireTime: null | number
    }
    dataDns: {
      data: DNSType[] | null | undefined
      expireTime: null | number
    }
    dataSmtp: {
      data: SmtpType[] | null | undefined
      expireTime: null | number
    }
    dataScanDAG: {
      data: ScanProfile[] | null | undefined
      expireTime: null | number
    }
    dataExposures: {
      data: ExposuresType | null | undefined
      expireTime: null | number
    }
    dataDnsRecords: {
      data: DnsRecordResults | null | undefined
      expireTime: null | number
    }
    dataEmails: {
      data: EmailType | null | undefined
      expireTime: null | number
    },
    dataIp: {
      data: IpApiResponse | null | undefined
      expireTime: null | number
    }
    dataPorts: {
      data: portType | null | undefined
      expireTime: null | number
    }
    dataPricePlans: {
      data: SubscriptionData | null | undefined
      expireTime: null | number
    }
    dataPaymentHistory: {
      data: PaymentHistoryType | null | undefined
      expireTime: null | number
    }
    dataIntegrations: {
      data: IntegrationsType[] | null | undefined
      expireTime: null | number
    }
    dataTicket: {
      data: TicketResponse | null | undefined
      expireTime: null | number
    }
    dataTargetTypes: {
      data: TargetCreateType[] | null | undefined
      expireTime: null | number
    }
    dataServices: {
      data: ServiceType[] | null | undefined
      expireTime: null | number
    }
    dataNotification: NotificationType[] | undefined | null
    dataLeaked: DATA
  }
  /** Filter options for different data types */
  filterOption: {
    assets: BasicFilter,
    ssl: BasicFilter
    dnsRecord: BasicFilter,
    email: BasicFilter,
    port: BasicFilter,
    vulnerabilities: {
      options: any,
      selected: any,
      search: string
    },
    ip: BasicFilter,
    ticket: string,
  }
  /** AI assistant related state */
  aiAssistant: {
    vulnerabilitiesSelected: {
      id: null | string,
      title: null | string
    }
    questionList: { id: string, content: string }[]
  },
  /** Current user information */
  user: UserModel | undefined | null
  /** JWT access token */
  accessToken: string | undefined | null
  /** Flag for new notifications */
  newNotification: boolean,
  /** Nodes can a Node be connected to */
  nodeConnect: null | NodeConnect[]
}

/**
 * Initial state for the authentication slice
 */
const initialAuthState: IAuthState = {
  data: {
    dataDashboard: {
      data: undefined,
      expireTime: null
    },
    dataScope: {
      data: undefined,
      expireTime: null
    },
    dataAsset: {
      data: undefined,
      expireTime: null
    },
    dataVulnerability: {
      data: undefined,
      expireTime: null
    },
    dataDns: {
      data: undefined,
      expireTime: null
    },
    dataSSL: {
      data: undefined,
      expireTime: null
    },
    dataSmtp: {
      data: undefined,
      expireTime: null
    },
    dataScanDAG: {
      data: undefined,
      expireTime: null
    },
    dataScan: {
      data: undefined,
      expireTime: null
    },
    dataExposures: {
      data: undefined,
      expireTime: null
    },
    dataDnsRecords: {
      data: undefined,
      expireTime: null
    },
    dataEmails: {
      data: undefined,
      expireTime: null
    },
    dataPricePlans: {
      data: undefined,
      expireTime: null
    },
    dataIp: {
      data: undefined,
      expireTime: null
    },
    dataPorts: {
      data: undefined,
      expireTime: null
    },
    dataPaymentHistory: {
      data: undefined,
      expireTime: null
    },
    dataIntegrations: {
      data: undefined,
      expireTime: null
    },
    dataTicket: {
      data: undefined,
      expireTime: null
    },
    dataServices: {
      data: undefined,
      expireTime: null
    },
    dataTargetTypes: {
      data: undefined,
      expireTime: null
    },
    dataLeaked: {
      data: undefined,
      expireTime: null
    },
    dataNotification: undefined
  },
  filterOption: {
    assets: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    ssl: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    dnsRecord: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    email: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    port: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    vulnerabilities: {
      options: null,
      selected: null,
      search: ''
    },
    ip: {
      options: null,
      selected: null,
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    ticket: 'open',
  },
  aiAssistant: {
    vulnerabilitiesSelected: {
      id: null,
      title: null
    },
    questionList: []
  },
  user: null,
  accessToken: null,
  newNotification: false,
  nodeConnect: null
}

/**
 * Generates expiration timestamp for cached data
 * @param {number} time - Time in minutes before data expires (default: 5)
 * @returns {number} Expiration timestamp
 */
function generateExpireTime(time: number = 5) {
  const expireTime = new Date().getTime() + (time * 60 * 1000)
  return expireTime
}
const rootSlice = createSlice({
  name: 'Root',
  initialState: process.env.REACT_APP_MODE === 'demo' ? demoData : initialAuthState,
  reducers: {
    // ==================== Filter reducers ====================
    /**
  * Updates the selected filters for asset data
  * @param {IAuthState} state - Current application state
  * @param {PayloadAction<SelectedType>} action - Selected filter values for assets
  */
    FilterSelectedAssets(state, action) {
      state.filterOption.assets.selected = action.payload
    },

    /**
     * Updates the available filter options for asset data
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for assets
     */
    FilterOptionAssets(state, action) {
      state.filterOption.assets.options = action.payload
    },

    /**
     * Updates the selected filters for SSL certificates
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for SSL
     */
    FilterSelectedSSL(state, action) {
      state.filterOption.ssl.selected = action.payload
    },

    /**
     * Updates the available filter options for SSL certificates
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for SSL
     */
    FilterOptionSSL(state, action) {
      state.filterOption.ssl.options = action.payload
    },

    /**
     * Updates the selected filters for port scanning
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for ports
     */
    FilterSelectedPort(state, action) {
      state.filterOption.port.selected = action.payload
    },

    /**
     * Updates the available filter options for port scanning
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for ports
     */
    FilterOptionPort(state, action) {
      state.filterOption.port.options = action.payload
    },

    /**
     * Updates the selected filters for DNS records
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for DNS records
     */
    FilterSelectedDnsRecord(state, action) {
      state.filterOption.dnsRecord.selected = action.payload
    },

    /**
     * Updates the available filter options for DNS records
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for DNS records
     */
    FilterOptionDnsRecord(state, action) {
      state.filterOption.dnsRecord.options = action.payload
    },

    /**
     * Updates the selected filters for email data
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for emails
     */
    FilterSelectedEmail(state, action) {
      state.filterOption.email.selected = action.payload
    },

    /**
     * Updates the available filter options for email data
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for emails
     */
    FilterOptionEmail(state, action) {
      state.filterOption.email.options = action.payload
    },

    /**
     * Updates the selected filters for IP addresses
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for IPs
     */
    FilterSelectedIp(state, action) {
      state.filterOption.ip.selected = action.payload
    },

    /**
     * Updates the available filter options for IP addresses
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for IPs
     */
    FilterOptionIp(state, action) {
      state.filterOption.ip.options = action.payload
    },

    /**
     * Updates the selected filters for vulnerabilities
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<SelectedType>} action - Selected filter values for vulnerabilities
     */
    FilterSelectedVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.selected = action.payload
    },

    /**
     * Updates the selected ticket filter status
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<string>} action - Selected ticket filter value
     */
    FilterSelectedTicket(state, action) {
      state.filterOption.ticket = action.payload
    },

    /**
     * Updates the available filter options for vulnerabilities
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<OptionsType>} action - Available filter options for vulnerabilities
     */
    FilterOptionVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.options = action.payload
    },
    // ==================== Search Reducers ====================

    /**
     * Updates the search query for vulnerabilities
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<string>} action - Search query string for vulnerability filtering
     */
    SearchVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.search = action.payload
    },

    /**
     * Updates the search criteria for assets
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for asset filtering
     */
    SearchAsset(state, action) {
      state.filterOption.assets.search = action.payload
    },

    /**
     * Updates the search criteria for IP addresses
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for IP filtering
     */
    SearchIp(state, action) {
      state.filterOption.ip.search = action.payload
    },

    /**
     * Updates the search criteria for email records
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for email filtering
     */
    SearchEmail(state, action) {
      state.filterOption.email.search = action.payload
    },

    /**
     * Updates the search criteria for DNS records
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for DNS record filtering
     */
    SearchDnsRecord(state, action) {
      state.filterOption.dnsRecord.search = action.payload
    },

    /**
     * Updates the search criteria for ports
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for port filtering
     */
    SearchPort(state, action) {
      state.filterOption.port.search = action.payload
    },

    /**
     * Updates the search criteria for SSL certificates
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<Array<{name: string, value: string}>>} action - Array of search criteria for SSL certificate filtering
     */
    SearchSSL(state, action) {
      state.filterOption.ssl.search = action.payload
    },
    // ==================== Sort Reducers ====================

    /**
     * Updates the sort configuration for SSL certificates
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortSSL(state, action) {
      const { type, value } = action.payload
      // Toggle between ascending and descending sort, clearing the opposite
      if (type === 'asc') {
        state.filterOption.ssl.sortAsc = value
        state.filterOption.ssl.sortDes = ''  // Clear descending sort
      } else {
        state.filterOption.ssl.sortDes = value
        state.filterOption.ssl.sortAsc = ''  // Clear ascending sort
      }
    },

    /**
     * Updates the sort configuration for ports
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortPort(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.port.sortAsc = value
        state.filterOption.port.sortDes = ''
      } else {
        state.filterOption.port.sortDes = value
        state.filterOption.port.sortAsc = ''
      }
    },

    /**
     * Updates the sort configuration for DNS records
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortDnsRecord(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.dnsRecord.sortAsc = value
        state.filterOption.dnsRecord.sortDes = ''
      } else {
        state.filterOption.dnsRecord.sortDes = value
        state.filterOption.dnsRecord.sortAsc = ''
      }
    },

    /**
     * Updates the sort configuration for emails
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortEmail(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.email.sortAsc = value
        state.filterOption.email.sortDes = ''
      } else {
        state.filterOption.email.sortDes = value
        state.filterOption.email.sortAsc = ''
      }
    },

    /**
     * Updates the sort configuration for IP addresses
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortIp(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.ip.sortAsc = value
        state.filterOption.ip.sortDes = ''
      } else {
        state.filterOption.ip.sortDes = value
        state.filterOption.ip.sortAsc = ''
      }
    },

    /**
     * Updates the sort configuration for assets
     * @param {IAuthState} state - Current application state
     * @param {PayloadAction<{type: 'asc' | 'desc', value: string}>} action - Sort configuration
     * @param {string} action.payload.type - Sort direction ('asc' or 'desc')
     * @param {string} action.payload.value - Field name to sort by
     */
    SortAsset(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.assets.sortAsc = value
        state.filterOption.assets.sortDes = ''
      } else {
        state.filterOption.assets.sortDes = value
        state.filterOption.assets.sortAsc = ''
      }
    },

    // ==================== Authentication & User Management ====================

    /**
    * Handles user logout process 
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<boolean>} action - If true, makes logout API call
    * @returns {IAuthState | void} Returns initial state or modifies user state for demo mode
    */
    Logout_(state, action) {
      if (!(process.env.REACT_APP_MODE === 'demo')) {
        const fcmToken = localStorage.getItem('fcmToken')
        // Make API call if requested
        if (action.payload) {
          // Call logout API endpoint
          axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/logout/`, {
            fcm_token: fcmToken
          }).then((res) => {
          }).catch((e) => {
          })
          // Clean up local storage
          localStorage.removeItem("fcmToken")
          localStorage.removeItem("fcmTokenExpiry")
          return initialAuthState
        } else {
          // Clean up without API call
          localStorage.removeItem("fcmToken")
          localStorage.removeItem("fcmTokenExpiry")
          return initialAuthState
        }
      } else {
        // Handle demo mode logout
        localStorage.removeItem("fcmToken")
        localStorage.removeItem("fcmTokenExpiry")
        state.user = null
      }
    },

    /**
    * Updates user information in state
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<UserModel>} action - User data payload
    */
    UserLoaded(state, action) {
      state.user = action.payload
    },

    // ==================== Data Management Reducers ====================

    /**
    * Updates dashboard data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<DashboardDataStructure>} action - Dashboard data payload
    */
    SetDataDashboard(state, action) {
      state.data.dataDashboard = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates scope data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<TargetApiResponse>} action - Scope data payload
    */
    SetDataScope(state, action) {
      state.data.dataScope = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates asset data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<AssetApiResponse>} action - Asset data payload
    */
    SetDataAsset(state, action) {
      state.data.dataAsset = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates vulnerability data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<VulnerabilitiesResponse>} action - Vulnerability data payload
    */
    SetDataVulnerability(state, action) {
      state.data.dataVulnerability = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates SSL certificate data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<SslResponse>} action - SSL data payload
    */
    SetDataSSL(state, action) {
      state.data.dataSSL = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates DNS data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<DNSType[]>} action - DNS data payload
    */
    SetDataDNS(state, action) {
      state.data.dataDns = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates SMTP data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<SmtpType[]>} action - SMTP data payload
    */
    SetDataSmtp(state, action) {
      state.data.dataSmtp = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates scan data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<Scan[]>} action - Scan data payload
    */
    SetDataScan(state, action) {
      state.data.dataScan = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates scan DAG data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<ScanProfile[]>} action - Scan DAG data payload
    */
    SetDataScanDAG(state, action) {
      state.data.dataScanDAG = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates exposures data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<ExposuresType>} action - Exposures data payload
    */
    SetDataExposures(state, action) {
      state.data.dataExposures = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates DNS records data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<DnsRecordResults>} action - DNS records data payload
    */
    SetDataDnsRecords(state, action) {
      state.data.dataDnsRecords = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },
    /**
 * Updates email data with expiration time
 * @param {IAuthState} state - Current application state
 * @param {PayloadAction<EmailType>} action - Email data payload
 */
    SetDataEmails(state, action) {
      state.data.dataEmails = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates pricing plans data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<PlanType[]>} action - Pricing plans data payload
    */
    SetDataPricePlans(state, action) {
      state.data.dataPricePlans = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates services data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<ServiceType[]>} action - Services data payload
    */
    SetDataServices(state, action) {
      state.data.dataServices = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates IP data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<IpApiResponse>} action - IP data payload
    */
    SetDataIp(state, action) {
      state.data.dataIp = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates notification data
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<NotificationType[]>} action - Notification data payload
    */
    SetDataNotification(state, action) {
      state.data.dataNotification = action.payload
    },

    /**
    * Updates target types data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<TargetCreateType[]>} action - Target types data payload
    */
    SetDataTargetTypes(state, action) {
      state.data.dataTargetTypes = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates leaked data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<any>} action - Leaked data payload
    */
    SetDataLeaked(state, action) {
      state.data.dataLeaked = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates ticket data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<TicketResponse | null>} action - Ticket data payload
    */
    SetDataTicket(state, action) {
      if (action.payload === null) {
        // Reset ticket data when payload is null
        state.data.dataTicket = {
          data: null,
          expireTime: null
        }
      } else {
        // Set new ticket data with expiration
        state.data.dataTicket = {
          data: action.payload,
          expireTime: generateExpireTime()
        }
      }
    },

    /**
    * Updates integrations data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<IntegrationsType[]>} action - Integrations data payload
    */
    SetDataIntegrations(state, action) {
      state.data.dataIntegrations = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates ports data with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<portType>} action - Ports data payload
    */
    SetDataPorts(state, action) {
      state.data.dataPorts = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    /**
    * Updates payment history with expiration time
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<PaymentHistoryType[]>} action - Payment history payload
    */
    SetDataPaymentHistory(state, action) {
      state.data.dataPaymentHistory = {
        data: action.payload,
        expireTime: generateExpireTime()
      }
    },

    // ==================== AI Assistant Reducers ====================

    /**
    * Updates selected vulnerability for AI assistant
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<{id: string | null, title: string | null}>} action - Selected vulnerability
    */
    SetAiAssistantVulnerabilitiesID(state, action) {
      state.aiAssistant.vulnerabilitiesSelected = action.payload
    },

    /**
    * Updates AI assistant question list
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<Array<{id: string, content: string}>>} action - Question list
    */
    SetAiAssistantQuestionList(state, action) {
      state.aiAssistant.questionList = action.payload
    },
    // ==================== Status Change Reducers ====================

    /**
    * Updates user's new status flag
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<boolean>} action - New status flag
    * @description Only updates if user exists in state
    */
    ChangeUserStatus(state, action) {
      if (state.user) {
        state.user.is_user_new = action.payload
      }
    },

    /**
    * Updates user's notification status
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<boolean>} action - New notification status
    * @description Only updates if user exists in state
    */
    ChangeUserHasNewNotification(state, action) {
      if (state.user) {
        state.user.has_new_notification = action.payload
      }
    },

    /**
    * Updates global notification status
    * @param {IAuthState} state - Current application state
    * @param {PayloadAction<boolean>} action - New notification status
    * @description Updates the global notification flag regardless of user state
    */
    ChangeNotificationStatus(state, action) {
      state.newNotification = action.payload
    },
    SetNodeConect(state, action) {
      if (action.payload) {
        state.nodeConnect = action.payload
      }
      else state.nodeConnect = null
    }
  },
})

export const {
  Logout_,
  UserLoaded,
  SetDataDashboard,
  SetDataScope,
  SetDataAsset,
  SetDataVulnerability,
  SetDataSSL,
  SetDataDNS,
  SetDataSmtp,
  SetDataScan,
  SetDataScanDAG,
  SetDataExposures,
  SetDataDnsRecords,
  SetDataEmails,
  SetDataPricePlans,
  FilterOptionAssets,
  FilterSelectedAssets,
  FilterSelectedVulnerabilities,
  FilterOptionVulnerabilities,
  SearchVulnerabilities,
  SetAiAssistantVulnerabilitiesID,
  SetAiAssistantQuestionList,
  ChangeUserStatus,
  FilterSelectedDnsRecord,
  FilterOptionDnsRecord,
  FilterSelectedEmail,
  FilterOptionEmail,
  FilterSelectedIp,
  FilterOptionIp,
  SetDataIp,
  SearchAsset,
  SortAsset,
  SearchIp,
  SortIp,
  SearchEmail,
  SortEmail,
  SearchDnsRecord,
  SortDnsRecord,
  SearchSSL,
  SortSSL,
  FilterSelectedSSL,
  FilterOptionSSL,
  SetDataIntegrations,
  FilterSelectedPort,
  FilterOptionPort,
  SetDataPorts,
  SearchPort,
  SortPort,
  SetDataPaymentHistory,
  SetDataTicket,
  SetDataServices,
  FilterSelectedTicket,
  ChangeNotificationStatus,
  ChangeUserHasNewNotification,
  SetDataNotification,
  SetDataTargetTypes,
  SetDataLeaked,
  SetNodeConect
} = rootSlice.actions
export default rootSlice.reducer


