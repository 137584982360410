import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top/index'
import {Sidebar} from './components/sidebar/index'
import {DrawerMessenger} from '../partials'
import {PageDataProvider} from './core'
import {KTIcon, reInitMenu} from '../helpers'
import {FooterWrapper} from './components/footer/index'
import useFcmToken from '../../Utils/useFcmToken'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const FcmToken = useFcmToken()
  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='flex-column flex-column-fluid app-page' id='kt_app_page'>
          <HeaderWrapper />
          <div
            className='position-relative flex-row-fluid flex-column app-wrapper p-10 pb-0'
            id='kt_app_wrapper'
          >
            <Sidebar />
            <div
              className='flex-row-fluid flex-column mx-auto app-main w-100'
              id='kt_app_main'
              style={{maxWidth: 1320}}
            >
              <div className='d-flex flex-column flex-column-fluid'>
                <Outlet />
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>
      <DrawerMessenger />
      <ScrollTop />
      <div id='kt_drawer_chat_toggle' className='ai_btn no_rotate' dir='ltr'>
        <KTIcon
          iconName='illustrator'
          iconType='solid'
          style={{fontSize: 44}}
          className='image_change_theme'
        />
      </div>
    </PageDataProvider>
  )
}

export {MasterLayout}
