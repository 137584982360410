import React, {useState, useLayoutEffect} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import ConvertDate from '../../../../Utils/ConvertDate'
import EmptyPage from '../../emptyPage/EmptyPage'
import {useNavigate} from 'react-router-dom'
import usePermission from '../../../../Utils/usePermission'
import {VulnerabilitiesResponse} from '../../../modules/types/vulnerabilities'
import ReactPaginate from 'react-paginate'
export const TaskScan = ({id}: {id: string | undefined}) => {
  const [loading, setLoading] = useState(false)
  const [vulnerabilityData, setVulnerabilityData] = useState<VulnerabilitiesResponse | null>(null)
  const lang = useLang()?.lang.selectedLang
  const intl = useIntl()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const {getPermission, isPermission} = usePermission()
  const fetchVulnerabilityData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?scan_id=${id}&page=${page}`
      )
      errorHandling(data.status) // Logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        setVulnerabilityData(data.data)
      } else {
        console.error(
          `Error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handlePageClick = (event: {selected: number}) => {
    fetchVulnerabilityData(event.selected + 1)
  }
  useLayoutEffect(() => {
    if (id !== null) {
      fetchVulnerabilityData(1)
    }
  }, [id])
  return (
    <>
      <div
        className={`row d-flex flex-row p-3 position-relative ${loading && 'skeleton'} min-h-300px`}
      >
        {isPermission()}
        <div className='w-100 h-100'>
          <div className={` h-100 rounded-bottom-0`}>
            {/* start body */}
            {vulnerabilityData?.results && vulnerabilityData.results?.length ? (
              <div className={`w-100 h-100 overflow-auto  m-0`}>
                {vulnerabilityData &&
                  vulnerabilityData.results?.map((item) => (
                    <div
                      key={item?.id}
                      className={`w-100 m-0 p-3  bg-gray-200 rounded-1 d-flex justify-content-center align-items-center mb-3 hover_Costume`}
                      onClick={() => {
                        navigate({
                          pathname: '/vulnerability-details',
                          search: `?id=${item.id}`,
                        })
                      }}
                    >
                      <div className='w-100 d-flex flex-column gap-5'>
                        <div className='w-100 d-flex  justify-content-between align-items-center'>
                          <div className={` text-primary fs-6`}>
                            {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}
                          </div>

                          <div className=' h-auto d-flex justify-content-center p-1'>
                            <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                          </div>
                        </div>

                        <div className='d-flex gap-3 justify-content-between px-2'>
                          <div className='w-100 h-auto d-flex flex-column gap-1'>
                            <span className='text-gray-800'>
                              {intl.formatMessage({id: 'Target'})}
                            </span>
                            <div className=' d-flex  align-items-center p-1 h-auto'>
                              {item.target}
                            </div>
                          </div>
                          {/* <div className='w-100 h-auto d-flex flex-column gap-1 '>
                              <span className='text-gray-800'>
                                {intl.formatMessage({id: 'Affected Asset'})}
                              </span>
                              <span className='fs-6 text-primary'>{item.asset}</span>
                            </div> */}
                          <div className='px-2 w-100 d-flex flex-column gap-1'>
                            <span className='text-gray-800 ms-2'>
                              {intl.formatMessage({id: 'Severity'})}
                            </span>
                            <div className='  h-auto d-flex flex-row align-items-center  text-capitalize'>
                              <div className=' d-flex justify-content-start'>
                                <div
                                  className={`w-15px h-15px  rounded-3 border-i${item.severity} border border-2 m-0 `}
                                />
                              </div>
                              {intl.formatMessage({id: item.severity || 'low'})}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
<div className=' w-100 d-flex justify-content-end'>
  
                  <ReactPaginate
                    nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={vulnerabilityData?.total_pages}
                    forcePage={vulnerabilityData?.current_page - 1}
                    previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    nextLinkClassName='page-link'
                    breakLabel='...'
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    renderOnZeroPageCount={null}
                  />
                  
</div>
              </div>
            ) : (
              <EmptyPage />
            )}
            {/* end body */}
          </div>
        </div>
      </div>
    </>
  )
}

// <div
//   key={item.id}
//   className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center ${
//     index === vulnerabilityData?.data?.results?.length - 1 ? null : 'mb-3'
//   } hover_Costume`}
//   style={{cursor: 'pointer', minHeight: 64}}
// >
//   <div className='w-100 d-flex p-1 align-items-center'>
//     <div className='w-50 p-1 h-auto d-flex align-items-center'>
//       {' '}
//       {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}{' '}
//     </div>
//     <div className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center  text-capitalize'>
//       <div className=' d-flex justify-content-end'>
//         <div
//           className={`w-15px h-15px me-2 rounded-3 border-i${item.severity} border border-2 `}
//         />
//       </div>
//       {item.severity}
//     </div>
//     <span className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center justify-content-center '>
//       {item.target}
//     </span>
//     <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
//       {item.host}
//     </div>
//     <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
//       <ConvertDate date={item.timestamp} />
//     </div>
//   </div>
// </div>
