import React, {memo, useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import Warning2 from '../../images/Warning2'
import {Button} from 'react-bootstrap'
import Tool from '../../images/Tool'
interface ConfigItem {
  value: string | null
  status: boolean
  title: string
  discripton: string // Note: keeping the typo as it's in the original data
  command: string
}
const findType = (item: any) => {
  switch (item?.type) {
    case 'string':
      if (item?.options) {
        return (
          <select
            className='form-select form-select py-1 w-75'
            data-kt-select2='true'
            data-placeholder={item.label}
            data-allow-clear='true'
          >
            {item?.options?.map((item: any, ind: number) => (
              <option key={ind} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        )
      }
      return (
        <input
          type='text'
          className='form-control p-1 w-75'
          placeholder={item.label}
          aria-describedby='basic-addon1'
        />
      )
    case 'number':
      return (
        <input
          type='number'
          className='form-control p-1 w-75'
          placeholder={item.label}
          aria-describedby='basic-addon1'
        />
      )
    case 'boolean':
      return (
        <>
          <input
            id={`btn${item?.name}`}
            className='form-check-input check_box'
            role="switch"
            type={'checkbox'}
            onChange={(e) => {}}
          />
          <label htmlFor={`btn${item?.name}`}></label>

        </>
      )
    case 'select':
      return (
        <select className='custom-select' id='inputGroupSelect01'>
          {item?.options?.map((item: any, ind: number) => (
            <option key={ind} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      )
    default:
      return (
        <input
          type='text'
          className='form-control'
          placeholder='Username'
          aria-label='Username'
          aria-describedby='basic-addon1'
        />
      )
  }
}
type DynamicConfig = ConfigItem[]
const DrawerConfig = memo(
  ({
    show,
    setShow,
    setOtherDrawer,
    details,
  }: {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setOtherDrawer: React.Dispatch<React.SetStateAction<boolean>>
    details: DynamicConfig | null | any
  }) => {
    const [detailsState, setDetailsState] = useState(details)
    useLayoutEffect(() => {
      setDetailsState(details)
    }, [details])
    const intl = useIntl()
    return (
      <>
        {/* {start drawer} */}
        <div
          id='kt_Drawer_Config'
          className={`bg-body drawer drawer-end overflow-visible ${
            show ? 'drawer-on' : ''
          } work_drawer bg-secondary card`}
          data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
          style={{zIndex: show ? 9 : 10, minWidth: 380, maxWidth: 380}}
        >
          <div className='node_item'>
            <div className='h-100 drawer-content'>
              <div className='border-0 h-100 card'>
                <div className='p-4 h-100 card-body'>
                  {detailsState ? (
                    <>
                      <Button>{intl.formatMessage({id: 'Save'})}</Button>
                      <div className='my-4 input-group'>
                        <span className='border-end-0 input-group-text'>
                          <i className='text-gray-500 fa-search fas'></i>
                        </span>
                        <input
                          type='text'
                          className='border-start-0 form-control ps-0'
                          placeholder={`${intl.formatMessage({id: 'Search'})}...`}
                        />
                      </div>
                      {/* <div className='custom-accordion accordion' id='kt_accordion_3'>
                        <div className='config-details'>
                          <div className='mb-3 border rounded-3 accordion-item'>
                            <h2 className='accordion-header' id={`kt_accordion_3_header_1`}>
                              {' '}
                              <button
                                className='fw-semibold accordion-button collapsed fs-5'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#kt_accordion_3_body_1`}
                                aria-expanded='false'
                                aria-controls={`kt_accordion__body_1`}
                              >
                                {intl.formatMessage({id: 'Enable config'})}
                              </button>
                            </h2>
                            <div
                              id={`kt_accordion_3_body_1`}
                              className='gap-2 p-2 accordion-collapse collapse'
                              aria-labelledby={`kt_accordion_3_header_1`}
                              data-bs-parent='#kt_accordion_3'
                            >
                              {detailsState.map((item:any, index:number) =>
                                item.status ? (
                                  <div
                                    key={index}
                                    className='d-flex flex-column gap-2 bg-light mb-3 p-2 rounded config-item'
                                  >
                                    <strong className='text-gray-700'>{item.title}</strong>
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                      <input
                                        type='text'
                                        className='form-control m-1 p-1'
                                        defaultValue={item.value || ''}
                                      />
                                      <input
                                        id={`btn${index}`}
                                        className='check_box'
                                        type='checkbox'
                                        checked={item.status}
                                        onChange={(e) => {
                                          if (detailsState) {
                                            const copy = [...detailsState]
                                            copy[index].status = e.target.checked
                                            setDetailsState(copy)
                                          }
                                        }}
                                      />
                                      <label htmlFor={`btn${index}`}></label>
                                    </div>
                                  </div>
                                ) : null
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className=''>
                        {detailsState.fields.map((item: any, index: number) => (
                          <div
                            key={index}
                            className='d-flex gap-2 bg-light mb-3 p-2 rounded config-item'
                          >
                            <div
                              data-tooltip-id={`${index}`}
                              data-tooltip-content={item.label}
                              style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                            >
                              <Warning2 className='image_change_theme2' svgClassName='info-icon' />
                            </div>
                            <ReactTooltip
                              id={`${index}`}
                              style={{
                                borderRadius: 5,
                                maxWidth: 200,
                                fontSize: 12,
                                textAlign: 'center',
                                zIndex: 10001,
                              }}
                            />

                            <div className='d-flex justify-content-between align-items-center w-100'>
                              <strong className='text-gray-700'>{item.name}</strong>
                              {findType(item)}
                              {/* <input
                                id={`btn${index}`}
                                // className='check_box'
                                type={item.type}
                                checked={item.status}
                                onChange={(e) => {}}
                              />
                              <label htmlFor={`btn${index}`}></label> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className='py-4 text-center text-gray-600'>
                      <i className='mb-3 fa-info-circle fas fs-2'></i>
                      <p className='mb-0'>
                        {intl.formatMessage({
                          id: 'To display the configuration, first select the desired node.',
                        })}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className='bg-secondary work_drawer_button_config'
            style={{border: 'none'}}
            onClick={() => {
              setShow(!show)

              if (!show) {
                setOtherDrawer(false)
              }
            }}
          >
            <div className='px-0 w-auto aside-toggle btn btn-active-color-primary btn-icon'>
              <Tool className='svg-icon-2x svg-icon-primary' />
            </div>
          </button>
        </div>
        {/* {end drawer} */}
      </>
    )
  }
)
export default DrawerConfig
