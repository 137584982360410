import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

export default function VerifyFree() {
    const intl = useIntl()
    const navigate = useNavigate()
    useEffect(()=>{
        setTimeout(() => {
            navigate('/payment-history')
          }, 5000)
    },[])
  return (
    <div>
    <div className='container'>
      {/* Successful payment message */}
        <div className='row justify-content-center'>
          <div className='col-md-5 w-50'>
            <div className='message-box _success '>
              <i className='fa fa-check-circle' aria-hidden='true'></i>
              <h2>
                {intl.formatMessage({ id: 'Your payment has been successfully completed' })}
              </h2>
            </div>
          </div>
        </div>
        </div></div>
  )
}
