import { useCallback, useState } from "react";

const useHistoryState = (initialNodes = [], initialEdges = []) => {
  const [history, setHistory] = useState([{ nodes: initialNodes, edges: initialEdges }]);
  const [currentStep, setCurrentStep] = useState(0);
  
  const pushToHistory = useCallback((nodes:any, edges:any) => {
    setHistory(prev => {
      const newHistory = prev.slice(0, currentStep + 1);
      return [...newHistory, { nodes, edges }];
    });
    setCurrentStep(prev => prev + 1);
  }, [currentStep]);

  const undo = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
      return history[currentStep - 1];
    }
    return history[currentStep];
  }, [currentStep, history]);
  
  const redo = useCallback(() => {
    if (currentStep < history.length - 1) {
      setCurrentStep(prev => prev + 1);
      return history[currentStep + 1];
    }
    return history[currentStep];
  }, [currentStep, history]);

  // Reset history with new initial state
  const resetHistory = useCallback((nodes:any, edges:any) => {
    setHistory([{ nodes, edges }]);
    setCurrentStep(0);
  }, []);
  return {
    pushToHistory,
    undo,
    redo,
    resetHistory,
    canUndo: currentStep > 0,
    canRedo: currentStep < history.length - 1,
  };
};

export default useHistoryState