/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {VUL_DETAILS_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {SetAiAssistantVulnerabilitiesID} from '../../../modules/auth'
import {useDispatch} from 'react-redux'
import {VulnerabilitiesResult, VulnerabilityDetails} from '../../../modules/types/vulnerabilities'
interface Props {
  setId: React.Dispatch<React.SetStateAction<string | null>>
  id: string | null
}
interface Alert {
  status: boolean
  massage: string
}
export const DetailsDrawer = ({id, setId}: Props) => {
  const [data, setData] = useState<VulnerabilitiesResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [commentLoading, setCommentLoading] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const [commentMassage, setCommentMassage] = useState<Alert | null>(null)
  const errorHandling = useErrorHandling()
  const lang = useLang()?.lang.selectedLang
  const dispatch = useDispatch()
  const intl = useIntl()
  /**
   * Generates the content for displaying severity levels.
   * 
   * @param severity - The severity level of the vulnerability. Can be 'info', 'low', 'medium', 'high', 'critical', or undefined.
   * @returns A JSX element representing the severity level with appropriate styling and text.
   */
  const severityContent = (severity: string | undefined) => {
    switch (severity) {
      case 'info':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-iinfo me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Info Severity'})} </span>
          </>
        )
      case 'low':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ilow me-3`} />
            <span className='m-auto'> {intl.formatMessage({id: 'Low Severity'})} </span>
          </>
        )

      case 'medium':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-imedium me-1`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Medium Severity'})} </span>
          </>
        )

      case 'high':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-ihigh me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'High Severity'})} </span>
          </>
        )

      case 'critical':
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-icritical me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'Critical Severity'})} </span>
          </>
        )

      default:
        return (
          <>
            <div className={`w-15px h-15px rounded-3 border border-2 border-white me-3`} />
            <span className='m-auto'>{intl.formatMessage({id: 'None'})}</span>
          </>
        )
    }
  }

  /**
   * Fetches vulnerability detail data from the API.
   * 
   * This function sends a GET request to retrieve vulnerability details for a specific ID.
   * It handles the loading state, error handling, and data setting.
   * 
   * @async
   * @function fetchVulnerabilityDetailData
   * @throws {Error} Logs any error that occurs during the API call
   * @returns {Promise<void>} Does not return a value, but updates component state
   */
  const fetchVulnerabilityDetailData = async () => {
    setLoading(true)
    setData(null)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  /**
   * Fetches vulnerability details when the component mounts or when the id changes.
   * 
   * This effect hook is responsible for loading vulnerability data either from a demo source
   * or by calling an API, depending on the application mode.
   * 
   * @effect
   * @param {string | null} id - The id of the vulnerability to fetch details for.
   * @returns {void}
   */
  useEffect(() => {
    if (id !== null) {
      if (process.env.REACT_APP_MODE === 'demo') {
        setData(VUL_DETAILS_DATA)
      } else {
        fetchVulnerabilityDetailData()
      }
    }
  }, [id])

  /**
   * Sends a comment for a vulnerability to the server.
   * 
   * This function posts the comment to the server, updates the UI state based on the response,
   * and refreshes the vulnerability details. It handles both successful and failed comment submissions.
   * 
   * @function
   * @async
   * @throws {Error} Logs any error that occurs during the API call
   * @returns {Promise<void>} Does not return a value, but updates component state
   */
  function sendComment() {
    setCommentLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${id}/comment/`, {
        comment: commentValue,
      })
      .then((res) => {
        setCommentValue('')
        setCommentLoading(false)
        setCommentMassage({
          status: true,
          massage: intl.formatMessage({id: 'Your comment has been sent'}),
        })
        fetchVulnerabilityDetailData()
      })
      .catch((e) => {
        console.error(e)
        console.log('message \n', e?.response?.data?.error)
        setCommentLoading(false)
        setCommentMassage({
          status: false,
          massage: intl.formatMessage({id: 'Your comment was not sent'}),
        })
      })
      .finally(() => {
        setTimeout(() => {
          setCommentMassage(null)
        }, 10000)
      })
  }

  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        <>
          {/* begin::Card  */}
          <div className='shadow-none w-100 card' style={{height: 'fit-content'}}>
            {/* begin::Header */}
            <div className='card-header' id='kt_explore_header'>
              <h5 className='fw-bold text-gray-600 card-title'>{!loading && data?.cve}</h5>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-icon btn-sm explore-btn-dismiss me-n5'
                  id='kt_engage_filters_close'
                  onClick={() => {
                    setId(null)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='image_change_theme2 svg-icon-2'
                  />
                </button>
              </div>
            </div>
            {/* end::Header */}

            <>
              {/* begin::Body */}
              <div
                className={`card-body d-flex flex-column gap-7 ${loading && 'skeleton'}`}
                id='kt_explore_body'
              >
                {/* begin::Content */}
                <button
                  onClick={() => {
                    dispatch(SetAiAssistantVulnerabilitiesID({id: id, title: data?.title}))
                  }}
                  id='kt_drawer_chat_toggle'
                  className='btn btn-primary btn-sm'
                  type='button'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_edit'
                >
                  {intl.formatMessage({id: 'Send to AI Assisstant'})}
                </button>
                <div className='d-flex flex-column justify-content-start'>
                  <div className='p-1 h-auto text-gray-700'>
                    {intl.formatMessage({id: 'AFFECTED ENTITY'})}{' '}
                  </div>
                  <div className='d-flex align-items-center mt-2 p-1 w-200px h-auto text-primary'>
                    {data?.host}
                  </div>
                </div>

                <div className='d-flex flex-column justify-content-start'>
                  <div className='p-1 w-200px h-auto text-gray-700'>
                    {intl.formatMessage({id: 'Target'})}{' '}
                  </div>
                  <div className='d-flex align-items-center mt-1 p-1 w-200px h-auto text-gray-800'>
                    {/* <div
                      className={`w-30px h-30px bg-gray-500 text-white rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                    >
                      {data?.tag}
                    </div> */}
                    {data?.target}
                  </div>
                </div>

                <div>
                  <span className='p-1 min-w-200px h-auto text-gray-700'>
                    {intl.formatMessage({id: 'Risk'})}
                  </span>
                  <div className='d-flex align-items-center bg-secondary my-2 px-3 py-1 rounded-3 mw-200px'>
                    {severityContent(data?.severity)}
                  </div>
                </div>

             { data?.port&&  <div className='d-flex flex-column justify-content-start'>
                  <div className='p-1 min-w-200px h-auto text-gray-700'>
                    {intl.formatMessage({id: 'Port'})}
                  </div>
                  <div className='mt-2 p-1 text-gray-900'>{data?.port}</div>
                </div>}
                <div className='d-flex flex-column justify-content-start'>
                  <div className='p-1 w-150px h-auto text-gray-700'>
                    {' '}
                    {intl.formatMessage({id: 'Last Seen'})}{' '}
                  </div>
                  <span className='mt-2 p-1 w-150px h-auto text-gray-900'>
                    <ConvertDate
                      date={data?.timestamp || ''}
                      jalali={data?.timestamp_jalali || ''}
                    />
                  </span>
                </div>
                <div>
                  <div className='p-1 min-w-200px h-auto text-gray-700'>
                    {intl.formatMessage({id: 'Description'})}
                  </div>
                  <div
                    className='my-2'
                    dangerouslySetInnerHTML={{
                      __html: lang === 'fa' ? data?.description_fa || '' : data?.description || '',
                    }}
                  ></div>
                </div>
                <div className='d-flex flex-column'>
                  <label className='form-label'>{intl.formatMessage({id: 'Comment'})}</label>
                  <div className='my-2'>{data?.comment}</div>
                  <textarea
                    onChange={(e) => {
                      setCommentValue(e.target.value)
                    }}
                    value={commentValue}
                    rows={1}
                    className='form-control form-control-solid'
                    data-kt-autosize='true'
                    placeholder={intl.formatMessage({id: 'Comment'})}
                  ></textarea>
                </div>
                <div>
                  {!!commentMassage && (
                    <p style={commentMassage.status ? {color: 'green'} : {color: 'red'}}>
                      {commentMassage.massage}
                    </p>
                  )}
                  {process.env.REACT_APP_MODE === 'demo' && (
                    <p className='text-danger'>
                      {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                    </p>
                  )}

                  <button
                    className='w-100 btn btn-primary btn-sm'
                    disabled={!commentValue || process.env.REACT_APP_MODE === 'demo'}
                    onClick={sendComment}
                  >
                    {commentLoading
                      ? `${intl.formatMessage({id: 'Sending'})}...`
                      : intl.formatMessage({id: 'Send Comment'})}
                  </button>
                </div>
                {/* end::Content */}
              </div>
              {/* end::Body */}
            </>
          </div>
          {/* begin::Card */}
        </>
      </div>
      {/* end::Demos drawer */}
    </>
  )
}
