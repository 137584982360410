import React from 'react'
import './Card.scss'
import {useNavigate} from 'react-router-dom'

/**
 * CardProvider component that wraps content in a clickable card
 * @param {Object} props - Component props
 * @param {string} props.link - The navigation link for the card
 * @param {React.ReactNode} props.children - The content to be rendered inside the card
 * @param {boolean} props.loading - Indicates whether the card is in a loading state
 * @returns {JSX.Element} Rendered CardProvider component
 */
export default function CardProvider ({
  link,
  children,
  loading
}: {
  link: string,
  children: React.ReactNode,
  loading: boolean
}) {
  const navigate = useNavigate()

  return (
    <div
      className={`card_custom bg-secondary card ${loading ? 'skeleton' : ''}`}
      onClick={() => {
        navigate(link)
      }}
      style={{height:'fit-content', width:'100%'}}
    >
      {children}
    </div>
  )
}

