import { useIntl } from 'react-intl'
import arrow from './assets/Down Left Arrow.svg'
interface Props {
  icon: JSX.Element
  name: string
  count: number|string|null | undefined
}
export default function Header ({icon, name = '', count = 0}: Props) {
  const intl = useIntl()
  return (
    <>
      <div className='card_hade'>
        <div className='card_icon'>
          <div className={'domain bg-body'}>{icon}</div>
          <span>{count === null || count === undefined ? '':count}</span>
        </div>
        <img src={arrow} className='card_arrow image_change_theme' alt='arrow' />
      </div>
      <div className='card_name'>
        <strong style={{fontSize:20}}>{intl.formatMessage({id: name})}</strong>
      </div>
    </>
  )
}
