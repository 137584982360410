/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

/**
 * Interface representing the structure of chart data
 * @interface ChartData
 */
interface ChartData {
  x: string[] // X-axis categories/labels
  y: {
    name: string // Series name
    data: number[] // Data points for the series
  }[]
}

/**
 * Props type for the LineChart component
 * @typedef {Object} Props
 * @property {ChartData} chartData - The data used to render the chart
 */
type Props = {
  chartData: ChartData
}

/**
 * LineChart Component - Renders an area chart using ApexCharts
 * 
 * @component
 * @param {Props} props - Component properties
 * @returns {React.FC} Rendered line chart
 */
const LineChart: React.FC<Props> = memo(({chartData}) => {
  // Reference to the chart container
  const chartRef = useRef<HTMLDivElement | null>(null)

  /**
   * useEffect hook to handle chart rendering and cleanup
   * 
   * @effect
   * @description Initializes and destroys ApexChart instance based on chartData
   */
  useEffect(() => {
    // Guard clause to prevent rendering if no chart reference exists
    if (!chartRef.current) {
      return
    }

    // Create ApexCharts instance with dynamic configuration
    const chart = new ApexCharts(chartRef.current, getChartOptions(chartData))
    
    // Render the chart if instance is created successfully
    if (chart) {
      chart.render()
    }

    // Cleanup function to destroy chart on component unmount
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, chartData])

  return (
    <div className='w-50 h-100'>

    <div
      ref={chartRef}
      id='kt_charts_widget_8_chart2'
      style={{height: '100%', width: '100%', maxWidth: 'calc(100% - 10px)'}}
      />
      </div>
  )
})

export {LineChart}

/**
 * Generates ApexCharts configuration options
 * 
 * @function getChartOptions
 * @param {ChartData} chartData - Chart data used to configure the chart
 * @returns {ApexOptions} Configured chart options
 */
function getChartOptions(chartData: ChartData): ApexOptions {
  // Define color variables using CSS custom properties
  const labelColor = '#474761'
  const strokeColor = '#000'
  const color1 = getCSSVariableValue('--bs-primary')
  const color2 = getCSSVariableValue('--bs-success')
  const color3 = getCSSVariableValue('--bs-primary')

  return {
    // Chart series data configuration
    series: chartData.y,

    // Basic chart configuration
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      width: '100%',
      height: '100%',
      toolbar: {
        show: false, // Disable toolbar
      },
      zoom: {
        enabled: false, // Disable zoom functionality
      },
    },

    // Styling and visual configurations
    plotOptions: {},
    legend: {
      show: false, // Hide legend
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },

    // Stroke configuration for line/area
    stroke: {
      curve: 'smooth', // Smooth curve for lines
      show: true,
      width: 2,
      colors: ['#02694a', '#255a8f', '#9c8705', '#c02407', '#75041e'],
    },

    // X-axis configuration
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      type: 'datetime',
      categories: chartData.x.length === 1 
        ? ['start', ...chartData.x] 
        : chartData.x,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    // Y-axis configuration
    yaxis: {
      opposite: true,
      labels: {
        show: false,
      },
    },

    // Additional chart configurations
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        },
      },
    },

    // Tooltip configuration
    tooltip: {
      enabled: false,
    },

    // Color palette
    colors: [
      '#02694a20', 
      '#255a8f30', 
      '#9c870540', 
      '#c0240720', 
      '#75041e60'
    ],

    // Grid configuration
    grid: {
      borderColor: labelColor,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },

    // Marker configuration
    markers: {
      colors: [color1, color2, color3],
      strokeColors: [color1, color2, color3],
      strokeWidth: 0,
    },
  }
}