import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import PageLoading from '../../../app/pages/loading/PageLoading'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {SetAiAssistantQuestionList} from '../../../app/modules/auth'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import Like from '../../../app/pages/images/Like'
import {toAbsoluteUrl} from '../../helpers'
import {Link} from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import usePermission from '../../../Utils/usePermission'
import { ChatBotType } from '../../../app/modules/types/chatBot'
type Props = {
  isDrawer?: boolean
}

const ChatInner: FC<Props> = ({isDrawer = false}) => {
  const targetRef = useRef<HTMLDivElement | null>(null)
  const [questionId, setQuestionId] = useState<string>('')
  const [status, setStatus] = useState<'pending' | 'accepted' | 'failed'>('accepted')
  const [chatList, setChatList] = useState<ChatBotType[]|null>(null)
  const dispatch = useDispatch()
  const {isPermission, getPermission} = usePermission()
  const errorHandling = useErrorHandling()
  const questionList = useSelector((state: RootState) => state.root.aiAssistant.questionList)
  const vulnerabilities = useSelector(
    (state: RootState) => state.root.aiAssistant.vulnerabilitiesSelected
  )
  let checkStatus: NodeJS.Timeout | undefined
  const sendMessage = () => {
    if (vulnerabilities.id && questionId) {
      setStatus('pending')
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/`, {
          question: questionId,
          vulnerability: vulnerabilities.id,
        })
        .then((res) => {
          if (res.data.answer) {
            fetchChatList()
          } else {
            checkStatus = setInterval(() => {
              fetchChatStatus(res.data.id)
            }, 10000)
          }
        })
        .catch((e) => {
          console.error(e)
          console.log('message \n', e?.response?.data?.error)
        })
        .finally(() => {})
    }
  }
  const fetchQuestionList = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/questions/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetAiAssistantQuestionList(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/chatbot/questions/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchChatList = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        setChatList(data.data)
        setStatus('accepted')
        setQuestionId('')
      } else {
        clearInterval(checkStatus)
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/chatbot/questions/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchChatStatus = async (id: string) => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/${id}/status/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        console.log(data.data)
        if (data.data === 'pending') {
        } else {
          clearInterval(checkStatus)
          if (data.data === 'accepted') {
            fetchChatList()
          } else {
            setStatus('failed')
          }
        }
      } else {
        clearInterval(checkStatus)
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/chatbot/${id}/status/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  const sendFeedback = (id: string, feedback: boolean) => {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/${id}/feedback/`, {
        is_helpful: feedback,
      })
      .then((res) => {
        fetchChatList()
      })
      .catch((e) => {
        console.error(e)
        console.log('message \n', e?.response?.data?.error)
      })
      .finally(() => {})
  }

  useEffect(() => {
    if (!questionList.length) {
      fetchQuestionList()
    }
    fetchChatList()
    return () => {
      clearInterval(checkStatus)
    }
  }, [])
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }, [chatList, status])
  return (
    <div
      className='card-body overflow-hidden position-relative'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      style={{height: 'calc(100% - 70px)'}}
    >
      {isPermission()}
      <div
        className={clsx('scroll-y me-n5 pe-5  ', {'h-100 h-lg-auto': !isDrawer})}
        style={{height: 'calc(100% - 130px)'}}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {chatList?.map((message, index: number, arr: ChatBotType[]) => {
          return (
            <div
              key={`message${index}`}
              className={' d-flex flex-column gap-3 mb-10'}
              ref={arr.length - 1 === index && status === 'accepted' ? targetRef : null}
            >
              {/* Question */}
              <div className='d-flex flex-column gap-3 align-items-end mw-400px align-self-end'>
                <div className='d-flex align-items-center'>
                  <div className='me-3'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                      You
                    </a>
                  </div>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src={toAbsoluteUrl(`media/avatars/blank.png`)} />
                  </div>
                </div>
                <div className='card  p-5 rounded bg-light-info text-gray-900 fw-bold  `text-end'>
                  <h3>{message.vulnerability}</h3>
                  <span>
                    <strong>Question : </strong> {message?.question}
                  </span>
                </div>
              </div>
              {/* Answer */}
              <div className='d-flex flex-column gap-3 mw-400px'>
                <div className='d-flex  align-items-center'>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src={toAbsoluteUrl(`ai.webp`)} />
                  </div>

                  <div className='ms-3'>
                    <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                      Ai
                    </a>
                  </div>
                </div>
                <div className='card card  p-5 rounded bg-light-primary text-gray-900 fw-bold  text-start'>
                  <p>
                    <strong>Answer : </strong> <ReactMarkdown>{message?.answer}</ReactMarkdown>
                  </p>
                </div>
                <div className=' d-flex gap-5'>
                  <div
                    onClick={() => {
                      sendFeedback(message.id, true)
                    }}
                  >
                    <Like
                      className={` svg-icon-2x pointer ${
                        message.is_helpful ? 'svg-icon-success' : 'image_change_theme2'
                      } `}
                      svgClassName=' align-baseline'
                    />
                  </div>
                  <div
                    className='rotate_180'
                    onClick={() => {
                      sendFeedback(message.id, false)
                    }}
                  >
                    <Like
                      className={` svg-icon-2x pointer  ${
                        message.is_helpful !== null && message.is_helpful === false
                          ? 'svg-icon-danger'
                          : 'image_change_theme2'
                      } `}
                      svgClassName=' align-baseline'
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        {status !== 'accepted' && (
          <div className={'  d-flex flex-column gap-3 mb-10'}>
            {/* Question */}
            <div
              className={`d-flex flex-column gap-3 align-items-end mw-400px align-self-end${
                status === 'failed' && 'bg-danger'
              }`}
            >
              <div className='d-flex align-items-center'>
                <div className='me-3'>
                  <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                    You
                  </a>
                </div>
                <div className='symbol  symbol-35px symbol-circle '>
                  <img alt='Pic' src={toAbsoluteUrl(`media/avatars/blank.png`)} />
                </div>
              </div>
              <div className='card  p-5 rounded bg-light-info text-gray-900 fw-bold  `text-end'>
                {/* <h3>{message.vulnerability}</h3> */}
                <span>
                  <strong>Question : </strong>{' '}
                  {questionList?.find((item) => item.id === questionId)?.content || ''}
                </span>
              </div>
            </div>
            {/* Answer */}
            <div className='d-flex flex-column gap-3 mw-400px position-relative' ref={targetRef}>
              <div className='d-flex  align-items-center'>
                <div className='symbol  symbol-35px symbol-circle '>
                  <img alt='Pic' src={toAbsoluteUrl(`ai.webp`)} />
                </div>

                <div className='ms-3'>
                  <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                    Ai
                  </a>
                </div>
              </div>
              {status === 'pending' ? (
                <div className='h-50px'>
                  <div className=' position-absolute ' style={{bottom: 40, left: 40}}>
                    <PageLoading />
                  </div>
                </div>
              ) : (
                <div className='card card  p-5 rounded bg-light-primary text-gray-900 fw-bold  `text-start'>
                  <p>
                    <strong>Answer : </strong>{' '}
                    <span>
                      {status === 'failed' &&
                        'Sorry, there was a problem. Please ask your question again'}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {vulnerabilities.id ? (
        <div
          className='card-footer pt-4 pb-0'
          id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        >
          {/* <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea> */}
          <div className='mb-3 '>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Severity Filter'
              data-allow-clear='true'
              value={questionId}
              onChange={(e) => {
                setQuestionId(e.target.value)
              }}
              disabled={status === 'pending'}
            >
              <option value=''>Question</option>
              {questionList.length ? (
                questionList.map((item) => (
                  <option style={{textTransform: 'capitalize'}} key={item.id} value={item.id}>
                    {item.content}
                  </option>
                ))
              ) : (
                <PageLoading />
              )}
            </select>
            {!questionId && <p className='text-danger m-0'>Question is required</p>}
          </div>
          <div className='d-flex flex-stack'>
            {/* <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div> */}
            <button
              className='btn btn-primary  w-100 '
              type='button'
              data-kt-element='send'
              onClick={sendMessage}
              disabled={!questionId || status === 'pending'}
            >
              {status === 'pending' ? 'Wait for the answer...' : 'Send'}
            </button>
          </div>
        </div>
      ) : (
        <h2 className='mt-15 text-center'>
          First select the <Link to='vulnerabilities'>Vulnerability</Link>
        </h2>
      )}
    </div>
  )
}

export {ChatInner}
