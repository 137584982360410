import React, {memo} from 'react'
import './toltip.scss'

/**
 * Represents a data point in the timeline
 * @typedef {Object} Data
 * @property {string} date - The date of the data point
 * @property {number|null} percentage - The percentage value for the data point
 */
interface Data {
  date: string
  percentage: number | null
}

/**
 * Determines the color based on the percentage value
 * @param {number|null} percentage - The percentage value
 * @returns {string} The color corresponding to the percentage
 */
function choseColor (percentage: number | null): string {
  if (percentage) {
    switch (true) {
      case percentage > 0 && percentage <= 25:
        return 'red'
      case percentage > 25 && percentage <= 50:
        return 'orange'
      case percentage > 50 && percentage <= 75:
        return 'yellow'
      case percentage > 75:
        return 'green'
      default:
        return 'gray'
    }
  }
  return 'gray'
}

/**
 * Timeline component displays a series of data points as a timeline
 * @param {Object} props - Component props
 * @param {Data[]} props.data - Array of data points to display in the timeline
 */
const Timeline = memo(function ({data}: {data: Data[]}) {
  return (
    <>
      <div className='w-100 h-40px d-flex justify-content-between'>
        {data?.map((item, index) => (
          <div
            className='h-100  rounded-pill tooltip_tt'
            key={index}
            style={{backgroundColor: '#fff', width: `calc(100% / ${data.length * 2})`}}
          >
            {/* Timeline item */}
            <div
              className='h-100 w-100 timeline_hover rounded-pill '
              style={{
                backgroundColor: choseColor(item.percentage),
              }}
            >
            </div>
            {/* Tooltip */}
            <div className='tooltip_text_tt '>
              <span>{new Date(item.date).toLocaleDateString()}</span>
              <p>{item.percentage ? item.percentage + '%' : 'No data'}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
})

export default Timeline

