import {
  addEdge,
  Connection,
  Controls,
  Edge,
  getOutgoers,
  Node,
  OnEdgesChange,
  OnNodesChange,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react'
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import CustomEdge from './CustomEdge'
import CustomDefaultNode from './CustomDefaultNode'
import {DragHandlerProps, DynamicConfig} from './CreateDAG'
import {KTSVG} from '../../../../_metronic/helpers'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import usePermission from '../../../../Utils/usePermission'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'sonner'
import './WorkFlow.scss'
import {Tooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import useHistoryState from '../../../../Utils/useHistoryState'
import Undo from '../../images/Undo'
import Redo from '../../images/Redo'
import Draft from '../../images/Draft'
import Reloade from '../../images/Reloade'
interface CustomEdge {
  markerEnd?: string
}
const edgeTypes = {
  default: CustomEdge,
}

const nodeTypes = {
  default: CustomDefaultNode,

}
/**
 * Flow component for managing and displaying a workflow.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.Dispatch<React.SetStateAction<DynamicConfig | null>>} props.setDetails - Function to set the details of a node.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpenConfigDrawer - Function to open or close the configuration drawer.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpenDagDrawer - Function to open or close the DAG drawer.
 * @param {DragHandlerProps | undefined} props.selectedNodeItem - The currently selected node item.
 *
 * @returns {JSX.Element} The rendered Flow component.
 *
 * @example
 * <Flow
 *   setDetails={setDetails}
 *   setIsOpenConfigDrawer={setIsOpenConfigDrawer}
 *   setIsOpenDagDrawer={setIsOpenDagDrawer}
 *   selectedNodeItem={selectedNodeItem}
 * />
 */
function Flow ({
  setDetails,
  setIsOpenConfigDrawer,
  setIsOpenDagDrawer,
  selectedNodeItem,
}: {
  setDetails: React.Dispatch<React.SetStateAction<DynamicConfig | null>>
  setIsOpenConfigDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenDagDrawer: React.Dispatch<React.SetStateAction<boolean>>
  selectedNodeItem: DragHandlerProps | undefined
}) {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[] | any>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[] | any>([])
  const [loading, setLoading] = useState(false)
  const [edgesChange, setEdgesChange] = useState<any[]>([])
  const [nodesChange, setNodesChange] = useState<any[]>([])

  const [saveLoading, setSaveLoading] = useState(false)
  const [draftLoading, setDraftLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const errorHandling = useErrorHandling()
  const {getPermission, isPermission} = usePermission()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const {getNodes, getNode, getEdges, screenToFlowPosition, getHandleConnections} = useReactFlow()
  const intl = useIntl()
  const navigate = useNavigate()
  const {pushToHistory, undo, redo, canUndo, canRedo, resetHistory} = useHistoryState()
  const onDragOver = useCallback((event: any) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  /**
   * Fetches the flow list from the API and updates the state with the retrieved data.
   *
   * This function performs the following steps:
   * 1. Sets the loading state to true.
   * 2. Makes an API call to fetch the workflow data using the provided `id`.
   * 3. Handles errors and permissions based on the response status.
   * 4. If the response status is within the 200-299 range, updates the state with the fetched data.
   * 5. Logs an error message if the response status is outside the 200-299 range.
   * 6. Catches and logs any errors that occur during the API call.
   * 7. Sets the loading state to false once the API call is complete.
   *
   * @async
   * @function fetchFlowList
   * @returns {Promise<void>} A promise that resolves when the API call is complete and the state is updated.
   */
  const fetchFlowList = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/${id}/`)
      errorHandling(data.status)
      getPermission(data.status)

      if (data.status >= 200 && data.status < 300) {
        setName(data.data.name)
        setNodes(data.data.graph.nodes)
        setEdges(data.data.graph.connectors)
        resetHistory(data.data.graph.nodes, data.data.graph.connectors)
      } else {
        console.error(`Error fetching DNS data:`, data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    fetchFlowList()
  }, [id])

  /**
   * Adds a new node to the workflow.
   * @param {Node} item - The node to be added.
   * @returns {void}
   */
  function addNode (item: Node) {
    const copy = [...nodes]
    copy.push(item)
    setNodes(copy)
    pushToHistory(copy, edges)
  }

  const onConnect = useCallback(
    (params: Connection) => {
      setEdges((eds) => {
        const newEdges = addEdge(params, eds)
        pushToHistory(nodes, newEdges)
        return newEdges
      })
      toast.dismiss()
    },
    [nodes, setEdges, pushToHistory]
  )

  const handleNodesChange: OnNodesChange = useCallback(
    (changes) => {
      onNodesChange(changes)
      setNodesChange(changes)
    },
    [nodes, edges, onNodesChange, pushToHistory]
  )

  const handleEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      setEdgesChange(changes)
      onEdgesChange(changes)
    },
    [nodes, edges, onEdgesChange, pushToHistory]
  )

  // Handle undo
  const handleUndo = useCallback(() => {
    const previousState = undo()
    setNodes(previousState.nodes)
    setEdges(previousState.edges)
  }, [undo, setNodes, setEdges])

  // Handle redo
  const handleRedo = useCallback(() => {
    const nextState = redo()
    setNodes(nextState.nodes)
    setEdges(nextState.edges)
  }, [redo, setNodes, setEdges])

  /**
   * Handles the drop event for workflow items.
   * @param {React.DragEvent<HTMLDivElement>} e - The drop event.
   * @returns {void}
   */
  function dropHandler (e: React.DragEvent<HTMLDivElement>) {
    if (selectedNodeItem) {
      const position = screenToFlowPosition({
        x: e.clientX,
        y: e.clientY,
      })
      const item: Node = {
        ...selectedNodeItem,
        position,
      }
      ;(item.id = Math.random()?.toString()), addNode(item)
    }
  }

  /**
   * Validates if a connection between nodes is valid by checking for cycles in the graph.
   *
   * @param connection - The connection object containing source and target node IDs.
   * @returns {boolean} - Returns `true` if the connection is valid (no cycles), otherwise `false`.
   *
   * The function uses `getNodes` and `getEdges` helpers to retrieve the current nodes and edges in the graph.
   * It then checks if the connection would create a cycle by traversing the graph from the target node.
   * If a cycle is detected, an error toast message is displayed and the function returns `false`.
   *
   * The function is memoized using `useCallback` to ensure it is only created once.
   */
  const isValidConnection = useCallback(
    (connection: any) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      if (connection.targetHandle === connection.sourceHandle) {
        const targetConnectionsCount = getHandleConnections({
          type: 'target',
          id: connection.targetHandle,
          nodeId: connection.target,
        })
        const nodes = getNodes()
        const edges = getEdges()
        // const target = nodes.find((node) => node.id === connection.target)
        const target = getNode(connection.target)
        const hasCycle = (node: any, visited = new Set()) => {
          if (visited.has(node.id)) {
            toast.dismiss('port')
            toast.error('Cannot connect. This connection will make cycles in the graph.', {
              id: 'cycle',
            })
            return false
          }

          visited.add(node.id)

          for (const outgoer of getOutgoers(node, nodes, edges)) {
            if (outgoer.id === connection.source) {
              return true
            }
            if (hasCycle(outgoer, visited)) {
              return true
            }
          }
        }
        if (!(targetConnectionsCount.length < 1)) {
          toast.dismiss('port')
          toast.dismiss('cycle')
          toast.error('This port has reached the end of the connection limit.', {id: 'limit'})
          return false
        }
        if (target?.id === connection.source) {
          toast.dismiss('port')
          toast.error('Cannot connect. This connection will make cycles in the graph.', {
            id: 'cycle',
          })
          return false
        }
        const isHasCycle = hasCycle(target)
        if (isHasCycle) {
          toast.error('Cannot connect. This connection will make cycles in the graph.', {
            id: 'cycle',
          })
        }
        return !isHasCycle
      } else {
        toast.dismiss('cycle')
        toast.dismiss('limit')
        toast.error('Cannot connect. The port for this connection does not match.', {id: 'port'})
        return false
      }
    },
    [getNodes, getEdges]
  )

  /**
   * Sends a draft of the workflow to the server.
   *
   * This function sends a POST request to the server with the current state of the workflow,
   * including nodes and connectors. It handles loading state and error handling.
   *
   * @async
   * @function workflowDraft
   * @returns {Promise<void>} A promise that resolves when the request is complete.
   *
   * @throws Will throw an error if the request fails.
   */
  const workflowDraft = async () => {
    if (!draftLoading) {
      setDraftLoading(true)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/workflow/${id}/draft/`,
          {
            nodes: nodes,
            connectors: edges,
          }
        )
        errorHandling(response.status)
        getPermission(response.status)
        if (response.status >= 200 && response.status < 300) {
        }
      } catch (error) {
      } finally {
        setDraftLoading(false)
      }
    }
  }

  const workflowDiscard = async () => {
    if (!draftLoading) {
      setLoading(true)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/workflow/${id}/discard/`
        )
        errorHandling(response.status)
        if (response.status >= 200 && response.status < 300) {
          setName(response.data.name)
          setNodes(response.data.nodes)
          setEdges(response.data.connectors)
          resetHistory(response.data.nodes, response.data.connectors)
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Saves the current workflow by sending a POST request to the API endpoint.
   *
   * @async
   * @function workflowSave
   * @returns {Promise<void>} A promise that resolves when the workflow is saved.
   *
   * @description
   * This function performs the following steps:
   * 1. Checks if `saveLoading` is false to prevent multiple submissions.
   * 2. Sets `saveLoading` to true to indicate the save process has started.
   * 3. Sends a POST request to the API endpoint with the current workflow nodes and connectors.
   * 4. Handles the response status by calling `errorHandling` and `getPermission` functions.
   * 5. Displays a success toast message and navigates to the workflows page if the response status is in the 200-299 range.
   * 6. Displays an error toast message if the response status is not in the 200-299 range or if an error occurs during the request.
   * 7. Sets `saveLoading` to false once the save process is complete.
   *
   * @throws {Error} Throws an error if the request fails.
   */
  const workflowSave = async () => {
    if (!saveLoading) {
      setSaveLoading(true)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/workflow/${id}/save/`,
          {
            nodes: nodes,
            connectors: edges,
          }
        )
        errorHandling(response.status)
        getPermission(response.status)
        if (response.status >= 200 && response.status < 300) {
          toast.success('Workflow saved successfully')
          navigate('/workFlow')
        } else {
          toast.error('Error occurred while saving workflow')
        }
      } catch (error) {
        toast.error('Error occurred while saving workflow')
      } finally {
        setSaveLoading(false)
      }
    }
  }

  useEffect(() => {
    const validate = setTimeout(() => {
      workflowDraft()
    }, 10000)

    if (nodesChange.length || edgesChange.length) {
      const nonNodeSelectionAndReplaceChanges = nodesChange.filter(
        (change) =>
          change.type !== 'select' && change.type !== 'position' && change.type !== 'dimensions'
      )
      const nonEdgesSelectionChanges = edgesChange.filter((change) => change.type !== 'select')
      if (nonNodeSelectionAndReplaceChanges.length || nonEdgesSelectionChanges.length) {
        pushToHistory(nodes, edges)
        setNodesChange([])
        setEdgesChange([])
      }
    }
    return () => clearTimeout(validate)
  }, [nodes, edges])

  return (
    <div className={` w-100 h-100 position-relative ${loading ? 'skeleton' : ''}`}>
      <div className={`workflow_toolkit `}>
        <button className='tooltip_tt' onClick={workflowSave}>
          <span
            data-tooltip-id={'my-tooltip Save'}
            data-tooltip-content={intl.formatMessage({id: 'Save'})}
            className='d-flex align-items-center gap-2'
          >
            <KTSVG path={'/media/save.svg'} className={'svg-icon-1 svg-icon-success'} />

            {saveLoading && (
              <span style={{fontSize: 10}}>{intl.formatMessage({id: 'Saving ...'})}</span>
            )}
          </span>
          <Tooltip
            place={'bottom'}
            id={'my-tooltip Save'}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </button>
        <button className='tooltip_tt' onClick={workflowDraft}>
          <span
            data-tooltip-id={'my-tooltip Draft'}
            data-tooltip-content={intl.formatMessage({id: 'Draft'})}
            className='d-flex align-items-center gap-2'
          >
            <div className={` d-flex align-items-center gap-2 `}>
              <Draft className={'svg-icon-1 svg-icon-success'} />
            </div>
            {draftLoading && (
              <span style={{fontSize: 10}}>{intl.formatMessage({id: 'Drafting ...'})}</span>
            )}
          </span>
          <Tooltip
            place={'bottom'}
            id={'my-tooltip Draft'}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </button>
        <button className='tooltip_tt' onClick={workflowDiscard}>
          <span
            data-tooltip-id={'my-tooltip discard'}
            data-tooltip-content={intl.formatMessage({id: 'Discard'})}
            className='d-flex align-items-center gap-2'
          >
            <div className={` d-flex align-items-center gap-2 `}>
              <Reloade className={'svg-icon-1 svg-icon-danger'} />
            </div>
            {loading && (
              <span style={{fontSize: 10}}>{intl.formatMessage({id: 'Discarding ...'})}</span>
            )}
          </span>
          <Tooltip
            place={'bottom'}
            id={'my-tooltip discard'}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </button>
        <button className='tooltip_tt' onClick={handleUndo} disabled={!canUndo}>
          <span
            data-tooltip-id={'my-tooltip undo'}
            data-tooltip-content={intl.formatMessage({id: 'Undo'})}
            className='d-flex align-items-center gap-2'
          >
            <Undo className={'svg-icon-1 svg-icon-warning'} />
          </span>
          <Tooltip
            place={'bottom'}
            id={'my-tooltip undo'}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </button>
        <button className='tooltip_tt' onClick={handleRedo}>
          <span
            data-tooltip-id={'my-tooltip redo'}
            data-tooltip-content={intl.formatMessage({id: 'Redo'})}
            className='d-flex align-items-center gap-2'
          >
            <Redo className={'svg-icon-1 svg-icon-warning'} />
          </span>
          <Tooltip
            place={'bottom'}
            id={'my-tooltip redo'}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </button>
      </div>
      <div className='position-absolute workflow_name' style={{top: -20, left: 0}}>
        <span>{name}</span>
      </div>
      <ReactFlow
        onDragOver={onDragOver}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dropHandler(e)
        }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        isValidConnection={isValidConnection}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onNodeDragStop={() => {
          pushToHistory(nodes, edges)
        }}
        onNodeClick={(_, node) => {
          // setDetails(node?.data?.config as DynamicConfig)
          setIsOpenConfigDrawer(true)
          setIsOpenDagDrawer(false)
        }}
        onConnect={onConnect}
        snapToGrid={false}
        edgeTypes={edgeTypes}
        panOnScroll
        panOnDrag={false}
        fitView
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

/**
 * FlowProvide component wraps the Flow component with ReactFlowProvider.
 *
 * @param {Object} props - The properties object.
 * @param {React.Dispatch<React.SetStateAction<DynamicConfig | null>>} props.setDetails - Function to set the details of the dynamic configuration.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpenConfigDrawer - Function to set the state of the configuration drawer.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpenDagDrawer - Function to set the state of the DAG drawer.
 * @param {DragHandlerProps | undefined} props.selectedNodeItem - The selected node item.
 * @returns {JSX.Element} The FlowProvide component.
 */
const FlowProvide = ({
  setDetails,
  setIsOpenConfigDrawer,
  setIsOpenDagDrawer,
  selectedNodeItem,
}: {
  setDetails: React.Dispatch<React.SetStateAction<DynamicConfig | null>>
  setIsOpenConfigDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenDagDrawer: React.Dispatch<React.SetStateAction<boolean>>
  selectedNodeItem: DragHandlerProps | undefined
}) => (
  <ReactFlowProvider>
    <Flow
      setDetails={setDetails}
      setIsOpenConfigDrawer={setIsOpenConfigDrawer}
      setIsOpenDagDrawer={setIsOpenDagDrawer}
      selectedNodeItem={selectedNodeItem}
    />
  </ReactFlowProvider>
)
export default FlowProvide
