import React, {memo, useLayoutEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import Warning2 from '../../images/Warning2'
import Git from '../../images/Git'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import axios from 'axios'

const DrawerDags = memo(
  ({
    dragHandler,
    show,
    setShow,
    setOtherDrawer,
  }: {
    dragHandler: (item: any) => void
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setOtherDrawer: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
    const [isOpen, setIsOpen] = useState<string | null>(null)
    const [type, setType] = useState<'default' | 'output' | 'input'>('default')
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>(null)
    const errorHandling = useErrorHandling()
    const fetchFlowList = async () => {
      setLoading(true)
      try {
        const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/node-type/`)
        errorHandling(data.status)

        if (data.status >= 200 && data.status < 300) {
          setData(data.data)
        } else {
          console.error(`Error fetching DNS data:`, data)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    useLayoutEffect(() => {
      fetchFlowList()
    }, [])
    const nodeItems = () => {
      return data?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            draggable={true}
            className='workflow-node mb-2'
            onDragStart={() => {
              dragHandler({
                ...item,
                type: type,
              })
            }}
          >
            <div
              data-tooltip-id={`${item.id}${index}`}
              style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
              onClick={() => {
                setIsOpen(`${item.id}${index}`)
              }}
            >
              <Warning2 className=' image_change_theme2' svgClassName='info-icon' />
            </div>
            <ReactTooltip
              events={['click']}
              id={`${item.id}${index}`}
              place='left'
              isOpen={isOpen === `${item.id}${index}` && show}
              offset={10}
              style={{
                opacity: '1 !important',
                borderRadius: 5,
                width: 300,
                fontSize: 12,
                textAlign: 'center',
                zIndex: 10001,
                pointerEvents: 'unset',
                cursor: 'default',
                backgroundColor: 'var(--bs-text-gray-700)',
                color: 'var(--bs-text-gray-300)',
              }}
              render={() => (
                <div
                  className=' d-flex flex-column'
                  draggable={true}
                  onDragStart={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <div
                    className='btn btn-icon btn-sm  ms-2 align-self-end pointer'
                    onClick={() => {
                      setIsOpen(null)
                    }}
                  >
                    <KTSVG
                      svgClassName='text-white svg-icon-white invert'
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon-2'
                    />
                  </div>
                  <div className='d-flex gap-2 align-items-center'>
                    <div
                      className='large_item h-50px w-50px'
                      style={item.data.icon ? {backgroundImage: `url(${item.data.icon})`} : {}}
                    ></div>
                    <span className=' fs-2'>{item.data.label}</span>
                  </div>
                  <p className=' text-start ps-3 pt-4'>{item.data.details}</p>
                </div>
              )}
            />

            <div className='node-icon'>
              <div
                className='large-item'
                style={item.data.icon ? {backgroundImage: `url(${item.data.icon})`} : {}}
              ></div>
            </div>
            <p className='node-label'>{item.data.label}</p>
          </div>
        )
      })
    }
    return (
      <>
        {/* {start drawer} */}
        <div
          id='kt_Drawer_Dags'
          className={`bg-body drawer drawer-end overflow-visible ${
            show ? 'drawer-on' : ''
          } work_drawer bg-secondary card`}
          data-kt-drawer-width="{default:'300px', 'lg': '300px'}"
          style={{zIndex: show ? 9 : 10, minWidth: 280, maxWidth: 280}}
        >
          <div className='drawer-content p-4'>
            {/* Search Input */}
            <div className='input-group mb-4 '>
              <span className='input-group-text  border-end-0'>
                <i className='fas fa-search text-gray-500'></i>
              </span>
              <input
                type='text'
                className='form-control border-start-0 ps-0'
                placeholder={`${intl.formatMessage({id: 'Search'})}...`}
              />
            </div>

            {/* Nodes Accordion */}
            {/* <div className='accordion custom-accordion' id='kt_accordion_1'>
              {WORKFLOW_Drawer_NODES?.map((items, ind) => (
                <div className='accordion-item border mb-3 rounded-3' key={ind + 1}>
                  <h2 className='accordion-header' id={`kt_accordion_1_header_${ind + 1}`}>
                    <button
                      onClick={() => {
                        setIsOpen(null)
                      }}
                      className='accordion-button fs-5 fw-semibold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_accordion_1_body_${ind + 1}`}
                      aria-expanded='false'
                      aria-controls={`kt_accordion_1_body_${ind + 1}`}
                    >
                      {items.category}
                    </button>
                  </h2>
                  <div
                    id={`kt_accordion_1_body_${ind + 1}`}
                    className='accordion-collapse collapse'
                    aria-labelledby={`kt_accordion_1_header_${ind + 1}`}
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body p-4'>
                      <div className='node-grid'>
                        {items.nodes?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              draggable={true}
                              className='workflow-node'
                              onDragStart={() => {
                                dragHandler({
                                  ...item,
                                  type: type,
                                })
                              }}
                            >
                              <div
                                data-tooltip-id={`${item.id}${ind}`}
                                style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                                onClick={() => {
                                  setIsOpen(`${item.id}${ind}`)
                                }}
                              >
                                <Warning2
                                  className=' image_change_theme2'
                                  svgClassName='info-icon'
                                />
                              </div>
                              <ReactTooltip
                                events={['click']}
                                id={`${item.id}${ind}`}
                                place='left'
                                isOpen={isOpen === `${item.id}${ind}` && show}
                                offset={10}
                                style={{
                                  opacity: '1 !important',
                                  borderRadius: 5,
                                  width: 300,
                                  fontSize: 12,
                                  textAlign: 'center',
                                  zIndex: 10001,
                                  pointerEvents: 'unset',
                                  cursor: 'default',
                                  backgroundColor: 'var(--bs-text-gray-700)',
                                  color: 'var(--bs-text-gray-300)',
                                }}
                                render={() => (
                                  <div
                                    className=' d-flex flex-column'
                                    draggable={true}
                                    onDragStart={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }}
                                  >
                                    <div
                                      className='btn btn-icon btn-sm  ms-2 align-self-end pointer'
                                      onClick={() => {
                                        setIsOpen(null)
                                      }}
                                    >
                                      <KTSVG
                                        svgClassName='text-white svg-icon-white invert'
                                        path='/media/icons/duotune/arrows/arr061.svg'
                                        className='svg-icon-2'
                                      />
                                    </div>
                                    <div className='d-flex gap-2 align-items-center'>
                                      <div className='large_item h-50px w-50px'></div>
                                      <span className=' fs-2'>{item.data.label}</span>
                                    </div>
                                    <p className=' text-start ps-3 pt-4'>{item.data.details}</p>
                                  </div>
                                )}
                              />

                              <div className='node-icon'>
                                <div className='large-item'></div>
                              </div>
                              <p className='node-label'>{item.data.label}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            {nodeItems()}
          </div>
          {/* </div> */}
          <button
            className='work_drawer_button bg-secondary'
            style={{border: 'none'}}
            onClick={() => {
              setShow(!show)
              if (!show) {
                setOtherDrawer(false)
              }
            }}
          >
            <div className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle  '>
              <Git className='svg-icon-success svg-icon-2x' />
            </div>
          </button>
        </div>
        {/* {end drawer} */}
      </>
    )
  }
)
export default DrawerDags
