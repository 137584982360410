import {useNavigate} from 'react-router-dom'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Arr001 from '../../components/Arr001'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {Vulnerability} from '../../../modules/types/dashboard'

/**
 * Default vulnerability data to be used when no issues are available
 * @type {Array<Vulnerability>}
 */
const DefaultData = [
  {
    id: 'hhhhhhh',
    title: 'This server is vulnerable to LUCKY13 attack.',
    title_fa: 'This server is vulnerable to LUCKY13 attack.',
    tag: 'A',
    severity: 'low',
    timestamp_jalali: '1403/10/10 10:10:10',
    timestamp: '2024-10-19T09:10:45.156015Z',
  },
  {
    id: '657657',
    title: 'This server is vulnerable to LUCKY13 attack.',
    title_fa: 'This server is vulnerable to LUCKY13 attack.',
    tag: 'A',
    severity: 'low',
    timestamp_jalali: '1403/10/10 10:10:10',
    timestamp: '2024-10-19T09:10:45.156015Z',
  },
  // ... other default entries
]

/**
 * Props interface for RecentVulnerabilities component
 * @interface RecentVulnerabilitiesProps
 */
interface RecentVulnerabilitiesProps {
  recentIssues?: Vulnerability[]
  loading: boolean
}

/**
 * RecentVulnerabilities Component
 * Displays a list of recent security vulnerabilities
 * 
 * @component
 * @param {RecentVulnerabilitiesProps} props - Component properties
 * @returns {React.ReactElement} Rendered recent vulnerabilities list
 */
const RecentVulnerabilities: React.FC<RecentVulnerabilitiesProps> = ({
  recentIssues,
  loading,
}) => {
  // Hooks and context
  const navigate = useNavigate()
  const intl = useIntl()
  const user = useSelector((state: RootState) => state.root.user)
  const lang = useLang()?.lang.selectedLang

  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body h-100 bg-body ${loading && 'skeleton'}`}>
        {/* Header section with title and navigation */}
        <div className='d-flex flex-row justify-content-between align-items-center pt-2 pb-5'>
          {/* Section Title */}
          <div className='d-flex flex-row align-items-center'>
            <h3 className='card-title align-items-start flex-column m-0'>
              {intl.formatMessage({id: 'Recent Vulnerabilities'})}
            </h3>

            {/* Informational Tooltip */}
            <span
              data-tooltip-id={'my-tooltip-recent'}
              data-tooltip-content={intl.formatMessage({id: 'Recent Vulnerabilities tooltip'})}
            >
              <InfoIcon />
            </span>
            <ReactTooltip
              id={'my-tooltip-recent'}
              style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
            />
          </div>

          {/* View All Button */}
          <button
            className={`col-auto border border-0 bg-body p-0 m-0`}
            onClick={() => {
              // Navigate to full vulnerabilities page
              navigate('/vulnerabilities')
            }}
          >
            {intl.formatMessage({id: 'View All'})}{' '}
            <Arr001 fill={'#fff'} className={'image_change_theme'} />
          </button>
        </div>

        {/* Vulnerabilities List Container */}
        <div
          className={`w-100 h-100 overflow-auto pe-2 m-0`}
          // Conditionally apply blur effect if no data is available
          style={!recentIssues?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
        >
          {/* Render vulnerabilities list */}
          {(user?.is_user_scan ? recentIssues || [] : DefaultData).map((issue, index) => (
            <div
              key={issue?.id}
              className={`d-flex flex-row w-100 h-55px bg-secondary p-2 border border-box m-0 align-items-center rounded-3 ${
                // Add margin between items except for the last one
                recentIssues && index === recentIssues?.length - 1 ? null : 'mb-3'
              } `}
            >
              {/* Vulnerability Tag */}
              {/* <div
                className={`w-30px h-30px bg-body rounded-1 d-flex justify-content-center align-items-center text-uppercase p-0 m-0`}
              >
                {issue?.tag}
              </div> */}

              {/* Vertical Separator */}
              <div className='w-1px h-75 gx-0 bg-dark me-3 ms-3' />

              {/* Severity Indicator */}
              <div
                className={`w-15px h-15px rounded-3 border border-2 border-${`i${issue?.severity}`}`}
              />

              {/* Vulnerability Title */}
              <div className={`me-auto ms-2 mw-200px text-truncate`}>
                {lang === 'fa' ? issue?.title_fa : issue?.title}
              </div>

              {/* Timestamp */}
              <div className={`w-auto p-0 m-0 text-uppercase`}>
                <ConvertDate date={issue?.timestamp} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentVulnerabilities