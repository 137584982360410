import React from 'react'
import {useIntl} from 'react-intl'
import Sort from '../images/Sort'
import {Button, Dropdown} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {ActionCreatorWithPayload} from '@reduxjs/toolkit'
import Search from '../images/Search'
import Close from '../images/Close'
/**
 * SearchAndSort - A comprehensive search and sorting component
 * 
 * @component
 * @description Provides advanced search and sorting functionality for lists
 * 
 * @param {Object} props - Component properties
 * @param {Array} props.search - Current search parameters
 * @param {Function} props.setSearch - Redux action to update search state
 * @param {Function} props.getData - Function to fetch data based on search/sort
 * @param {Object} props.sort - Current sorting configuration
 * @param {Function} props.setSort - Redux action to update sort state
 * @param {Array} props.sortOption - Available sorting options
 * @param {Array} [props.searchOption=null] - Available search options
 * @param {string} [props.className=''] - Additional CSS classes
 * 
 * @returns {React.ReactElement} Search and sort interface
 * 
 * @remarks
 * - Integrates with Redux for state management
 * - Supports dynamic search and sort options
 * - Internationalization enabled
 * 
 * @example
 * <SearchAndSort
 *   search={searchState}
 *   setSearch={setSearchAction}
 *   getData={fetchDataFunction}
 *   sort={sortState}
 *   setSort={setSortAction}
 *   sortOption={availableSortOptions}
 *   searchOption={availableSearchOptions}
 * />
 */
interface Props {
  search:
    | never[]
    | {
        name: string
        value: string
      }[]
  setSearch: ActionCreatorWithPayload<any, any>
  getData: ({}) => void
  sort: any
  setSort: ActionCreatorWithPayload<any, any>
  sortOption: {value: string; name: string}[]|any[]
  searchOption: string[] | null
  className?: string
}
export default function SearchAndSort ({
  search,
  setSearch,
  getData,
  sort,
  setSort,
  sortOption,
  searchOption,
  className = '',
}: Props) {
  const dispatch = useDispatch()
  const intl = useIntl()
  function sortAsc (args: any) {
    const sort = dispatch(setSort(args)).payload.value
    getData({sort: `&ordering=${sort}`})
  }
  function sortDes (args: any) {
    const sort = dispatch(setSort(args)).payload.value
    getData({sort: `&ordering=${sort}`})
  }
  function deleteSearch (ind: number) {
    const copy = [...search]
    copy.splice(ind, 1)
    dispatch(setSearch(copy))
  }
  function addSearch (name: string) {
    const copy = [...search]
    copy.push({name: name, value: ''})
    dispatch(setSearch(copy))
  }
  function updateSearchValue (values: string, ind: number) {
    const copy = structuredClone(search)
    copy[ind].value = values
    dispatch(setSearch(copy))
  }
  return (
    <div className={` w-100 d-flex gap-2 align-items-end ${className}`}>
      {/* Search */}
      <Dropdown style={{maxWidth: 'calc(100% - 140px)',width:'100%', height: 75}}>
        <Dropdown.Toggle
          className='dropdown_arrow_none w-100 p-0 m-0 bg-transparent :hover-bg-transparent '
          variant='link'
          id='dropdown-basic'
          style={{height: 43}}
        >
          <div className='h-75px card w-100 bg-gray-200 d-flex flex-row align-items-center gap-3 p-2'>
            <strong>{intl.formatMessage({id: 'Search'})} : </strong>
            <div className='h-100 card w-100 shadow-none  d-flex flex-row align-items-center gap-3 border-0  overflow-x-auto overflow-y-visible bg-transparent px-1'>
              {search.map((item, ind) => (
                <div
                  className='bg-body rounded d-flex align-items-center p-1 gap-1 px-2'
                  key={ind}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <span className=' text-capitalize'>{item.name}</span>
                  <input
                    type='text'
                    className='form-control w-100px  form-control-solid text-gray-700 py-2 '
                    placeholder={intl.formatMessage({id: 'Search'})}
                    value={item.value}
                    onChange={(e) => {
                      updateSearchValue(e.target.value, ind)
                    }}
                  />
                  <div
                    onClick={() => {
                      deleteSearch(ind)
                    }}
                  >
                    <Close className='svg-icon-2x image_change_theme2' />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {searchOption?.map((item, ind) => (
            <Dropdown.Item
              key={ind}
              className={`dropdown-item text-capitalize ${
                search.some((i) => i.name === item) && 'Active'
              }`}
              onClick={() => {
                if (!search.some((i) => i.name === item)) addSearch(item)
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className='d-flex gap-1 align-items-center h-75px'>
        <Button className='p-0 ' style={{height: 43, width: 42}} onClick={() => getData({})}>
          {' '}
          <Search className='svg-icon svg-icon-2x svg-icon-white image_change_theme2 m-2' />
        </Button>
        {/* Asc */}
        <Dropdown>
          <Dropdown.Toggle
            className='dropdown_arrow_none p-1 h-43px'
            variant='success'
            id='dropdown-basic'
            style={{height: 43}}
          >
            <Sort className='svg-icon svg-icon-2x svg-icon-white image_change_theme2 m-2' />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {sortOption.map((item, ind) => (
              <Dropdown.Item
                key={ind}
                className={`dropdown-item text-capitalize ${
                  item.value === sort.sortAsc && 'Active'
                }`}
                onClick={() => {
                  sortAsc({value: item.value, type: 'asc'})
                }}
              >
                {item.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Des */}
        <Dropdown>
          <Dropdown.Toggle
            className='dropdown_arrow_none p-1 h-43px'
            variant='success'
            id='dropdown-basic'
            style={{height: 43}}
          >
            <Sort
              className='svg-icon svg-icon-2x  svg-icon-white image_change_theme2 m-2 '
              svgClassName='rotate_180'
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortOption.map((item, ind) => {

              return(
              <Dropdown.Item
                key={ind}
                className={`dropdown-item text-capitalize ${`-${item.value}` === sort.sortDes && 'Active'}` }
                onClick={() => {
                  sortDes({value: `-${item.value}`, type: 'des'})
                }}
              >
                {item.name}
              </Dropdown.Item>
            )})}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}
//${search.some((i)=>(i.name===item)) && 'Active'}
