import { Card } from "react-bootstrap";
import { SectionData } from "..";
import { SubSection } from "./SubSection";
interface SectionProps {
  data: SectionData;
  isActive: boolean;
  onToggle: () => void;
}
export const Section: React.FC<SectionProps> = ({ data, isActive, onToggle }) => {
  
  const passedCount = Object.values(data)
    .filter(item => item?.status === 'pass').length;
  const failedCount = Object.values(data)
    .filter(item => item?.status === 'fail').length;

  return (
    <Card className="mb-3 section-card">
      <Card.Header
        className="cursor-pointer d-flex justify-content-between align-items-center"
        onClick={onToggle}
        role="button"
        aria-expanded={isActive}
        tabIndex={0}
      >
        <h3 className="mb-0 h5">{data.title}</h3>
        
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center" 
            aria-label='{count} tests passed'
          >
            <i className="fas fa-check-circle text-success me-2"></i>
            <span>{passedCount}</span>
          </div>
          
          <div className="d-flex align-items-center"
            aria-label='{count} tests failed' 

          >
            <i className="fas fa-times-circle text-danger me-2"></i>
            <span>{failedCount}</span>
          </div>
          
          <i className={`fas fa-chevron-${isActive ? 'up' : 'down'}`}></i>
        </div>
      </Card.Header>

      <Card.Body className={`section-content ${isActive ? 'show' : ''}`}>
        {Object.entries(data)
          .filter(([key]) => key !== 'title')
          .map(([key, subsection], index) => (
            <SubSection key={key} data={subsection} />
          ))}
      </Card.Body>
    </Card>
  );
};