import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import clsx from 'clsx'
import { Button} from 'react-bootstrap'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {toast} from 'sonner'

/**
 * Regular expression for validating URLs
 */
const URLRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]{2,}(\/[\w-._~:/?#[\]@!$&'()*+,;=]*)?$/

/**
 * Initial values for the form
 */
const initialValues = {
  service_name: 'splunk',
  url: '',
  token: '',
}

/**
 * Interface for form values
 */
interface ValueProps {
  service_name: string
  url: string
  token: string
}

/**
 * Drawer component for creating or editing Splunk integration
 * @param {Object} props - Component props
 * @param {string} props.id - ID of the integration to edit (if editing)
 */
export default function Drawer ({id}: {id: string}) {
  const errorHandling = useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const intl = useIntl()

  /**
   * Yup schema for form validation
   */
  const loginSchema = Yup.object().shape({
    service_name: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Name is required'})),
    url: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .required(intl.formatMessage({id: 'URL is required'})),
      // .matches(URLRegex, intl.formatMessage({id: 'URL is not valid'})),
    token: Yup.string()
      .min(10, intl.formatMessage({id: 'Minimum 10 symbols'}))
      .required(intl.formatMessage({id: 'Token is required'})),
  })

  /**
   * Formik hook for form handling
   */
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values: ValueProps) => {
      setLoadingSubmit(true)
      // Send PUT request to update integration
      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/vul-logger/${id}/`,values)
        .then((res) => {
          errorHandling(res.status)
          if (res.status >= 200 && res.status < 300) {
            toast.success(intl.formatMessage({id: 'Integrations created successfully'}))
          } else {
            toast.error(
              res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
            )
          }
          setLoadingSubmit(false)
        })
        .catch((e) => {
          console.log('message \n', e?.response?.data?.error)
          formik.setErrors(e.response.data)
          toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          setLoadingSubmit(false)
        })
    },
  })

  /**
   * Fetch integration data from the API
   */
  const fetchData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vul-logger/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        formik.setValues(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vul-logger \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  // Fetch data when id is available
  useEffect(()=>{
    if(id){
      fetchData()
    }
  },[id])

  return (
    <div
      id='kt_drawer_Integrations'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='Integrations'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_Integrations_toggle'
      data-kt-drawer-close='#kt_drawer_Integrations_close'
      data-kt-drawer-permanent='true'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title justify-content-between w-100'>
            <div className='d-flex justify-content-center flex-column me-3 '>
              <a
                href='#'
                className={`fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 ${(loading||!id)&&'skeleton'}`}
              >
                {intl.formatMessage({id: 'Create'})}{' '}Splunk{' '}{intl.formatMessage({id: 'Configuration'})}
              </a>
            </div>
            <div className='card-toolbar'>
              <div className=' text_hide_width w-25px fw-bold text-primary'></div>
              <div
                className='btn btn-sm btn-icon btn-active-light-primary'
                id='kt_drawer_Integrations_close'
                onClick={() => {}}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </div>
            </div>
          </div>
        </div>
        <form
          className={`w-100 form h-100 position-relative ${(loading||!id)&&'skeleton'}`}
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_Integrations_form'
        >
          <div
            className=' d-flex flex-column align-items-center position-absolute top-10 start-auto right-auto w-100'
            style={{zIndex: 100, top: -21, left: ' 50%', transform: ' translateX(-50%)'}}
          >
          </div>
          <div className='d-flex flex-column gap-2 p-10 justify-content-between h-100'>
            <div>
              {/* Service Name Input */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder  '>
                  {intl.formatMessage({id: 'Service Name'})}{' '}
                </label>
                <input
                  placeholder={`${intl.formatMessage({id: 'Service Name'})}...`}
                  {...formik.getFieldProps('service_name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.service_name && formik.errors.service_name,
                    },
                    {
                      'is-valid': formik.touched.service_name && !formik.errors.service_name,
                    }
                  )}
                  type='text'
                  name='service_name'
                  autoComplete='off'
                  onChange={(e) => {
                    formik.setFieldValue('service_name', e.target.value)
                  }}
                  readOnly
                />
                {formik.touched.service_name && formik.errors.service_name && (
                  <div className='fv-plugins-message-container mt-2 text-danger'>
                    <span role='alert'>{formik.errors.service_name}</span>
                  </div>
                )}
              </div>
              {/* Name Input */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder  '>
                  {intl.formatMessage({id: 'Name'})}
                </label>
                <input
                  placeholder={`${intl.formatMessage({id: 'Name'})}...`}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  type='text'
                  autoComplete='off'
                />
              </div>
              {/* URL Input */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder  '>
                  {intl.formatMessage({id: 'URL'})}{' '}
                </label>
                <input
                  placeholder='URL...'
                  {...formik.getFieldProps('url')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.url && formik.errors.url,
                    },
                    {
                      'is-valid': formik.touched.url && !formik.errors.url,
                    }
                  )}
                  type='text'
                  name='url'
                  autoComplete='off'
                  onChange={(e) => {
                    formik.setFieldValue('url', e.target.value)
                  }}
                />
                {formik.touched.url && formik.errors.url && (
                  <div className='fv-plugins-message-container mt-2 text-danger'>
                    <span role='alert'>{formik.errors.url}</span>
                  </div>
                )}
              </div>
              {/* Token Input */}
              <div className='fv-row mb-5'>
                <label className='form-label fs-6 fw-bolder  '>
                  {intl.formatMessage({id: 'Token'})}{' '}
                </label>
                <input
                  placeholder={`${intl.formatMessage({id: 'Token'})}...`}
                  {...formik.getFieldProps('token')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.token && formik.errors.token,
                    },
                    {
                      'is-valid': formik.touched.token && !formik.errors.token,
                    }
                  )}
                  type='text'
                  name='token'
                  autoComplete='off'
                  onChange={(e) => {
                    formik.setFieldValue('token', e.target.value)
                  }}
                />
                {formik.touched.token && formik.errors.token && (
                  <div className='fv-plugins-message-container mt-2 text-danger'>
                    <span role='alert'>{formik.errors.token}</span>
                  </div>
                )}
              </div>
            </div>
            {/* Submit Button */}
            <Button type='submit' disabled={loadingSubmit || loading}>
              {loadingSubmit ? intl.formatMessage({id: 'Please wait'})+'...' : intl.formatMessage({id: 'Submit'})}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

