import {useIntl} from 'react-intl'

/**
 * Represents the structure of scan data
 * @typedef {Object} Data
 * @property {string} id - The unique identifier of the scan
 * @property {string} start_time - The start time of the scan
 */
interface Data {
  id: string
  start_time: string
}

/**
 * HistoryModal component displays a modal with scan history
 * @param {Object} props - Component props
 * @param {Data[]} props.data - Array of scan history data
 * @param {function} props.get - Function to fetch scan details
 * @param {string} props.selected - ID of the currently selected scan
 * @returns {JSX.Element} HistoryModal component
 */
export default function HistoryModal ({
  data,
  get,
  selected
}: {
  data: Data[]
  get: (historyId?: string) => Promise<void>
  selected: string
}) {
  const intl = useIntl()

  return (
    <div
      className='modal fade'
      id='historyModal'
      tabIndex={-1}
      aria-labelledby='historyModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-lg '>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>
              <strong>
                {data?.length} {intl.formatMessage({id: 'Last Scans'})}
              </strong>
            </h2>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='d-flex flex-wrap gap-10 p-5 min-h-400px'>
            {data?.map((item, ind) => {
              // Convert the start_time to a Date object
              const date = new Date(item.start_time)
              return (
                <div
                  className={`px-3 py-3 border border-2 rounded date_box ${selected === item.id && " border-success"}`}
                  key={ind}
                  style={{height: 'fit-content', cursor: 'pointer' }}
                  onClick={() => {
                    get(item.id)
                  }}
                >
                  {/* Display the month and day of the scan */}
                  <span style={{fontSize: 10}}>
                    {date?.toString()?.split(' ')[1]} {date?.toString()?.split(' ')[2]}
                  </span>
                  {/* Display the scan number */}
                  <div className='d-flex justify-content-center align-items-center bg-success bg-gradient py-2 rounded'>
                    <span>{ind + 1}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

