import React, {useState, useEffect} from 'react'
import axios from 'axios'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '../../../_metronic/helpers'
import Sort from '../images/Sort'
import {useNavigate} from 'react-router-dom'
import ConvertDate from '../../../Utils/ConvertDate'
import {RootState} from '../../../setup/redux/Store'
import {SetDataSSL} from '../../modules/auth'
import {useIntl} from 'react-intl'
import EmptyPage from '../emptyPage/EmptyPage'
import {SSL_DATA} from '../../modules/auth/redux/DEMO_DATA'
const HostTest = [
  'wait for API 1',
  'wait for API 2',
  'wait for API 3',
  'wait for API 4',
  'wait for API 5',
]
const colorConvertor = (grade: string) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'A+' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'F' ? 'rgb(140 29 29)' : color
  color = grade === 'T' ? 'rgb(92 33 33)' : color
  color = grade === 'M' ? 'rgb(102 21 21)' : color
  color = grade === 'N' ? '#565672' : color

  return color
}
export const SSL = () => {
  const [loading, setLoading] = useState(false)
  const sslData = useSelector((state: RootState) => state?.root?.data?.dataSSL)
  const dispatch = useDispatch()
  const [sortState, setSortState] = useState<string>('')
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const handlePageClick = (event: {selected: number}) => {
    fetchSslData(event.selected + 1, sortState)
  }
  const fetchSslData = async (page = 1, sort = '') => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ssl/?page=${page}${sort && '&ordering=' + sort}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataSSL(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ssl/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (sslData?.expireTime && sslData.expireTime >= nowDate) {
    } else {
      fetchSslData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function sort (sortItem: string) {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setSortState(sortItem)
      fetchSslData(sslData?.data?.current_page, sortItem)
    }
  }
  return (
    <>
      {loading ? (
        <PageLoading />
      ) :  (
        <>
          <div className='row d-flex flex-row  mt-5'>
            {/* <Section data={sslData?.data?.results} /> */}
            <div className='w-100 h-100'>
              <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
                {/* start title */}
                <div className='w-100 pe-3 d-flex flex-row justify-content-between align-items-start pt-2'>
                  <h3 className=' '>{intl.formatMessage({id: 'SSL Report'})}</h3>
                </div>
                {/* end title */}

                {/* start filters */}
                <div className='d-flex gap-5 my-10'>
                  {/* <div className='w-100'>
                    <label className='form-label fw-bold'>
                      {intl.formatMessage({id: 'Severity Filter'})}:
                    </label>

                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Severity Filter'
                        data-allow-clear='true'
                        defaultValue={''}
                      >
                        <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                        <option value='Critical' className='text-capitalize'>
                          {intl.formatMessage({id: 'critical'})}
                        </option>
                        <option value='high' className='text-capitalize'>
                          {intl.formatMessage({id: 'high'})}
                        </option>
                        <option value='medium' className='text-capitalize'>
                          {intl.formatMessage({id: 'medium'})}
                        </option>
                        <option value='low' className='text-capitalize'>
                          {intl.formatMessage({id: 'low'})}
                        </option>
                        <option value='info' className='text-capitalize'>
                          {intl.formatMessage({id: 'info'})}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className='w-100'>
                    <label className='form-label fw-bold'>
                      {intl.formatMessage({id: 'Sensor Filter'})}:
                    </label>

                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Sensor Filter'
                        data-allow-clear='true'
                        defaultValue={''}
                      >
                        <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                        <option value='dns_sensor'>DNS Sensor</option>
                        <option value='fuzzer'>Fuzzer</option>
                        <option value='pscan'>Pscan</option>
                        <option value='smtp_sensor'>Smtp Sensor</option>
                        <option value='ssl'>SSL</option>
                        <option value='tech'>Tech</option>
                        <option value='web_scan'>Web Scan</option>
                        <option value='wpscan'>Wpscan</option>
                      </select>
                    </div>
                  </div>
                  <div className='w-100'>
                    <label className='form-label fw-bold'>
                      {intl.formatMessage({id: 'Host Filter'})}:
                    </label>

                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Host Filter'
                        data-allow-clear='true'
                        defaultValue={''}
                      >
                        <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                        {HostTest?.map((item, ind) => (
                          <option key={ind} value={ind}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  <div className='w-100'>
                    <label className='form-label fw-bold'>
                      {intl.formatMessage({id: 'Search'})}
                    </label>

                    <div>
                      <input
                        type='text'
                        className='form-control w-100 mw-300px form-control-solid text-gray-700'
                        placeholder={intl.formatMessage({id: 'Search'})}
                      />
                    </div>
                  </div>
                </div>
                {/* end filters */}

                {/* start body */}
                {/* start titles */}
                <div className='w-100 m-0 pe-3 '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-200px p-1  h-auto'>
                      <div
                        className='w-100  h-auto d-flex justify-content-start align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-grade') {
                            sort('grade')
                          } else {
                            sort('-grade')
                          }
                        }}
                      >
                        <span>{intl.formatMessage({id: 'Grade'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-grade' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-100 p-1 ps-2 ms-5 h-auto'>
                      <div
                        className='w-100  h-auto d-flex justify-content-start align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-name') {
                            sort('name')
                          } else {
                            sort('-name')
                          }
                        }}
                      >
                        <span>{intl.formatMessage({id: 'Name'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-name' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                      <div
                        className='w-100  h-auto d-flex justify-content-center align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-total_vulnerabilities') {
                            sort('total_vulnerabilities')
                          } else {
                            sort('-total_vulnerabilities')
                          }
                        }}
                      >
                        {' '}
                        <span>{intl.formatMessage({id: 'Vulnerabilities'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{
                            rotate: sortState === '-total_vulnerabilities' ? '180deg' : '0deg',
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-100 d-flex justify-content-center p-1 ms-2 h-auto'>
                      <div
                        className='w-100  h-auto d-flex justify-content-center align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-certificate_status') {
                            sort('certificate_status')
                          } else {
                            sort('-certificate_status')
                          }
                        }}
                      >
                        {' '}
                        <span>{intl.formatMessage({id: 'Certificate Status'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-certificate_status' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      {' '}
                      {intl.formatMessage({id: 'Expiry Date'})}{' '}
                    </div>
                    <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                      <div
                        className='w-100 h-auto d-flex justify-content-center align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-timestamp') {
                            sort('timestamp')
                          } else {
                            sort('-timestamp')
                          }
                        }}
                      >
                        {' '}
                        <span>{intl.formatMessage({id: 'Last Seen'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-timestamp' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* end titles */}
                <div className={`w-100 h-100 d-flex flex-column gap-3 overflow-auto pe-3 m-0`}>
                  {(sslData?.data?.results?.length
                    ? sslData?.data?.results
                    : SSL_DATA.results
                  )?.map((item, index: number) => {
                    const {
                      id,
                      grade,
                      host,
                      total_vulnerabilities,
                      timestamp,
                      timestamp_jalali,
                      certificate_status,
                      days_to_expiry,
                    } = item
                    return (
                      <div
                        style={!sslData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                        onClick={() => {
                          if (sslData?.data?.results?.length)
                            navigate({
                              pathname: '/ssl-details',
                              search: `?id=${id}`,
                            })
                        }}
                        key={`ssl_list_${index}`}
                        className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center pointer  hover_Costume`}
                      >
                        <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                          <div className='w-200px d-flex justify-content-center align-items-center p-1 h-auto'>
                            <div
                              className={`d-flex justify-content-center align-items-center position-relative`}
                            >
                              <KTSVG
                                path='/media/hexagonal.svg'
                                className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                                fill={colorConvertor(grade)}
                              />
                              <span className='position-absolute ms-2 text-black fw-bolder'>
                                {grade}
                              </span>
                            </div>
                          </div>
                          <div className='w-100 p-1 ps-2 ms-5 h-auto'> {host} </div>
                          <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                            {total_vulnerabilities}
                          </div>
                          <div className='w-100 p-1 h-auto d-flex justify-content-center'>
                            {certificate_status ? 'Valid' : 'Invalid'}
                          </div>
                          <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                            <div className={`col-auto border border-0  bg-gray-200 p-0 m-0`}>
                              {days_to_expiry >0
                                ? days_to_expiry + ' ' + intl.formatMessage({id: 'day'})
                                : 'Expired'}
                            </div>
                          </div>
                          <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                            <ConvertDate date={timestamp} jalali={timestamp_jalali} />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={sslData?.data?.total_pages||1}
              forcePage={sslData?.data?.current_page ? sslData?.data?.current_page - 1:0}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      ) }
    </>
  )
}
