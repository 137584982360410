import { getApp, getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";


/**
 * Firebase configuration object
 * @type {Object}
 */
const firebaseConfig = {
  apiKey: "AIzaSyBOXaRHxP3TiJ9Jb_a-zMze0m1yt85IpJA",
  authDomain: "netbaan-7bf0c.firebaseapp.com",
  projectId: "netbaan-7bf0c",
  storageBucket: "netbaan-7bf0c.firebasestorage.app",
  messagingSenderId: "555941273161",
  appId: "1:555941273161:web:8a74f8159fc135790ba998"
};

/**
 * Initialize Firebase app or get the existing app instance
 * @type {import('firebase/app').FirebaseApp}
 */
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

/**
 * Get Firebase Cloud Messaging instance if supported
 * @returns {Promise<import('firebase/messaging').Messaging | null>}
 */
const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
};

/**
 * Fetch FCM token for the current device
 * @returns {Promise<string | null>} FCM token or null if not available
 */
export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();
    if (fcmMessaging) {
      // Get the token using the messaging instance and VAPID key
      const token = await getToken(fcmMessaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
      });
      return token;

    }
    return null;
  } catch (err) {
    console.error("An error occurred while fetching the token:", err);
    return null;
  }
};


export { app, messaging };