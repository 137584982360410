import React, {memo} from 'react'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store'
interface DATA {
  count: number | null | undefined
  desc: string
  header: string
  title: string
}
/**
 * Assets component displays a card with statistical information
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.className] - Additional CSS classes for styling
 * @param {DATA} props.data - Data object containing count, description, header, and title
 * @param {string} [props.tooltip='Tooltip...'] - Tooltip text for additional information
 * @param {boolean} props.loading - Indicates if the component is in a loading state
 * 
 * @returns {React.ReactElement} A card displaying asset information with optional loading state
 * 
 * @example
 * <Assets 
 *   data={{
 *     count: 5, 
 *     desc: 'Total Assets', 
 *     header: 'Assets', 
 *     title: 'Asset Count'
 *   }} 
 *   loading={false} 
 * />
 */
const Assets = memo(
  ({
    className,
    data,
    tooltip = 'Tooltip...',
    loading
  }: {
    className?: string
    data: DATA
    tooltip?: string
    loading: boolean
  }) => {
    const user = useSelector((state:RootState) => state.root.user)
    const intl = useIntl()
    
    return (
      <div className={`bg-body h-100 card  ${loading && 'skeleton'}`}>
        {/* Card header with title and info icon */}
        <div
          className={`w-100 h-50px p-4 fw-bolder d-flex justify-content-center align-items-center text-body bg-secondary rounded-top`}
        >
          {intl.formatMessage({id: data.header || 'Address'})}
          <span
            data-tooltip-id={'my-tooltip' + data.title}
            data-tooltip-content={intl.formatMessage({id: tooltip})}
          >
            <InfoIcon />
          </span>
          <Tooltip
            id={'my-tooltip' + data.title}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </div>
        
        {/* Card body displaying count and description */}
        <div
          className={`w-100 h-100 p-0 pt-4 d-flex flex-column justify-content-center align-items-center ${className}`}
        >
          <span
            style={
              data?.count === null && !user?.is_user_scan ? {filter: 'blur(3px)', fontSize: '3em'} : {fontSize: '3em'}
            }
            className='pt-2 d-flex align-items-center fw-bolder text-igray'
          >
            {data?.count === null && !user?.is_user_scan ? 5 : data.count}
          </span>
          <span className='d-flex align-items-center fw-bolder text-gray-700'>
            {intl.formatMessage({id: data.title || 'Address'})}
          </span>
          <span className='d-flex w-100 align-items-center fs-5 justify-content-center fw-bolder text-gray-700 mt-2'>
            {intl.formatMessage({id: data.desc || 'Address'})}
          </span>
        </div>
      </div>
    )
  }
)

export default Assets
