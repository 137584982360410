/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {lazy, Suspense, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {IpDetails} from '../../../modules/types/ip'

/**
 * Props for the DetailsDrawer component
 * @typedef {Object} Props
 * @property {React.Dispatch<React.SetStateAction<string | null>>} setId - Function to set the IP id
 * @property {string | null} id - The IP id
 */
interface Props {
  setId: React.Dispatch<React.SetStateAction<string | null>>
  id: string | null
}

/**
 * DetailsDrawer component for displaying IP details
 * @param {Props} props - The component props
 */
export const DetailsDrawer = ({id, setId}: Props) => {
  // Lazy load the Map component
  const Map = lazy(() => import('./Map'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IpDetails|null>(null)
  const intl = useIntl()
  const errorHandling = useErrorHandling()

  /**
   * Fetches IP data from the API
   * @param {string} query - The query string for the API request
   * @param {any} filterProps - Additional filter properties for the API request
   */
  const fetchIpData = async (query = '?page=1', filterProps?: any) => {
    setLoading(true)
    setData(null)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ip/${id}/`)
      errorHandling(response.status) // Logout when invalid token && redirect 404
      if (response.status >= 200 && response.status < 300) {
        setData(response.data)
      } else {
        console.error(
          `Error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ip/${query}${filterProps} \n`,
          response
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // Fetch IP data when the id changes
  useEffect(() => {
    if (id !== null) {
      fetchIpData()
    }
  }, [id])
 const detailsData = [
  {label: 'Domain', value: data?.host_domain},
  {label: 'Location', value: data?.loc},
  {label: 'Version', value: data?.version},
  {label: 'CIDR', value: data?.cidr},
  {label: 'ASN', value: data?.asn},
  {label: 'ASN Name', value: data?.asn_name || '_'},
  {label: 'ASN Domain', value: data?.asn_domain || '_'},
]
  return (
    <>
      {/* Drawer component for displaying IP details */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        <>
          {/* Card component for IP details */}
          <div className='card shadow-none w-100' style={{height: 'fit-content'}}>
            {/* Card header with close button */}
            <div className='card-header' id='kt_explore_header' style={{minHeight: 'unset'}}>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                  id='kt_engage_filters_close'
                  onClick={() => setId(null)}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </button>
              </div>
            </div>
            {/* Card body with IP details */}
            <div className={`p-5 ${loading && 'skeleton'}`}>
              {/* Lazy-loaded Map component */}
              <Suspense fallback={<></>}>
                <Map lat={data?.latitude || 0} lng={data?.longitude || 0} />
              </Suspense>
              <h2 className='mt-5'>{data?.ip}</h2>
              <span className='text-gray-500'>{intl.formatMessage({id: 'IP Address'})}</span>
              <h4 className='mt-10'>{intl.formatMessage({id: 'Information'})}</h4>
              <hr />
              {/* Display IP details in a list */}
              {detailsData.map((item, index) => (
                <React.Fragment key={index}>
                  <div className='d-flex align-items-center justify-content-between gap-5'>
                    <h6 className='text-gray-500'>{intl.formatMessage({id: item.label})}</h6>
                    <strong className='text-capitalize text-nowrap mw-75 text_hide_width'>
                      {item.value}
                    </strong>
                  </div>
                  {index < 6 && <hr />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      </div>
    </>
  )
}

