import axios from 'axios'
import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Check from '../../../pages/images/Ckeck'

export default function VerifyEmail () {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [status, setStatus] = useState('')
  const intl = useIntl()

  const params = useParams()
  const {uid, token} = params
  const navigate = useNavigate()
  /**
   * Verifies the user's email by sending a POST request to the activation endpoint.
   * 
   * This function attempts to activate the user's account using the provided UID and token.
   * It updates the component's state based on the response, handling both successful and failed verifications.
   * 
   * @async
   * @function verify
   * @throws {Error} If there's a network error or the server responds with an error.
   * @returns {Promise<void>}
   */
  async function verify() {
    setLoading(true)
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/activation/`,
        {
          uid,
          token,
        },
        { withCredentials: false }
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('Your email has been successfully verified')
        setIsError(false)
        setIsVerify(true)
        setTimeout(() => {
          navigate('/auth/login')
        }, 5000)
      } else {
        setIsError(true)
        setStatus(data.data.message || 'Unfortunately, there was a problem verifying your email')
      }
    } catch (e: any) {
      console.log('aaa', e?.message)
      setIsError(true)
      setStatus(e?.message || 'Unfortunately, there was a problem verifying your email')
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className='h-100 bg-red'>
      <div className='app-navbar position-relative px-4 py-5 px-md-5 text-center text-lg-start h-100 d-flex align-items-center justify-content-center gradient'>
        <div className='container'>
          <div className='row gx-lg-5 align-items-center justify-content-between'>
            <div className='col-lg-6 mb-5 mb-lg-0'>
              <h1
                className='my-5 display-3 fw-bold ls-tight text-white '
                style={{textAlign: 'justify'}}
              >
                {intl.formatMessage({id: 'Netbaan'})}
                <br />
                <span className='text-primary'>{intl.formatMessage({id: 'Cloud Platform'})}</span>
              </h1>
              <p style={{color: 'hsl(217, 10%, 50.8%)', textAlign: 'justify'}}>
                {intl.formatMessage({
                  id: 'Secure your infrastructure with our cutting-edge technologies',
                })}
              </p>
            </div>

            {!isVerify ? (
              <div className='col-lg-6 py-10 mb-lg-0 ' style={{maxWidth: 500}}>
                <div
                  hidden={!status || !isError}
                  className={`mb-lg-15 h-40px alert ${
                    isError ? 'alert-danger' : 'alert-success'
                  }  rounded-pill`}
                >
                  <div className='font-weight-bold alert-text'>{status}</div>
                </div>

                <div className='d-flex align-items-center justify-content-center flex-column gap-10  p-5 text-center card py-10'>
                  <h1 className='text-white'>
                    {intl.formatMessage({id: 'Verify your email to finish signing up'})}
                  </h1>

                  <h4 className='text-white'>
                    {intl.formatMessage({id: 'Thank you for choosing'})}{' '}
                    <strong>{intl.formatMessage({id: 'NetBaan Sharif'})} </strong>.
                  </h4>

                  <button
                    className='btn btn-success  '
                    style={{opacity: 0.9}}
                    disabled={loading}
                    onClick={verify}
                  >
                    {!loading && (
                      <span className='indicator-label fw-bold'>
                        {intl.formatMessage({id: 'Verify'})}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Please wait'})}
                        ...
                        <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className='d-flex align-items-center justify-content-center flex-column gap-5 text-center card py-10 col-lg-6 py-10 mb-lg-0'>
                <Check className='svg-icon-6x svg-icon-success' />
                <h1 className=' text-success'>
                  {intl.formatMessage({id: 'Email verification was successful'})}
                </h1>
                <p>{intl.formatMessage({id: 'You can login with your email'})}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
